import React from "react";
import Select from "react-select";

const TripBulkActions = ({
  selectedOwner,
  superAdminOrAd,
  ownerOptions,
  handleOwnerSelection,
  stageFilterOptions,
  handleStageFilterChange,
  defaultStageFilters,
  stageFilterInput,
}) => {
  return (
    <div id="lead-bulk-actions">
      {!superAdminOrAd && (
        <Select
          key={`selectedOwner-${selectedOwner}`}
          className="owner-select"
          classNamePrefix="select"
          value={selectedOwner}
          options={ownerOptions}
          placeholder="Owner"
          onChange={handleOwnerSelection}></Select>
      )}

      <div id="filterByStage">
        <Select
          className="stage-select"
          classNamePrefix="select"
          placeholder="Status"
          isMulti
          options={stageFilterOptions}
          onChange={handleStageFilterChange}
          defaultValue={defaultStageFilters}
          value={stageFilterInput}></Select>
      </div>
    </div>
  );
};

export default TripBulkActions;
