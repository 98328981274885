import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Fragment } from "react";

const columns = [
  {
    name: "View Lead",
    sortable: false,
    cell: (row) => {
      return <Link to={`/leads/${row._id}`}>View</Link>;
    },
    width: "80px",
  },
  {
    name: "View Prospect",
    sortable: false,
    cell: (row) => {
      // if (row.evoProspectId) {
      //   return <Link to={`/prospects/${row.evoProspectId}`}>View</Link>;
      // }
      return "-";
    },
    width: "90",
  },
  {
    name: "Send Quote",
    sortable: false,
    cell: (row) => {
      let newQuote = <Link to={`/leads/${row._id}/quotes/new`}>New Quote</Link>;

      if (row.assigneeId) {
        return newQuote;
      }

      return "Assign Lead First";
    },
    width: "130px",
  },

  {
    name: "Sourcing",
    sortable: false,
    cell: (row) => {
      let newSourcing = (
        <Link to={{ pathname: `/sourcing/new`, state: { lead: row } }}>
          Create
        </Link>
      );

      if (row.assigneeId) {
        return newSourcing;
      }

      return "Assign Lead First";
    },
    width: "130px",
    omit: false,
  },

  {
    name: "Create Contract",
    sortable: false,
    cell: (row) => {
      let newContract = (
        <Link to={{ pathname: `/contracts/new`, state: { lead: row } }}>
          New Contract
        </Link>
      );

      if (row.assigneeId) {
        return newContract;
      }

      return "Assign Lead First";
    },
    width: "130px",
  },

  {
    name: "Matches",
    selector: "flightLegData",
    sortable: true,
    width: "150px",
    cell: (row) => {
      if (!row.flightLegData.length) {
        return "-";
      }

      let matches = row.flightLegData.map((leg) => {
        let linkText = "";
        let returnValue = "";
        if (leg.isPrimaryLeg) {
          linkText = "Leg 1 Matches";
        } else {
          linkText = "Leg 2 Matches";
        }

        // console.log("LEG", leg);

        if (leg?.flightMatches?.length) {
          returnValue = (
            <Fragment key={`leg-${leg._id}`}>
              <Link
                className="leadFlightLegMatches"
                to={`/flight-matches/${leg._id}`}
                key={leg._id}
              >{`${linkText}`}</Link>{" "}
              {row.flightLegData.length > 1 &&
              leg.isPrimaryLeg &&
              row.flightLegData[1].flightMatches.length
                ? ", "
                : ""}
            </Fragment>
          );
        } else {
          returnValue = false;
        }
        return returnValue;
      });

      if (matches.every((item) => item === false)) {
        return "No Matches";
      }

      return matches;
    },
  },

  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "130px",
    cell: (row) => {
      return moment(row.createdAt).format("M/D/YY - HHmm");
    },
  },

  {
    name: "Owner",
    selector: "assigneeName",
    sortable: true,
    cell: (row) => {
      if (row.assigneeName) {
        let assigneeArray = row.assigneeName.split(" ");
        if (assigneeArray[0] && assigneeArray[1] && assigneeArray[1][0]) {
          return `${assigneeArray[0]} ${assigneeArray[1][0]}.`;
        }
        if (assigneeArray[0]) {
          return `${assigneeArray[0]}`;
        }
      }

      return "-";
    },

    width: "110px",
  },

  {
    name: "First Name",
    selector: "firstName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Last Name",
    selector: "lastName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    width: "150px",
  },
  {
    name: "Phone",
    selector: "phone",
    sortable: true,
    width: "150px",
  },
  {
    name: "Departure Date",
    selector: "estimateData.0.departureDateString",
    cell: (row) =>
      moment.utc(row.estimateData[0]?.departureDateString).format("ll"),
    sortable: true,
    width: "200px",
  },
  {
    name: "Return Date",
    selector: "estimateData.0.returnDateString",
    sortable: true,
    width: "150px",
    cell: (row) =>
      row.estimateData[0]?.returnDateString
        ? moment
            .utc(row.estimateData[0]?.returnDateString)

            .format("ll")
        : "-",
  },

  {
    name: "Departure Airport",
    selector: "estimateData[0].departureAirportName",
    cell: (row) => {
      if (
        !row.estimateData ||
        !row?.estimateData?.length ||
        !row?.estimateData[0] ||
        !row?.estimateData[0]?.departureAirportName
      ) {
        return "-";
      }
      let airportArr = row.estimateData[0].departureAirportName.split(", ");
      return `${airportArr[0]}, ${airportArr[2]}, ${airportArr[3]}`;
    },
    // sortable: true,
    width: "290px",
  },

  {
    name: "Arrival Airport",
    selector: "estimateData[0].arrivalAirportName",
    cell: (row) => {
      if (
        !row.estimateData ||
        !row?.estimateData?.length ||
        !row?.estimateData[0] ||
        !row?.estimateData[0]?.arrivalAirportName
      ) {
        return "-";
      }

      let airportArr = row.estimateData[0].arrivalAirportName.split(", ");
      return `${airportArr[0]}, ${airportArr[2]}, ${airportArr[3]}`;
    },
    // sortable: true,
    width: "290px",
  },
  {
    name: "Pax",
    selector: "estimateData[0].passengerCount",
    cell: (row) => {
      if (
        row.estimateData &&
        row.estimateData[0] &&
        row.estimateData[0].passengerCount
      ) {
        return row.estimateData[0].passengerCount;
      }
    },
    sortable: true,
    width: "150px",
  },
  {
    name: "Company",
    selector: "companyName",
    sortable: true,
    width: "150px",
  },

  // {
  //   name: "Phone Country Code",
  //   selector: "phoneCountryCode",
  //   sortable: true,
  //   width: "220px",
  // },

  {
    name: "Comments",
    selector: "comments",
    sortable: true,
    width: "350px",
  },
  {
    name: "Lead Origin",
    selector: "leadOrigin",
    sortable: true,
    width: "150px",
    cell: (row) => {
      let formatted = "";
      if (row.leadOrigin === "qfs") {
        formatted = "Flight Deck - QFS";
      } else if (row.leadOrigin === "liveAPI") {
        formatted = "Live API";
      } else if (row.leadOrigin === "phoneCall") {
        formatted = "Phone Call";
      } else if (row.leadOrigin === "flightDeckInternal") {
        formatted = "Flight Deck - Internal";
      } else if (row.leadOrigin === "flightDeckExternal") {
        formatted = "Flight Deck - External";
      } else if (row.leadOrigin === "apiV3") {
        formatted = "Outside Org API";
      } else {
        formatted = "Unset";
      }
      return formatted;
    },
  },
  {
    name: "Trip Type",
    selector: "tripType",
    sortable: true,
    width: "150px",
    cell: (row) => (row.tripType === 1 ? "One Way" : "Round Trip"),
  },

  {
    name: "Stage",
    selector: "stage",
    sortable: true,
    width: "130px",
    cell: (row) => {
      // console.log("STAGE: ", row._id, row.stage);
      if (row.stage === "newLead") {
        return "New Lead";
      } else if (row.stage === "inProgress") {
        return "In Progress";
      } else if (row.stage === "archived") {
        return "Archived";
      } else if (row.stage === "warmProspect") {
        return "Warm Prospect";
      } else if (row.stage === "unqualified") {
        return "Unqualified";
      } else if (row.stage === "inLimbo") {
        return "In Limbo";
      } else if (row.stage === "noReply") {
        return "Lost - No Reply";
      } else if (row.stage === "signedContract") {
        return "Signed Contract";
      } else if (row.stage === "tripComplete") {
        return "Trip Complete";
      } else if (row.stage === "closedNoReply") {
        return "Closed - No Reply";
      } else if (row.stage === "closedGhost") {
        return "Closed - Ghost";
      } else if (row.stage === "repeatClient" || row.stage === "closedRepeat") {
        return "Lost - Repeat Client";
      } else if (row.stage === "closedCompetitor") {
        return "Closed - Lost to Competitor";
      } else if (row.stage === "inProgressTemp") {
        return "Soft-Deleted";
      } else if (row.stage === "noReply") {
        return "No Reply";
      } else if (row.stage === "hotProspect") {
        return "Hot Prospect";
      }
    },
  },

  {
    name: "Assigned At",
    selector: "assignedAt",
    sortable: true,
    width: "200px",
    cell: (row) => {
      if (row.assignedAt) {
        return moment(row.assignedAt).format("llll");
      }

      return "-";
    },
  },
  {
    name: "Opportunity (OR)",
    selector: "orOpportunityId",
    sortable: false,
    width: "190px",
    cell: (row) => (
      <a
        href={`https://app1a.outreach.io/opportunities/${row.orOpportunityId}/activity`}
        target="_blank"
      >
        {row.orOpportunityId}
      </a>
    ),
  },
  {
    name: "Prospect (OR)",
    selector: "orProspectId",
    sortable: false,
    width: "150px",
    cell: (row) => (
      <a
        href={`https://app1a.outreach.io/prospects/${row.orProspectId}/activity`}
        target="_blank"
      >
        {row.orProspectId}
      </a>
    ),
  },
];

export default columns;
