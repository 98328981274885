import React, { Fragment, useReducer, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRangePicker } from "react-date-range";
import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";
import moment from "moment";

import currencyFormatter from "../../helpers/currencyFormatter";
import timeFormatter from "../../helpers/timeFormatter";

import Select from "react-select";

// import { Line } from "react-chartjs-2";

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from "date-fns";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const columns = [
  {
    name: "Assignee",
    sortable: true,
    selector: "assignee",
    // cell: (row) => {
    //   // return row.assignee === null ? "Unassigned" : row.assigneeName;
    // },
    width: "170px",
  },
  {
    name: "Total",
    sortable: true,
    selector: "total",
    // cell: (row) => {
    //   return row.total;
    // },
    width: "100px",
  },
  {
    name: "Nonarchived",
    sortable: true,
    selector: "nonarchivedData.total",
    // cell: (row) => {
    //   return 0;
    // },
    width: "120px",
  },
  {
    name: "Unqualified",
    sortable: true,
    selector: "unqualifiedData.total",
    cell: (row) => {
      return row.unqualifiedData?.total || 0;
    },
    width: "120px",
  },
  {
    name: "Warm Prospect",
    sortable: true,
    selector: "warmProspectData.total",
    cell: (row) => {
      return row.warmProspectData?.total || 0;
    },
    width: "170px",
  },
  {
    name: "Hot Prospect",
    sortable: true,
    selector: "hotProspectData.total",
    cell: (row) => {
      return row.hotProspectData?.total || 0;
    },
    width: "170px",
  },
  {
    name: "No Reply",
    sortable: true,
    selector: "noReplyData.total",
    cell: (row) => {
      return row.noReplyData?.total || 0;
    },
    width: "170px",
  },
  {
    name: "In Progress",
    sortable: true,
    selector: "inProgressData.total",
    cell: (row) => {
      return row.inProgressData?.total || 0;
    },
    width: "150px",
  },
  {
    name: "Archived",
    sortable: true,
    selector: "archivedData.total",
    cell: (row) => {
      return row.archivedData?.total || 0;
    },
    width: "150px",
  },
];

const sourcingWidgetColumns = [
  {
    name: "Class",
    sortable: true,
    selector: "type",
    // cell: (row) => {
    //   // return row.assignee === null ? "Unassigned" : row.assigneeName;
    // },
    width: "170px",
  },
  {
    name: "Count",
    sortable: true,
    selector: "count",
    // cell: (row) => {
    //   return row.total;
    // },
    width: "100px",
  },
  {
    name: "Flight Hours",
    sortable: true,
    selector: "timesAndHours.flightTime",
    cell: (row) => {
      if (
        row?.timesAndHours?.flightTime &&
        !isNaN(row?.timesAndHours?.flightTime)
      ) {
        return timeFormatter(row.timesAndHours.flightTime);
      }

      return "-";
    },
    width: "120px",
  },
  {
    name: "Operator Cost",
    sortable: true,
    selector: "timesAndHours.operatorPrice",
    cell: (row) => {
      if (
        row?.timesAndHours?.operatorPrice &&
        !isNaN(row?.timesAndHours?.operatorPrice)
      ) {
        return currencyFormatter.format(row.timesAndHours.operatorPrice);
      }

      return "-";
    },
    width: "120px",
  },
  {
    name: "Evo Cost",
    sortable: true,
    selector: "timesAndHours.totalClientPayment",
    cell: (row) => {
      if (
        row?.timesAndHours?.totalClientPayment &&
        !isNaN(row?.timesAndHours?.totalClientPayment)
      ) {
        return currencyFormatter.format(row.timesAndHours.totalClientPayment);
      }

      return "-";
    },
    width: "120px",
  },
  {
    name: "Operator Hourly",
    sortable: true,
    selector: "timesAndHours.averageOperatorHourly",
    cell: (row) => {
      if (
        row?.timesAndHours?.averageOperatorHourly &&
        !isNaN(row?.timesAndHours?.averageOperatorHourly)
      ) {
        return currencyFormatter.format(
          row.timesAndHours.averageOperatorHourly
        );
      }

      return "-";
    },
    width: "120px",
  },
  {
    name: "Evo Hourly",
    sortable: true,
    selector: "timesAndHours.averageEvoHourly",
    cell: (row) => {
      if (
        row?.timesAndHours?.averageEvoHourly &&
        !isNaN(row?.timesAndHours?.averageEvoHourly)
      ) {
        return currencyFormatter.format(row.timesAndHours.averageEvoHourly);
      }

      return "-";
    },
    width: "120px",
  },
];

const columnsDateAllLeads = [
  {
    name: "Date",
    sortable: true,
    selector: "date.date",
    cell: (row) => {
      if (row.date.date === "Overall Totals") {
        return row.date.date;
      }
      return moment.utc(new Date(row.date.date)).format("MM/DD/YYYY");
    },
    width: "170px",
  },
  {
    name: "Day of Week",
    sortable: true,
    selector: "dayOfWeek",
    cell: (row) => {
      if (row.date.date === "Overall Totals") {
        return "-";
      }
      return row.dayOfWeek;
      // return moment.utc(new Date(row.date.date)).format("dddd");
    },
    width: "170px",
  },
  {
    name: "Total",
    sortable: true,
    selector: "total",
    cell: (row) => {
      return row?.date?.total;
    },
    width: "120px",
  },
  {
    name: "QFS",
    sortable: true,
    selector: "qfsTotal",
    width: "120px",
  },
  {
    name: "RFP",
    sortable: true,
    selector: "liveAPITotal",
    width: "120px",
  },
  {
    name: "Phone Calls",
    sortable: true,
    selector: "phoneCallTotal",
    width: "150px",
  },
  {
    name: "Outside Org",
    sortable: true,
    selector: "flightDeckExternalTotal",
    width: "150px",
  },
];

const columnsDate = [
  {
    name: "Date",
    sortable: true,
    selector: "date",
    cell: (row) => {
      if (row.date === "Overall Totals") {
        return row.date;
      }
      return moment.utc(new Date(row.date)).format("MM/DD/YYYY");
    },
    width: "170px",
  },
  {
    name: "Day of Week",
    sortable: true,
    selector: "dayOfWeek",
    cell: (row) => {
      if (row.date === "Overall Totals") {
        return "-";
      }
      return row.dayOfWeek;
    },
    width: "170px",
  },
  {
    name: "Total",
    sortable: true,
    selector: "total",
    cell: (row) => {
      return row?.total;
    },
    width: "120px",
  },
];

const columnsDateRFPPhoneCall = [
  {
    name: "Date",
    sortable: true,
    selector: "date",
    cell: (row) => {
      if (row.date === "Overall Totals") {
        return row.date;
      }
      return moment.utc(new Date(row.date)).format("MM/DD/YYYY");
    },
    width: "170px",
  },
  {
    name: "Day of Week",
    sortable: true,
    selector: "dayOfWeek",
    cell: (row) => {
      if (row.date === "Overall Totals") {
        return "-";
      }
      return row.dayOfWeek;
    },
    width: "170px",
  },
  {
    name: "Total",
    sortable: true,
    selector: "total",
    cell: (row) => {
      return row?.total;
    },
    width: "120px",
  },
  {
    name: "RFP",
    sortable: true,
    selector: "rfpTotal",
    width: "120px",
  },
  {
    name: "Phone Calls",
    sortable: true,
    selector: "phoneCallTotal",
    width: "150px",
  },
];

moment.locale("en", {
  week: {
    dow: 1,
  },
});

const defineds = {
  startOfWeek: moment(new Date()).startOf("week").toDate(),
  endOfWeek: moment(new Date()).endOf("week").toDate(),
  startOfLastWeek: moment(addDays(new Date(), -7)).startOf("week").toDate(),
  // set to beginning of day, on last day of week
  // endOfLastWeek: startOfDay(endOfWeek(addDays(new Date(), -7))),
  endOfLastWeek: moment(addDays(new Date(), -7)).endOf("week").toDate(),

  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  // reset to beginning of day
  endOfMonth: startOfDay(endOfMonth(new Date())),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  // reset to beginning of day
  endOfLastMonth: startOfDay(endOfMonth(addMonths(new Date(), -1))),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "selectionChanged": {
      return {
        ...state,
        selection: {
          ...action.value,
        },
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        operator: action.payload.data.operator,
        opiWeights: action.payload.data.opiWeights,
      };
    }
    default:
      break;
  }
  return state;
};

const defaultInputRanges = [
  {
    label: "days up to today",
    range(value) {
      console.log("VALUE", value);
      if (value) {
        return {
          startDate: addDays(
            defineds.startOfToday,
            (Math.max(Number(value), 1) - 1) * -1
          ),
          endDate: defineds.endOfToday,
          clearField: false,
        };
      }
      return {
        startDate: new Date(),
        endDate: new Date(),
        clearField: true,
      };
    },
    getCurrentValue(range) {
      console.log("RANGE", range);
      if (range.clearField) return "";
      if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
      if (!range.startDate) return "∞";
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
    },
  },
];

const defaultStaticRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.startOfToday,
    }),
  },
  {
    label: "Yesterday",
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.startOfYesterday,
    }),
  },

  {
    label: "This Week",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
]);

const AdminWidgets = () => {
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();

  const initialState = {
    selection: {
      startDate: startOfDay(new Date()),
      endDate: startOfDay(new Date()),
      key: "selection",
    },
    recordType: "allLeads",
    titleValue: "All Opportunities",
    recordTypeSelect: {
      label: "All Opportunities",
      value: "allLeads",
      titleValue: "All Opportunities",
    },
    data: {},
    sourcingWidgetData: null,
    showSourcingWidget: false,
    dateTableData: null,
  };

  const handleSelect = (ranges) => {
    if (ranges.selection.endDate > startOfDay(new Date())) {
      ranges.selection.endDate = startOfDay(new Date());
    }

    dispatch({
      type: "selectionChanged",
      value: ranges.selection,
    });
    console.log(ranges);
  };

  const handleSubmit = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log(state.selection.startDate, state.selection.endDate);

    console.log(
      state.selection.startDate.toISOString(),
      state.selection.endDate.toISOString()
    );

    if (!state.recordType) {
      alert("Please select a record type.");
      return;
    }

    if (state.recordType !== "sourcingRecords") {
      dispatch({
        type: "fieldChanged",
        field: "showSourcingWidget",
        value: false,
      });
    } else {
      dispatch({
        type: "fieldChanged",
        field: "dateTableData",
        value: null,
      });

      dispatch({
        type: "fieldChanged",
        field: "assigneeTableData",
        value: null,
      });

      dispatch({ type: "fieldChanged", field: "data", value: null });
    }

    let leadType = [
      "qfs",
      "liveAPI",
      // "phoneCall",
      "flightDeckInternal",
      "flightDeckExternal",
    ];
    if (state.recordType === "qfs") {
      leadType = ["qfs"];
    }

    if (state.recordType === "rfp" || state.recordType === "rfp+phoneCall") {
      leadType = ["liveAPI"];
    }

    let response = await ai
      .auth(token)
      .get(`/api/widgets/getRecordsForDateRange`, {
        params: {
          startDate: state.selection.startDate,
          endDate: state.selection.endDate,
          recordType: state.recordType,
          leadType: leadType,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      console.log("RESPONSE DATA", response.data);

      if (state.recordType === "sourcingRecords") {
        dispatch({
          type: "fieldChanged",
          field: "showSourcingWidget",
          value: true,
        });

        dispatch({
          type: "fieldChanged",
          field: "sourcingWidgetData",
          value: response.data,
        });

        dispatch({
          type: "fieldChanged",
          field: "responseRecordType",
          value: response.data.recordType,
        });

        return document
          .getElementById("recordWidgetResults")
          .scrollIntoView({ behavior: "smooth" });
      }

      const assigneeTableData = consolidateStages(
        response.data.docs.assigneeBreakdown
      );

      let dateTableData = determineTotals(response.data.docs.groupedByDate);

      if (response.data.recordType === "allLeads") {
        dateTableData = consolidateDates(response.data.docs.groupedByDate);
      }
      dispatch({ type: "fieldChanged", field: "data", value: response.data });
      dispatch({
        type: "fieldChanged",
        field: "assigneeTableData",
        value: assigneeTableData,
      });

      dispatch({
        type: "fieldChanged",
        field: "responseRecordType",
        value: response.data.recordType,
      });
      dispatch({
        type: "fieldChanged",
        field: "dateTableData",
        value: dateTableData,
      });

      document
        .getElementById("recordWidgetResults")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const consolidateStages = (assigneeBreakdown) => {
    const assigneeBreakdownData = [...assigneeBreakdown];

    console.log("ASSIGNEE BREAKDOWN DATA", assigneeBreakdownData);

    let formattedData = assigneeBreakdownData.map((item) => {
      return {
        total: item.total,
        assigneeName: item.groupedByData.assigneeName || "Unassigned",
        stage: item.groupedByData.stage || "No Stage",
        type: item?.itemType,
      };
    });

    console.log("FORMATTED DATA", formattedData);

    const assignees = formattedData.map((item) => item.assigneeName);
    const uniqueAssignees = assignees.filter(
      (assignee, index) => assignees.indexOf(assignee) === index
    );

    const assigneeTotals = uniqueAssignees.map((assignee) => {
      const total = formattedData
        .filter((item) => item.assigneeName === assignee)
        .reduce((count, arrayItem) => count + arrayItem.total, 0);
      return { assignee, total };
    });

    console.log("ASSIGNEE TOTALS", assigneeTotals);

    const assigneeTotalsWithStageBreakdown = assigneeTotals.map((assignee) => {
      const stageData = formattedData.filter(
        (item) => item.assigneeName === assignee.assignee
      );

      // TO DO - update stage summation. Currently it's taking the first array item, but we need to sum if multiple array items match

      let archivedData = {
        total:
          stageData
            .filter((item) => item.stage === "archived")
            .reduce((count, arrayItem) => count + arrayItem.total, 0) || 0,
      };
      let warmProspectData = {
        total:
          stageData
            .filter((item) => item.stage === "warmProspect")
            .reduce((count, arrayItem) => count + arrayItem.total, 0) || 0,
      };

      let hotProspectData = {
        total:
          stageData
            .filter((item) => item.stage === "hotProspect")
            .reduce((count, arrayItem) => count + arrayItem.total, 0) || 0,
      };

      let noReplyData = {
        total:
          stageData
            .filter((item) => item.stage === "noReply")
            .reduce((count, arrayItem) => count + arrayItem.total, 0) || 0,
      };

      let unqualifiedData = {
        total:
          stageData
            .filter((item) => item.stage === "unqualified")
            .reduce((count, arrayItem) => count + arrayItem.total, 0) || 0,
      };

      console.log("UNQUALIFIED DATA", unqualifiedData);

      let inProgressData = {
        total:
          stageData
            .filter((item) => item.stage === "inProgress")
            .reduce((count, arrayItem) => count + arrayItem.total, 0) || 0,
      };

      const nonarchivedData = { total: assignee.total - archivedData.total };

      return {
        assignee: assignee.assignee,
        total: assignee.total,
        stageData,
        archivedData,
        warmProspectData,
        unqualifiedData,
        inProgressData,
        nonarchivedData,
        hotProspectData,
        noReplyData,
      };
    });

    const archivedTotal = assigneeTotalsWithStageBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.archivedData.total,
      0
    );

    const inProgressTotal = assigneeTotalsWithStageBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.inProgressData.total,
      0
    );

    const overallTotal = assigneeTotalsWithStageBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.total,
      0
    );

    const warmProspectTotal = assigneeTotalsWithStageBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.warmProspectData.total,
      0
    );

    const unqualifiedTotal = assigneeTotalsWithStageBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.unqualifiedData.total,
      0
    );

    const hotProspectTotal = assigneeTotalsWithStageBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.hotProspectData.total,
      0
    );

    const noReplyTotal = assigneeTotalsWithStageBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.noReplyData.total,
      0
    );

    const nonarchivedTotal = overallTotal - archivedTotal;

    assigneeTotalsWithStageBreakdown.push({
      assignee: "Overall Totals",
      total: overallTotal,
      archivedData: { total: archivedTotal || 0 },
      inProgressData: { total: inProgressTotal || 0 },
      warmProspectData: { total: warmProspectTotal || 0 },
      hotProspectData: { total: hotProspectTotal || 0 },
      noReplyData: { total: noReplyTotal || 0 },

      unqualifiedData: { total: unqualifiedTotal || 0 },
      nonarchivedData: { total: nonarchivedTotal || 0 },
    });

    console.log(assigneeTotalsWithStageBreakdown);

    return assigneeTotalsWithStageBreakdown;
  };

  const determineTotals = (dateBreakdown) => {
    let formattedData = [...dateBreakdown];
    const overallTotal = formattedData.reduce(
      (count, arrayItem) => count + arrayItem.total,
      0
    );

    formattedData = formattedData.map((item) => {
      return {
        ...item,
        dayOfWeek: moment.utc(new Date(item.date)).format("dddd"),
      };
    });

    formattedData.push({
      date: "Overall Totals",
      total: overallTotal,
      dayOfWeek: "-",
    });

    return formattedData;
  };

  const consolidateDates = (dateBreakdown) => {
    const formattedData = [...dateBreakdown];

    // console.log("FORMATTED DATES", formattedData);

    const dates = formattedData.map((item) => item.date);
    const uniqueDates = dates.filter(
      (date, index) => dates.indexOf(date) === index
    );

    const dateTotals = uniqueDates.map((date) => {
      const total = formattedData
        .filter((item) => item.date === date)
        .reduce((count, arrayItem) => count + arrayItem.total, 0);
      return { date, total };
    });

    console.log("DATE TOTALS", dateTotals);

    const dateTotalsWithTypeBreakdown = dateTotals.map((date) => {
      let dateString = date.date;

      const leadTypeData = formattedData.filter(
        (item) => item.date === dateString
      );

      // console.log(leadTypeData);
      const qfsTotal =
        leadTypeData.filter((item) => item.type === "qfs")[0]?.total || 0;
      const liveAPITotal =
        leadTypeData.filter((item) => item.type === "liveAPI")[0]?.total || 0;
      const flightDeckExternalTotal =
        leadTypeData.filter((item) => item.type === "flightDeckExternal")[0]
          ?.total || 0;
      const flightDeckInternalTotal =
        leadTypeData.filter((item) => item.type === "flightDeckInternal")[0]
          ?.total || 0;
      const phoneCallTotal =
        leadTypeData.filter((item) => item.type === "phoneCall")[0]?.total || 0;

      // console.log("QFS DATA", qfsData);

      return {
        date,
        qfsTotal,
        liveAPITotal,
        flightDeckExternalTotal,
        flightDeckInternalTotal,
        phoneCallTotal,
        dayOfWeek: moment.utc(new Date(dateString)).format("dddd"),
      };
    });

    const overallTotal = formattedData.reduce(
      (count, arrayItem) => count + arrayItem.total,
      0
    );

    const overallTotalQFS = dateTotalsWithTypeBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.qfsTotal,
      0
    );

    const overallTotalRFP = dateTotalsWithTypeBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.liveAPITotal,
      0
    );

    const overallTotalPhoneCalls = dateTotalsWithTypeBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.phoneCallTotal,
      0
    );

    const overallTotalOutsideOrg = dateTotalsWithTypeBreakdown.reduce(
      (count, arrayItem) => count + arrayItem.flightDeckExternalTotal,
      0
    );

    console.log(overallTotal, "QFS", overallTotalQFS);

    dateTotalsWithTypeBreakdown.push({
      date: { date: "Overall Totals", total: overallTotal },
      total: overallTotal,
      qfsTotal: overallTotalQFS,
      liveAPITotal: overallTotalRFP,
      phoneCallTotal: overallTotalPhoneCalls,
      flightDeckExternalTotal: overallTotalOutsideOrg,
      dayOfWeek: "-",
    });

    return dateTotalsWithTypeBreakdown;
  };

  const handleRecordTypeChange = (recordType) => {
    dispatch({
      type: "fieldChanged",
      field: "recordType",
      value: recordType.value,
    });

    dispatch({
      type: "fieldChanged",
      field: "recordTypeSelect",
      value: recordType,
    });

    dispatch({
      type: "fieldChanged",
      field: "titleValue",
      value: recordType.titleValue,
    });
    return recordType;
  };

  let finalColumns = columnsDate;
  if (state.recordType === "allLeads") {
    finalColumns = columnsDateAllLeads;
  }

  if (state.recordType === "rfp+phoneCall") {
    finalColumns = columnsDateRFPPhoneCall;
  }

  return (
    <div id="recordWidgetContainer">
      <div id="recordWidgetControls">
        <h1>
          {state.titleValue === "All Opportunities"
            ? `View All Opportunities`
            : `View ${state.titleValue} Records`}
        </h1>

        <div className="form-group ml-0">
          <label className="base-label">Record Type</label>
          <Select
            className="recordType-select"
            classNamePrefix="select"
            options={[
              {
                label: "All Opportunities",
                value: "allLeads",
                titleValue: "All Opportunities",
              },
              {
                label: "RFPs + Phone Calls",
                value: "rfp+phoneCall",
                titleValue: "RFP + Phone Call",
              },

              { label: "RFPs", value: "rfp", titleValue: "RFP" },
              {
                label: "Phone Calls",
                value: "phoneCall",
                titleValue: "Phone Call",
              },
              { label: "Estimates", value: "estimate", titleValue: "Estimate" },
              { label: "QFS", value: "qfs", titleValue: "QFS" },
              {
                label: "Legacy Estimates",
                value: "legacyEstimates",
                titleValue: "Legacy Estimate",
              },
              {
                label: "Legacy Leads",
                value: "legacyLeads",
                titleValue: "Legacy Lead",
              },
              {
                label: "Sourcing Records",
                value: "sourcingRecords",
                titleValue: "Sourcing Record",
              },
            ]}
            placeholder="Record Type"
            onChange={handleRecordTypeChange}
            value={state.recordTypeSelect}
            key={`key-${state.recordTypeSelect}`}
          ></Select>
        </div>

        <div className="date-range-picker-container">
          <DateRangePicker
            ranges={[state.selection]}
            onChange={handleSelect}
            maxDate={addDays(new Date(), 0)}
            inputRanges={defaultInputRanges}
            staticRanges={defaultStaticRanges}
          />
        </div>
        <button
          className="base-button evo-blue d-block w-100 mb-20"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>

      <div id="recordWidgetResults">
        {state.assigneeTableData &&
          state.responseRecordType !== "estimate" &&
          state.responseRecordType !== "legacyEstimates" && (
            <div id="breakdownByAssignee">
              <h4>Breakdown by Assignee</h4>
              <div className="dataTableWithShadow">
                <div className="innerDataTableContainer">
                  {/* <DataTableExtensions
                    data={state.assigneeTableData}
                    columns={columns}
                    exportHeaders={true}
                    fileName={`${
                      state.responseRecordType
                    } - ${state.selection.startDate.toLocaleDateString()} - ${state.selection.endDate.toLocaleDateString()}`}
                  > */}
                  <DataTable
                    data={state.assigneeTableData}
                    columns={columns}
                    noHeader
                    highlightOnHover
                    pagination={true}
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                    defaultSortField="total"
                    defaultSortAsc={false}
                    responsive={true}
                  ></DataTable>
                  {/* </DataTableExtensions> */}
                </div>
              </div>
            </div>
          )}

        {state?.dateTableData &&
          !state.showSourcingWidget &&
          state.responseRecordType !== "sourcingRecords" && (
            <div id="breakdownByDate">
              <h4>Breakdown by Date</h4>
              <div className="dataTableWithShadow">
                <div className="innerDataTableContainer">
                  <DataTable
                    data={state.dateTableData}
                    columns={finalColumns}
                    noHeader
                    highlightOnHover
                    pagination={true}
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                    defaultSortField="date"
                    defaultSortAsc={true}
                    responsive={true}
                  ></DataTable>
                </div>
              </div>
            </div>
          )}

        {state.showSourcingWidget &&
          state?.sourcingWidgetData?.docs?.length > 0 && (
            <div id="breakdownByDate">
              <h4>Breakdown by Aircraft Class</h4>
              <div className="dataTableWithShadow">
                <div className="innerDataTableContainer">
                  <DataTable
                    data={state.sourcingWidgetData?.docs}
                    columns={sourcingWidgetColumns}
                    noHeader
                    highlightOnHover
                    pagination={true}
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                    defaultSortField="date"
                    defaultSortAsc={true}
                    responsive={true}
                  ></DataTable>
                </div>
              </div>
            </div>
          )}

        {state.showSourcingWidget &&
          state?.sourcingWidgetData?.docs?.length === 0 && (
            <h3 className="centered">No Records</h3>
          )}
      </div>

      {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
    </div>
  );
};

export default AdminWidgets;
