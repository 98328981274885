import React, { Fragment, useEffect, useReducer } from "react";

import Select from "react-select";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import AsyncSelect from "react-select/async";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import NumberFormat from "react-number-format";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { Datepicker, momentTimezone } from "@mobiscroll/react";

import ai from "../../helpers/axios";
import timeFormatter from "../../helpers/timeFormatter";

import ProspectsAutocomplete from "../prospects/prospects-autocomplete";

import TextInput from "../text-input";
import Loader from "../loader";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const invoiceTypesOptions = [
  {
    label: "Flight Charges",
    value: "flightCharges",
  },
  {
    label: "Ground Transportation",
    value: "groundTransportation",
  },
  {
    label: "Catering",
    value: "catering",
  },
  {
    label: "Other",
    value: "misc",
  },
];

const paymentOptions = [
  {
    label: "Credit Card",
    value: "creditCard",
  },
  {
    label: "Wire",
    value: "wire",
  },
  {
    label: "Funds on Account",
    value: "fundsOnAccount",
  },
  {
    label: "Other",
    value: "other",
  },
];

const NewInvoiceContent = (props) => {
  const { tripId, invoiceId } = useParams();
  const history = useHistory();

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const handleInvoiceSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      return loginWithRedirect();
    });

    const invoiceData = {
      createdBy: state.data.createdBy,
      owner: state.data.owner,
      signerName: state.data.contractData[0].signerName,
      signerEmail: state.data.contractData[0].signerEmail,
      signerPhone: state.data.contractData[0].signerPhone,
      signerPhoneCountry: state.data.contractData[0].signerPhoneCountry,
      tripId: tripId,
      invoiceDetails:
        state?.invoiceDetails?.map((detail) => ({
          typeLabel: detail.typeLabel.value,
          description: detail.description || " ", // It can't be empty
          amount: detail.amount,
        })) || [],
      paymentDetails:
        state?.paymentDetails?.map((detail) => ({
          date: detail.date,
          typeLabel: detail.typeLabel.value,
          notes: detail.notes,
          amount: detail.amount,
        })) || [],

      // TODO: Add all legs when we will have airports data from the API
      tripSummary:
        [state?.legs[0]]?.map((leg) => ({
          departureAirport: leg?.departureAirport || "",
          arrivalAirport: leg?.arrivalAirport || "",
          date: leg.departureDate,
          ete: leg?.ete || "",
          pax: leg.passengers,
        })) || [],
    };

    let response;

    if (!invoiceId || invoiceId === "new") {
      if (tripId) {
        response = await ai
          .auth(token)
          .post(`/api/invoices/create/`, invoiceData)
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error);
          });
      }
    } else {
      response = await ai
        .auth(token)
        .put(`/api/invoices/${invoiceId}`, invoiceData)
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }

    if (!!response && response.data) {
      setTimeout(() => {
        toast.success("Invoice saved successfully!", toastOptions);
      }, 0);
    }

    history.push(`/trips/${tripId}/invoices/${response.data._id}/preview`);
  };

  const handleClientOptionCreation = (inputValue) => {
    dispatch({
      type: "changeField",
      field: "createProspectModalIsOpen",
      value: true,
    });
  };

  const handleClientChange = (selectedClient) => {
    if (selectedClient) {
      selectedClient._id = selectedClient?.value;

      dispatch({
        type: "changeField",
        field: "selectedClient",
        value: selectedClient,
      });
    } else {
      dispatch({
        type: "changeField",
        field: "selectedClient",
        value: selectedClient,
      });
    }
  };

  const handleTextInputChange = (e) => {
    return dispatch({
      type: "changeField",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleAddLeg = (event) => {
    event.preventDefault();

    dispatch({ type: "addNewLeg" });
  };

  const handleAddDetail = (event) => {
    event.preventDefault();
    dispatch({ type: "addNewInvoiceDetail" });
  };

  const handleAddPaymentDetail = (event) => {
    event.preventDefault();

    dispatch({ type: "addNewPaymentDetail" });
  };

  const handleAircraftChange = async (legIndex, updatedAircraft) => {
    let legData = { ...state.legs[legIndex] };

    legData.aircraft = updatedAircraft;

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const loadAircraftOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/aircrafts/search?q=${inputValue}`, {
        params: {
          query: inputValue,
          page: 1,
          countPerPage: 50,
        },
      });

    if ((data?.aircraf?.length || 0) >= 1) {
      return data.aircraft.map((aircraft) => {
        return {
          value: aircraft._id,
          label: aircraft.name,
          id: aircraft._id,
          yom: aircraft.yom,
          name: aircraft.name,
          opi: aircraft.opi,
          seats: aircraft.seats,
          speed: aircraft.cruiseSpeed,
        };
      });
    } else {
      return [];
    }
  };

  const handleETEChange = async (legIndex, updatedETE) => {
    let leg = { ...state.legs[legIndex] };

    let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    const isValid = pattern.test(updatedETE);

    if (!isValid) {
      dispatch({
        type: "changeLeg",
        payload: {
          legIndex: legIndex,
          field: "ete",
          value: "",
        },
      });

      return toast.error(
        `Please enter a valid ETE for Leg ${legIndex + 1}`,
        toastOptions
      );
    }

    if (!state.legs[legIndex]?.etd) {
      return toast.error(
        `Please enter a valid ETD for Leg ${
          legIndex + 1
        } to recalculate the ETA.`,
        toastOptions
      );
    }

    let etaResponse = await calculateETAForLeg(
      updatedETE,
      state.legs[legIndex]?.etd,
      leg
    );

    dispatch({
      type: "changeLeg",
      payload: {
        field: "eta",
        value: etaResponse.data?.eta,
        legIndex,
      },
    });
  };

  const handleFlightTimeCalculationByIndex = async (legData, legIndex) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let params = {
      legData: {
        arrivalAirportId: legData.arrivalAirport.id,
        departureAirportId: legData.departureAirport.id,
        legIndex: legIndex,
        aircraftId: legData.aircraft.id,
      },
    };

    let response = await ai
      .auth(token)
      .post(`/api/trips/flightTimes`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (
      response &&
      response?.data &&
      response?.data?.length &&
      response?.data[0]?.value
    ) {
      let returnedValueForLeg = response?.data[0]?.value;

      let composedString = "";

      if (returnedValueForLeg?.timeWithWindAdjustment) {
        composedString = timeFormatter(
          returnedValueForLeg?.timeWithWindAdjustment
        );
      }

      dispatch({
        type: "changeLeg",
        payload: {
          legIndex,
          field: "ete",
          value: composedString,
        },
      });

      handleETEChange(legIndex, composedString);

      return toast.success(`ETE and ETA for Leg ${legIndex + 1} were updated.`);
    }
  };

  const handleAirportChange = async (legIndex, airport, airportIndex) => {
    let legData = { ...state.legs[legIndex] };

    if (airportIndex === 0) {
      legData.departureAirport = {
        id: airport.value,
        name: airport.label,
      };

      let departureTimeZone = null;
      if (airport.tz && airport.tz.length) {
        departureTimeZone = airport.tz[0];
      }

      if (departureTimeZone?.length) {
        dispatch({
          type: "changeLeg",
          payload: {
            legIndex: legIndex,
            field: "departureTimeZone",
            value: departureTimeZone,
          },
        });
      }
    }

    if (airportIndex === 1) {
      legData.arrivalAirport = {
        id: airport.value,
        name: airport.label,
      };

      let arrivalTimeZone = null;

      if (airport.tz && airport.tz.length) {
        arrivalTimeZone = airport.tz[0];
      }

      if (arrivalTimeZone?.length) {
        dispatch({
          type: "changeLeg",
          payload: {
            legIndex: legIndex,
            field: "arrivalTimeZone",
            value: arrivalTimeZone,
          },
        });
      }
    }

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const loadAirportOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&includeTZ=true`);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        city: i.city,
        localCode: i.localCode,
        lat: i.lat,
        lon: i.lon,
        tz: i.tz,
      }));
    }

    return options;
  };

  const calculateETAForLeg = async (ete, etd, leg) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let etaFactors = {
      ete,
      etd,
      leg,
    };

    //re-calculate ETA

    let response = await ai
      .auth(token)
      .post(`/api/trips/calculateETAOneLeg`, etaFactors)
      .catch((err) => {
        console.error(err.response.data);
        return;
      });

    return response;
  };

  // const handleETDChange = async (legIndex, updatedETD) => {
  //   let leg = { ...state.legs[legIndex] };

  //   let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

  //   const isValid = pattern.test(leg.ete);

  //   if (!isValid && leg.ete !== "" && leg.ete !== null) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         legIndex: legIndex,
  //         field: "ete",
  //         value: "",
  //       },
  //     });

  //     return toast.error(
  //       `Please enter a valid ETE for Leg ${
  //         legIndex + 1
  //       } to recalculate the ETA.`,
  //       toastOptions
  //     );
  //   }

  //   let etaResponse = await calculateETAForLeg(leg.ete, updatedETD, leg);
  //   if (!etaResponse.data) {
  //     return;
  //   }

  //   if (etaResponse.data?.eta) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         field: "eta",
  //         value: etaResponse?.data?.eta,
  //         legIndex,
  //       },
  //     });
  //   }

  //   if (etaResponse.data?.departureTimeZone) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         field: "departureTimeZone",
  //         value: etaResponse?.data?.departureTimeZone,
  //         legIndex,
  //       },
  //     });
  //   }

  //   if (etaResponse.data?.arrivalTimeZone) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         field: "arrivalTimeZone",
  //         value: etaResponse?.data?.arrivalTimeZone,
  //         legIndex,
  //       },
  //     });
  //   }
  // };

  const reducer = (state, action) => {
    switch (action.type) {
      case "addNewLeg": {
        return {
          ...state,
          legs: [
            ...state.legs,
            {
              departureDate: "",
              departureAirport: {
                airportName: "",
                id: "",
                code: "",
                city: "",
                localCode: "",
              },
              arrivalAirport: {
                airportName: "",
                id: "",
                code: "",
                city: "",
                localCode: "",
              },
              passengers: "",
              aircraft: {
                name: "",
                seats: 8,
                yom: "",
                opi: "",
                id: "",
              },
            },
          ],
        };
      }
      case "deleteLeg": {
        let newLegsArray = [...state.legs];
        newLegsArray.splice(action.index, 1);
        return {
          ...state,
          legs: newLegsArray,
        };
      }
      case "changeLeg": {
        let newLegsArray = [...state.legs];
        newLegsArray[action?.payload?.legIndex][action?.payload?.field] =
          action?.payload?.value;
        return {
          ...state,
          legs: newLegsArray,
        };
      }
      case "addNewInvoiceDetail": {
        return {
          ...state,
          legs: action.payload?.legs || state.legs,
          invoiceDetails: [
            ...state.invoiceDetails,
            {
              index: action.payload?.index || 0,
              typeLabel: action.payload?.typeLabel || { label: "", value: "" },
              description: action.payload?.description || "",
              amount: action.payload?.amount || "",
            },
          ],
        };
      }
      case "deleteInvoiceDetail": {
        let newDetailsArray = [...state.invoiceDetails];
        newDetailsArray.splice(action.index, 1);
        return {
          ...state,
          invoiceDetails: newDetailsArray,
        };
      }
      case "changeInvoiceDetailField": {
        let invoiceDetailsArray = [...state.invoiceDetails];
        invoiceDetailsArray[action?.payload?.invoiceDetailIndex][
          action?.payload?.field
        ] = action?.payload?.value;
        return {
          ...state,
          invoiceDetails: invoiceDetailsArray,
        };
      }
      case "addNewPaymentDetail": {
        return {
          ...state,
          paymentDetails: [
            ...state.paymentDetails,
            {
              date: "",
              typeLabel: { label: "", value: "" },
              notes: "",
              amount: "",
            },
          ],
        };
      }
      case "changePaymentDetailField": {
        let newPaymentDetailsArray = [...state.paymentDetails];
        newPaymentDetailsArray[action?.payload?.paymentDetailIndex][
          action?.payload?.field
        ] = action?.payload?.value;
        return {
          ...state,
          paymentDetails: newPaymentDetailsArray,
        };
      }
      case "deletePaymentDetail": {
        let newPaymentDetailsArray = [...state.paymentDetails];
        newPaymentDetailsArray.splice(action.index, 1);

        return {
          ...state,
          paymentDetails: newPaymentDetailsArray,
        };
      }
      case "beginDataDownload": {
        return {
          ...state,
          loading: true,
        };
      }
      case "endDataDownload": {
        return {
          ...state,
          data: action?.payload?.data,
          loading: false,
          dataDownloadedTimestamp: Date.now(),
          operatorFinancialRecords:
            action?.payload?.data?.operatorFinancialRecords,
        };
      }
      case "changeField": {
        return {
          ...state,
          [action.field]: action.value,
        };
      }
      default:
        return state;
    }
  };

  const getTotals = () => {
    let totalCharges = 0;
    let totalPayments = 0;
    let balanceDue = 0;

    state.invoiceDetails?.forEach((detail) => {
      totalCharges += parseFloat(detail.amount);
    });

    state.paymentDetails?.forEach((detail) => {
      totalPayments += parseFloat(detail.amount);
    });

    balanceDue = totalCharges - totalPayments;

    return {
      totalCharges,
      totalPayments,
      balanceDue,
    };
  };

  let initialState = {
    modalPhoneNumber: "",
    selectedClient: null,
    createProspectModalIsOpen: false,
    createAuthorizedSignerModalIsOpen: false,
    routingProcessed: false,

    prospectOptions: [],
    loading: true,
    useDifferentAuthorizedSigner: false,
    companyName: "",
    signerName: "",
    signerEmail: "",
    signerPhone: "",
    signerPhoneCountry: "US",

    authorizedSigners: [],

    paymentType: "",
    flightCharges: "",
    FET: "",
    ccFee: "",
    segmentFees: "",
    catering: "",
    groundTransportation: "",
    otherExpense: "",
    paymentApplied: "",
    discount: "",
    totalCharges: "",

    email: "",
    emailInputValue: "",
    subject: "",

    cc: [],
    ccInputValue: "",

    contractCancellationPolicies: [
      {
        type: 1,
        value: 0,
        amountOfTime: null,
        unitOfTime: null,
      },
    ],

    dusCancellationPolicy: {
      type: 1,
      value: 0,
      dus100: false,
    },

    legs: [],
    invoiceDetails: [],
    paymentDetails: [],
    data: null,
    dataDownloadedTimestamp: null,
    operatorFinancialRecords: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const prepareTripAndInvoiceData = async ({ tripId, invoiceId }) => {
    dispatch({ type: "beginDataDownload" });

    const token = await getAccessTokenSilently().catch((e) => {
      return loginWithRedirect();
    });

    const tripDetails = await ai
      .auth(token)
      .get(`/api/trips/${tripId}`)
      .catch((error) => {
        setTimeout(() => {
          toast.error("Something went wrong! Please try again later!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);
      });

    let selectedInvoice;
    if (invoiceId && invoiceId !== "new") {
      let _response = await ai.auth(token).get(`/api/invoices/list`);

      if (_response && _response.data && _response.data.invoices) {
        selectedInvoice = _response.data.invoices.find(
          (invoice) => invoice._id === invoiceId
        );
      }
    }

    if (tripDetails) {
      dispatch({
        type: "endDataDownload",
        payload: {
          data: tripDetails.data,
        },
      });

      (!invoiceId || invoiceId === "new") &&
        dispatch({
          type: "addNewInvoiceDetail",
          payload: {
            typeLabel: {
              label: "Flight Charges",
              value: "flightCharges",
            },
            description: "Contract",
            amount: tripDetails.data.contractData[0]?.pricing?.paymentApplied,
          },
        });

      dispatch({
        type: "changeField",
        field: "legs",
        value: tripDetails.data.contractData[0].legs,
      });

      parseClientData(tripDetails.data?.clientData[0]);
    }

    if (selectedInvoice) {
      dispatch({
        type: "changeField",
        field: "invoiceDetails",
        value: selectedInvoice?.invoiceDetails?.map((detail) => ({
          ...detail,
          typeLabel: invoiceTypesOptions.find(
            (option) => option.value === detail.typeLabel
          ),
        })),
      });

      selectedInvoice?.tripSummary?.length &&
        dispatch({
          type: "changeField",
          field: "legs",
          value: selectedInvoice.tripSummary,
        });
      dispatch({
        type: "changeField",
        field: "paymentDetails",
        value: selectedInvoice?.paymentDetails?.map((payment) => ({
          ...payment,
          typeLabel: paymentOptions.find(
            (option) => option.value === payment.typeLabel
          ),
        })),
      });
    }
  };

  const parseClientData = (clientData) => {
    if (!clientData) {
      return false;
    }

    if (!clientData?._id || !clientData?.firstName || !clientData?.lastName) {
      //no contact info
      return false;
    }

    if (clientData?.isBusiness && !clientData?.companyName) {
      //client is business but missing company name
      return false;
    }

    let label = `${clientData.firstName} ${clientData?.lastName}`;
    let value = clientData?._id;

    if (clientData?.isBusiness) {
      label = clientData.companyName;

      dispatch({
        type: "changeField",
        field: "companyName",
        value: clientData.companyName,
      });
    }

    if (clientData?.firstName && clientData?.lastName) {
      dispatch({
        type: "changeField",
        field: "signerName",
        value: `${clientData.firstName} ${clientData?.lastName}`,
      });
    }

    if (clientData?.phoneNumbers?.length) {
      if (
        clientData?.phoneNumbers[0]?.number &&
        clientData?.phoneNumbers[0]?.countryCode
      ) {
        dispatch({
          type: "changeField",
          field: "signerPhone",
          value: clientData?.phoneNumbers[0]?.number,
        });
        dispatch({
          type: "changeField",
          field: "signerPhoneCountry",
          value: clientData?.phoneNumbers[0]?.countryCode,
        });
      }
    } else if (clientData?.workPhones?.length) {
      dispatch({
        type: "changeField",
        field: "signerPhone",
        value: clientData?.workPhones[0],
      });
    } else if (clientData?.mobilePhones?.length) {
      dispatch({
        type: "changeField",
        field: "signerPhone",
        value: clientData?.mobilePhones[0],
      });
    }

    parseAuthorizedSigners(clientData);

    if (clientData?.emails?.length) {
      dispatch({
        type: "changeField",
        field: "email",
        value: clientData.emails[0],
      });

      dispatch({
        type: "changeField",
        field: "signerEmail",
        value: clientData.emails[0],
      });
    }

    dispatch({
      type: "changeField",
      field: "selectedClient",
      value: {
        label,
        value,
        ...clientData,
      },
    });
  };

  const parseAuthorizedSigners = (clientData) => {
    if (!clientData?.additionalUsers?.length) {
      return false;
    }

    let authorizedSigners = clientData.additionalUsers.flatMap((item) => {
      if (!item?.isAuthorizedSigner) {
        return [];
      }
      let label = `${item.firstName} ${item.lastName} - ${item.relationship}`;
      let value = item?._id;
      return {
        ...item,
        label,
        value,
      };
    });

    dispatch({
      type: "changeField",
      field: "authorizedSigners",
      value: authorizedSigners,
    });
  };

  useEffect(() => {
    prepareTripAndInvoiceData({
      invoiceId,
      tripId,
    });

    document.title = "Trip Invoice | Flight Deck";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, tripId]);

  if (state.loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <h2 className="base-heading" id="page-heading">
        {invoiceId && invoiceId !== "new" ? `Edit Invoice` : `New Invoice`}
      </h2>

      <form
        id="new-invoice"
        onSubmit={handleInvoiceSubmit}
        className="base-form">
        <ProspectsAutocomplete
          onCreateOption={handleClientOptionCreation}
          handleProspectChange={handleClientChange}
          prospectOptions={[]}
          parentDispatch={dispatch}
          selectedProspect={state.selectedClient}
          label="Client"
          placeholder="Select a Client"
          required
        />

        <div className="flex-row column-gap-1pct four-columns">
          <TextInput
            name="signerName"
            label="Signer Name"
            handleChange={handleTextInputChange}
            value={state.signerName}></TextInput>

          <TextInput
            name="signerEmail"
            label="Signer Email"
            handleChange={handleTextInputChange}
            value={state.signerEmail}
            type={`email`}></TextInput>

          <TextInput
            name="companyName"
            label="On Behalf Of"
            handleChange={handleTextInputChange}
            value={state.companyName}
            className={"companyName"}></TextInput>

          <div className="form-group ml-0" key={`signerPhone`}>
            <label className="phoneInputLabel" htmlFor="phone">
              Phone Number
            </label>

            <PhoneInput
              placeholder="Phone Number"
              value={state?.signerPhone}
              name={"signerPhone"}
              onChange={(value) => {
                dispatch({
                  type: "changeField",
                  field: "signerPhone",
                  value: value,
                });
              }}
              onCountryChange={(value) => {
                dispatch({
                  type: "changeField",
                  field: "signerPhoneCountry",
                  value: value || "",
                });
              }}
              defaultCountry={state?.signerPhoneCountry}
              className="mb-0 base-phone-input"
            />
          </div>
        </div>

        <div className="flight-details-container">
          <h3>Trip Summary</h3>
          {state?.legs?.length > 0 &&
            state?.legs?.map((leg, index) => (
              <div className="flight-details-block" key={index}>
                <div className="flex-row column-gap-1pct">
                  <div className="datepickerContainer mb-0">
                    <label
                      htmlFor={`departureDate[${index}]`}
                      className="base-label">
                      Date <span className="required">*</span>
                    </label>

                    <Datepicker
                      value={leg.departureDate}
                      onChange={(event) => {
                        dispatch({
                          type: "changeLeg",
                          payload: {
                            legIndex: index,
                            field: "departureDate",
                            value: event.value,
                          },
                        });
                      }}
                      dataTimezone="utc"
                      displayTimezone="local"
                      timezonePlugin={momentTimezone}
                      controls={["date"]}
                    />
                  </div>

                  <div className="airportContainer flex-grow-1 mb-0">
                    <label
                      htmlFor={`departureAirport[${index}]`}
                      className="base-label">
                      Depart <span className="required">*</span> (
                      {leg.departureTimeZone})
                    </label>

                    <AsyncSelect
                      key={`departure-${leg.departureAirport?.airportName}`}
                      name={`departureAirport[${index}]`}
                      className="departure-airport-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      loadOptions={loadAirportOptions}
                      placeholder="Search Airports"
                      defaultValue={{
                        label: leg.departureAirport.airportName,
                        value: leg.departureAirport.id,
                      }}
                      onChange={(selectedAirport) => {
                        handleAirportChange(index, selectedAirport, 0);

                        dispatch({
                          type: "changeLeg",
                          payload: {
                            legIndex: index,
                            field: "departureAirport",
                            value: {
                              airportName: selectedAirport?.label || "",
                              id: selectedAirport?.value || "",
                              code: selectedAirport?.code || "",
                              city: selectedAirport?.city || "",
                              localCode: selectedAirport?.localCode || "",
                            },
                          },
                        });
                      }}></AsyncSelect>
                  </div>
                  <div className="airportContainer flex-grow-1">
                    <label
                      htmlFor={`departureAirport[${index}]`}
                      className="base-label">
                      Arrive <span className="required">*</span> (
                      {leg.arrivalTimeZone})
                    </label>

                    <AsyncSelect
                      key={`arrival-${leg.arrivalAirport.airportName}`}
                      name={`arrivalAirport[${index}]`}
                      className="arrival-airport-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      loadOptions={loadAirportOptions}
                      placeholder="Search Airports"
                      defaultValue={{
                        label: leg.arrivalAirport.airportName,
                        value: leg.arrivalAirport.id,
                      }}
                      onChange={(selectedAirport) => {
                        handleAirportChange(index, selectedAirport, 1);

                        dispatch({
                          type: "changeLeg",
                          payload: {
                            legIndex: index,
                            field: "arrivalAirport",
                            value: {
                              airportName: selectedAirport?.label || "",
                              id: selectedAirport?.value || "",
                              code: selectedAirport?.code || "",
                              city: selectedAirport?.city || "",
                              localCode: selectedAirport?.localCode || "",
                            },
                          },
                        });
                      }}></AsyncSelect>
                  </div>
                </div>

                <div className="flex-row column-gap-1pct">
                  <TextInput
                    type={"number"}
                    name={`passengers[${index}]`}
                    label="PAX"
                    handleChange={(e) => {
                      dispatch({
                        type: "changeLeg",
                        payload: {
                          legIndex: index,
                          field: "passengers",
                          value: parseInt(e.target.value) || "",
                        },
                      });
                    }}
                    className="base-input"
                    containerClasses="flex-half mb-0"
                    value={leg.passengers}
                    required></TextInput>

                  <div className="form-group flex-grow-1">
                    <label htmlFor={`aircraft[${index}]`}>Aircraft</label>
                    <AsyncSelect
                      name={`aircraft[${index}]`}
                      className="aircraft-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      key={`aircraft[${index}]${leg?.aircraft?.name}`}
                      loadOptions={loadAircraftOptions}
                      placeholder="Search Aircraft"
                      defaultValue={{
                        label: leg?.aircraft?.name,
                        value: leg?.aircraft?.id,
                      }}
                      onChange={(selectedAircraft) => {
                        if (selectedAircraft) {
                          handleAircraftChange(index, selectedAircraft);
                          dispatch({
                            type: "changeLeg",
                            payload: {
                              legIndex: index,
                              field: "aircraft",
                              value: {
                                name: selectedAircraft.name,
                                seats: selectedAircraft.seats,
                                yom: selectedAircraft.yom,
                                id: selectedAircraft.id,
                              },
                            },
                          });
                        }

                        if (!selectedAircraft) {
                          dispatch({
                            type: "changeLeg",
                            payload: {
                              legIndex: index,
                              field: "aircraft",
                              value: {},
                            },
                          });
                        }
                      }}
                      isClearable></AsyncSelect>
                  </div>
                </div>

                {index >= 1 && (
                  <button
                    className="deleteLeg action-button base-gunmetal-button"
                    onClick={(e) => {
                      e.preventDefault();
                      return dispatch({ type: "deleteLeg", index: index });
                    }}
                    type="button">
                    Delete Leg {index + 1}
                  </button>
                )}
              </div>
            ))}
          <button
            className="action-button base-blue-button mt-0"
            onClick={handleAddLeg}
            type="button">
            Add Leg
          </button>
        </div>

        <div className="flight-details-container">
          <h3>Invoice Details</h3>

          {state?.invoiceDetails?.length > 0 &&
            state?.invoiceDetails?.map((detail, index) => (
              <div className="flight-details-block" key={index}>
                <div className="flex-row column-gap-1pct">
                  <div className="form-group flex-half">
                    <label htmlFor={`type[${index}]`}>
                      Type <span className="required">*</span>
                    </label>
                    <Select
                      className="type-select"
                      classNamePrefix="select"
                      options={invoiceTypesOptions}
                      placeholder="Type"
                      onChange={(selection) => {
                        dispatch({
                          type: "changeInvoiceDetailField",
                          payload: {
                            invoiceDetailIndex: index,
                            field: "typeLabel",
                            value: selection,
                          },
                        });
                      }}
                      key={`type${index}`}
                      value={detail.typeLabel}
                      defaultValue={detail.typeLabel}></Select>
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`description[${index}]`}>
                      Description <span className="required">*</span>
                    </label>
                    <textarea
                      id={`description${index}`}
                      placeholder="Description"
                      onChange={(e) => {
                        dispatch({
                          type: "changeInvoiceDetailField",
                          payload: {
                            invoiceDetailIndex: index,
                            field: "description",
                            value: e.target.value,
                          },
                        });
                      }}
                      key={`description[${index}]`}
                      value={
                        state.invoiceDetails[index].description
                      }></textarea>
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`amount[${index}]`}>
                      Amount <span className="required">*</span>
                    </label>
                    <NumberFormat
                      thousandSeparator={true}
                      prefix={"$"}
                      onValueChange={(event) => {
                        dispatch({
                          type: "changeInvoiceDetailField",
                          payload: {
                            invoiceDetailIndex: index,
                            field: "amount",
                            value: event.value,
                          },
                        });
                      }}
                      placeholder="Amount"
                      className="semibold-text mb-0"
                      decimalScale={2}
                      name={`amount[${index}]`}
                      value={state.invoiceDetails[index].amount}
                    />
                  </div>
                </div>
                {index >= 1 && (
                  <button
                    className="deleteLeg action-button base-gunmetal-button"
                    onClick={(e) => {
                      e.preventDefault();
                      return dispatch({
                        type: "deleteInvoiceDetail",
                        index: index,
                      });
                    }}
                    type="button">
                    Delete Detail {index + 1}
                  </button>
                )}
              </div>
            ))}
          <button
            className="action-button base-blue-button mt-0"
            onClick={handleAddDetail}
            type="button">
            Add Detail
          </button>
        </div>

        <div className="flight-details-container">
          <h3>Payment Details</h3>

          {state.paymentDetails?.length > 0 &&
            state.paymentDetails.map((detail, index) => (
              <div className="flight-details-block" key={index}>
                <div className="flex-row column-gap-1pct">
                  <div className="datepickerContainer mb-0 form-group flex-half">
                    <label
                      htmlFor={`departureDate[${index}]`}
                      className="base-label">
                      Date <span className="required">*</span>
                    </label>

                    <Datepicker
                      value={detail.date}
                      onChange={(event) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "date",
                            value: event.value,
                          },
                        });
                      }}
                      dataTimezone="utc"
                      displayTimezone="local"
                      timezonePlugin={momentTimezone}
                      controls={["date"]}
                    />
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`paymentDetailType[${index}]`}>
                      Type <span className="required">*</span>
                    </label>
                    <Select
                      className="type-select"
                      classNamePrefix="select"
                      options={paymentOptions}
                      placeholder="Type"
                      onChange={(selection) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "typeLabel",
                            value: selection,
                          },
                        });
                      }}
                      defaultValue={detail.typeLabel}
                      value={detail.typeLabel}
                      key={`paymentDetailType${index}`}></Select>
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`paymentDetailsNotes[${index}]`}>
                      Notes
                      {state.paymentDetails[index].type === "other" && (
                        <span className="required">*</span>
                      )}
                    </label>
                    <textarea
                      id={`paymentDetailsNotes${index}`}
                      placeholder="Notes"
                      value={detail.notes || ""}
                      onChange={(event) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "notes",
                            value: event.target.value,
                          },
                        });
                      }}
                      key={`paymentDetailsNotes[${index}]`}></textarea>
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`amount[${index}]`}>
                      Amount <span className="required">*</span>
                    </label>
                    <NumberFormat
                      value={detail.amount}
                      thousandSeparator={true}
                      prefix={"$"}
                      onValueChange={(event) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "amount",
                            value: event.value,
                          },
                        });
                      }}
                      placeholder="Amount"
                      className="semibold-text mb-0"
                      decimalScale={2}
                      name={`amount[${index}]`}
                    />
                  </div>
                </div>
                {index >= 1 && (
                  <button
                    className="deleteLeg action-button base-gunmetal-button"
                    onClick={(e) => {
                      e.preventDefault();
                      return dispatch({
                        type: "deletePaymentDetail",
                        index: index,
                      });
                    }}
                    type="button">
                    Delete Payment Detail {index + 1}
                  </button>
                )}
              </div>
            ))}

          <button
            className="action-button base-blue-button mt-0"
            onClick={handleAddPaymentDetail}
            type="button">
            Add Payment Detail
          </button>
        </div>
        <button
          className="action-button mt-0 float-right proeminent-button"
          onClick={handleInvoiceSubmit}
          type="submit">
          {invoiceId && invoiceId !== "new"
            ? `Update and Preview`
            : `Save and Preview`}
        </button>
      </form>

      <div className="invoice-totals-parent">
        <div className="flex invoice-totals">
          <h3 className="h3-total">Total Charges</h3>
          <NumberFormat
            value={getTotals().totalCharges}
            thousandSeparator={true}
            prefix={"$"}
            placeholder="Total Charges"
            className="semibold-text mb-0 ml-4"
            decimalScale={2}
            name="totalCharges"
            disabled
          />
        </div>
        <div className="flex invoice-totals">
          <h3 className="h3-total">Total Payments</h3>
          <NumberFormat
            value={getTotals().totalPayments}
            thousandSeparator={true}
            prefix={"$"}
            placeholder="Total Payments"
            className="semibold-text mb-0 ml-4"
            decimalScale={2}
            name="totalPayments"
            disabled
          />
        </div>
        <div className="flex invoice-totals">
          <h3 className="h3-total">Balance Due</h3>
          <NumberFormat
            value={getTotals().balanceDue}
            thousandSeparator={true}
            prefix={"$"}
            placeholder="Balance Due"
            className="semibold-text mb-0 ml-4"
            decimalScale={2}
            name="balanceDue"
            disabled
          />
        </div>
      </div>
    </Fragment>
  );
};

export default NewInvoiceContent;
