import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  {
    name: "Edit Prospect",
    sortable: false,
    cell: (row) => {
      return <Link to={`/prospects/${row._id}`}>Edit</Link>;
    },
    width: "100px",
  },

  //   {
  //     name: "Send Quote",
  //     sortable: false,
  //     cell: (row) => {
  //       let newQuote = <Link to={`/leads/${row._id}/quotes/new`}>New Quote</Link>;

  //       if (row.assigneeId) {
  //         return newQuote;
  //       }

  //       return "Assign Lead First";
  //     },
  //     width: "130px",
  //   },

  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "250px",
    cell: (row) => {
      if (row.orCreatedAt) {
        return moment(row.orCreatedAt).format("llll");
      }
      return moment(row.createdAt).format("llll");
    },
  },

  {
    name: "Assigned To",
    selector: "assigneeName",
    sortable: true,
    width: "150px",
  },

  // {
  //   name: "Phone Numbers",
  //   selector: "phoneNumbers",
  //   sortable: true,
  //   cell: (row) =>
  //     row?.phoneNumbers?.length
  //       ? row.phoneNumbers.map((phoneNumber) => phoneNumber.number).join(", ")
  //       : "-",
  //   width: "150px",
  // },

  //   {
  //     name: "Assigned At",
  //     selector: "assignedAt",
  //     sortable: true,
  //     width: "200px",
  //     cell: (row) => {
  //       if (row.assignedAt) {
  //         return moment(row.assignedAt).format("llll");
  //       }

  //       return "-";
  //     },
  //   },
  {
    name: "First Name",
    selector: "firstName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Last Name",
    selector: "lastName",
    sortable: true,
    width: "150px",
  },
  //   {
  //     name: "Phone",
  //     selector: "phone",
  //     sortable: true,
  //     width: "150px",
  //   },
  //   // {
  //   //   name: "Phone Country Code",
  //   //   selector: "phoneCountryCode",
  //   //   sortable: true,
  //   //   width: "220px",
  //   // },
  {
    name: "Email",
    selector: "emails",
    sortable: true,
    cell: (row) => row.emails.join(", "),
    width: "350px",
  },
  //   {
  //     name: "Comments",
  //     selector: "comments",
  //     sortable: true,
  //     width: "350px",
  //   },
  //   {
  //     name: "Trip Type",
  //     selector: "tripType",
  //     sortable: true,
  //     width: "150px",
  //     cell: (row) => (row.tripType === 1 ? "One Way" : "Round Trip"),
  //   },
  //   {
  //     name: "Departure Date",
  //     selector: "estimateData[0].departureDate",
  //     sortable: true,
  //     width: "200px",
  //   },
  //   {
  //     name: "Return Date",
  //     selector: "estimateData[0].returnDate",
  //     sortable: true,
  //     width: "200px",
  //     cell: (row) =>
  //       row.estimateData[0].returnDate ? row.estimateData[0].returnDate : "-",
  //   },

  //   {
  //     name: "Departure Airport",
  //     selector: "estimateData[0].departureAirportName",
  //     sortable: true,
  //     width: "350px",
  //   },

  //   {
  //     name: "Arrival Airport",
  //     selector: "estimateData[0].arrivalAirportName",
  //     sortable: true,
  //     width: "350px",
  //   },

  //   {
  //     name: "Passengers",
  //     selector: "estimateData[0].passengerCount",
  //     sortable: true,
  //     width: "150px",
  //   },
  //   {
  //     name: "Company",
  //     selector: "companyName",
  //     sortable: true,
  //     width: "150px",
  //   },
  {
    name: "Stage",
    selector: "stage",
    sortable: true,
    width: "130px",
    cell: (row) => {
      console.log(row);
      if (row.stage === "newLead") {
        return "New Prospect";
      } else if (row.stage === "inProgress") {
        return "In Progress";
      } else if (row.stage === "archived") {
        return "Archived";
      } else if (row.stage === "warmProspect") {
        return "Warm Prospect";
      } else if (row.stage === "unqualified") {
        return "Unqualified";
      } else if (row.stage === "inLimbo") {
        return "In Limbo";
      } else if (row.stage === "signedContract") {
        return "Signed Contract";
      } else if (row.stage === "tripComplete") {
        return "Trip Complete";
      } else if (row.stage === "closedNoReply") {
        return "Closed - No Reply";
      } else if (row.stage === "closedGhost") {
        return "Closed - Ghost";
      } else if (row.stage === "repeatClient") {
        return "Lost - Repeat Client";
      } else if (row.stage === "closedCompetitor") {
        return "Closed - Lost to Competitor";
      } else if (row.stage === "inProgressTemp") {
        return "Soft-Deleted";
      } else if (row.stage === "repeatClient") {
        return "Repeat Client";
      }
    },
  },
  //   {
  //     name: "Flight Matches",
  //     selector: "flightLegData",
  //     sortable: true,
  //     width: "250px",
  //     cell: (row) => {
  //       if (!row.flightLegData.length) {
  //         return "-";
  //       }

  //       let matches = row.flightLegData.map((leg) => {
  //         let linkText = "";
  //         let returnValue = "";
  //         if (leg.isPrimaryLeg) {
  //           linkText = "Leg 1 Matches";
  //         } else {
  //           linkText = "Leg 2 Matches";
  //         }

  //         console.log("LEG", leg);

  //         if (leg?.flightMatches?.length) {
  //           returnValue = (
  //             <>
  //               <Link
  //                 className="leadFlightLegMatches"
  //                 to={`/flight-matches/${leg._id}`}
  //               >{`${linkText}`}</Link>{" "}
  //               {row.flightLegData.length > 1 &&
  //               leg.isPrimaryLeg &&
  //               row.flightLegData[1].flightMatches.length
  //                 ? ", "
  //                 : ""}
  //             </>
  //           );
  //         } else {
  //           returnValue = false;
  //         }
  //         return returnValue;
  //       });

  //       if (matches.every((item) => item === false)) {
  //         return "No Matches";
  //       }

  //       return matches;
  //     },
  //   },

  //   {
  //     name: "Opportunity ID",
  //     selector: "orOpportunityId",
  //     sortable: true,
  //     width: "190px",
  //     cell: (row) => (
  //       <a
  //         href={`https://app1a.outreach.io/opportunities/${row.orOpportunityId}/activity`}
  //         target="_blank"
  //       >
  //         {row.orOpportunityId}
  //       </a>
  //     ),
  //   },
  {
    name: "Outreach Prospect ID",
    selector: "orProspectId",
    sortable: true,
    width: "250px",
    cell: (row) => (
      <a
        href={`https://app1a.outreach.io/prospects/${row.orProspectId}/activity`}
        target="_blank"
      >
        {row.orProspectId}
      </a>
    ),
  },
  //   {
  //     name: "Lead Origin",
  //     selector: "leadOrigin",
  //     sortable: true,
  //     width: "150px",
  //     cell: (row) => {
  //       let formatted = "";
  //       if (row.leadOrigin === "qfs") {
  //         formatted = "Flight Deck - QFS";
  //       } else if (row.leadOrigin === "liveAPI") {
  //         formatted = "Live API";
  //       } else if (row.leadOrigin === "phoneCall") {
  //         formatted = "Phone Call";
  //       } else if (row.leadOrigin === "flightDeckInternal") {
  //         formatted = "Flight Deck - Internal";
  //       } else if (row.leadOrigin === "flightDeckExternal") {
  //         formatted = "Flight Deck - External";
  //       } else {
  //         formatted = "Unset";
  //       }
  //       return formatted;
  //     },
  //   },
];

export default columns;
