import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment-timezone";

import formatAircraftSelection from "../../helpers/format-aircraft-selection";
import formatStage from "../../helpers/format-stage";
import currencyFormatter from "../../helpers/currencyFormatter";

import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Link } from "react-router-dom";

const getAircraftSelection = (data) => {
  if (data?.aircraftSelection?.length) {
    return data?.aircraftSelection
      .map((aircraft) => formatAircraftSelection(aircraft))
      .join(", ");
  }
  return "N/A";
};

const capitalizeFirstLetter = (string) => {
  if (!string) {
    return "";
  }
  return string[0].toUpperCase() + string.substring(1);
};

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(false);

  console.log("ROW", row);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className="mobile-lead-details-initial-cell">
          <span className="material-icons lead-info-icon">person</span>
          {row.firstName} {row.lastName}
          <br />
          <span className="material-icons lead-info-icon">flight</span>
          {row?.itineraryDetails?.departureAirportCode} -{" "}
          {row?.itineraryDetails?.arrivalAirportCode}
          <br />
          <span className="material-icons lead-info-icon">date_range</span>
          {row?.itineraryDetails?.departureDate}
          {row.tripType === 2
            ? ` - ${row?.itineraryDetails?.returnDate}`
            : ""}{" "}
          <br />
          <span className="material-icons lead-info-icon">schedule</span>
          {formatStage(row.stage)}
        </TableCell>
        {/* 
        <TableCell>{formatStage(row.stage)}</TableCell>

        <TableCell>{row.assigneeName || "-"}</TableCell>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell>{row.email}</TableCell>

        {row.companyName !== "" && <TableCell>{row.companyName}</TableCell>} */}

        {/* 
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
 */}
        {/* <TableCell scope="row">
          {row.isCurrentLeg ? "Current Leg" : `Leg ${row.indexText}`}
        </TableCell>
        <TableCell>{row.departureDate}</TableCell>
        <TableCell>{row.departureAirport.airportName}</TableCell>
        <TableCell>{row.arrivalAirport.airportName}</TableCell>
        <TableCell>{row.passengerCount}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Lead Metadata
              </Typography>

              <p>
                <strong>Created At: </strong>
                {moment(row.createdAt).format("MMM Do YYYY h:mm a")}
              </p>

              <p>
                <strong>Stage: </strong>
                {formatStage(row.stage)}
              </p>

              <p>
                <strong>Assignee: </strong>
                {row.assigneeName || "-"}
              </p>

              <p>
                <strong>Organization: </strong>
                {row.organizationName}
              </p>

              <p>
                <strong>Lead ID: </strong>
                {row._id}
              </p>

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Quote Details
              </Typography>

              {row.quotes.length > 0 &&
                row.quotes.map((quote) => {
                  return (
                    <p key={quote}>
                      <Link to={`/quotes/${quote}`}>View Quote: {quote}</Link>
                    </p>
                  );
                })}

              {!row.quotes.length && (
                <p>
                  <strong>No Quotes Sent.</strong>
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Trip Details
              </Typography>

              <p>
                <strong>Lead ID: </strong>
                {row._id}
              </p>

              <p>
                <strong>Trip Type: </strong>
                {row.tripType === 1 ? "One Way" : "Round Trip"}
              </p>

              <p>
                <strong>Departure Date: </strong>
                {row?.itineraryDetails?.departureDate}
              </p>

              {row.tripType === 2 && (
                <p>
                  <strong>Return Date: </strong>
                  {row?.itineraryDetails?.returnDate || "N/A"}
                </p>
              )}

              <p>
                <strong>Departure Airport: </strong>
                {row?.itineraryDetails?.departureAirportName}
              </p>

              <p>
                <strong>Arrival Airport: </strong>
                {row?.itineraryDetails?.arrivalAirportName}
              </p>

              <p>
                <strong>Departure Time: </strong>
                {row?.departureTime ? row?.departureTime : "N/A"}
              </p>

              {row.tripType === 2 && (
                <p>
                  <strong>Return Time: </strong>
                  {row?.returnTime ? row?.returnTime : "N/A"}
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Additional Details
              </Typography>

              <p>
                <strong>Passengers: </strong>
                {row?.itineraryDetails?.passengerCount}
              </p>

              <p>
                <strong>Aircraft Selection: </strong>
                {getAircraftSelection()}
              </p>
              <p>
                <strong>Baggage: </strong>
                {row?.baggage?.length ? row.baggage.join(", ") : "N/A"}
              </p>
              <p>
                <strong>Pets: </strong>
                {row.pets ? "Yes" : "No"}
              </p>
              <p>
                <strong>Infants: </strong>
                {row.infants ? "Yes" : "No"}
              </p>

              <p>
                <strong>Comments: </strong>
                {row.comments}
              </p>

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Estimate Details
              </Typography>

              {row?.tripOptions?.length > 0 &&
                row.tripOptions.map(
                  ({ aircraftName, lowSpread, highSpread }) => {
                    return (
                      <p key={aircraftName}>
                        <strong>{aircraftName}: </strong>
                        {currencyFormatter.format(lowSpread)} -{" "}
                        {currencyFormatter.format(highSpread)}
                      </p>
                    );
                  }
                )}

              {/* <p>

                <strong>Owner: </strong>
                {row?.originData?.assigneeName || "Not Yet Assigned"}
              </p>

              <p>
                <strong>Leg Origin: </strong>
                {row.isFromATC === true ? (
                  <Link to={`/atc/${row.atcId}`}>View ATC Record</Link>
                ) : (
                  <Link to={`/leads/${row.leadId}`}>View Lead</Link>
                )}
              </p>

              <p>
                <strong>
                  {row.isFromATC
                    ? `ATC Record Created At:`
                    : `Lead Created At: `}
                </strong>
                {moment(row?.originData?.createdAt)
                  .utc()
                  .format("ddd, MMM Do YYYY")}
              </p>

              <p>
                <strong>Stage: </strong>
                {formatStage(row.stage)}
              </p>

              {superAdminOrAd && (
                <Link
                  className="base-button evo-blue mt-20 d-block w-200 centered"
                  to={`/flight-matches/${row._id}`}
                >
                  View All Leg Data
                </Link>
              )} */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function SourcingRow(props) {
  const { superAdminOrAd } = props;

  let { row, leadData, dispatch, selectedRows, handleDelete, handleHide } =
    props;
  const [open, setOpen] = React.useState(false);

  if (!row) return null;

  let rowSelected = selectedRows.filter(
    (selectedRow) => row._id === selectedRow._id
  );

  rowSelected = rowSelected?.length ? true : false;

  console.log("SOURCING ROW", row);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className={`fm-l2-parent-table-row ${
          row?.hidden ? "sourcing-record-hidden" : "sourcing-record-active"
        } ${
          row?.latestQuoteId && row?.latestQuoteTimeStamp
            ? "record-quote-sent"
            : row?.latestQuoteId
            ? "record-quote-draft"
            : "record-quote-null"
        }`}
        key={row._id}
      >
        {/* <TableCell key="toggleOpenClose">
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell> */}

        <TableCell className="centered-important">
          <IconButton
            aria-label="expand row"
            size="small"
            className="sourcing-record-checkbox"
            disabled={row?.hidden}
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: "rowSelectionEvent",
                rowId: row._id,
                selected: !rowSelected,
              });
            }}
          >
            {rowSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlank />}
          </IconButton>
        </TableCell>

        <TableCell className="mobile-lead-details-initial-cell">
          <span className="material-icons lead-info-icon">person</span>
          {capitalizeFirstLetter(leadData.firstName)}{" "}
          {capitalizeFirstLetter(leadData.lastName)}
          <br />
          <span className="material-icons lead-info-icon">flight</span>
          {leadData.itineraryDetails.departureAirportCode} -{" "}
          {leadData.itineraryDetails.arrivalAirportCode}
          <br />
          {/* <span className="material-icons lead-info-icon">schedule</span>
          {formatStage(row.stage)} */}
          <span className="material-icons lead-info-icon">airlines</span>
          {row?.aircraft?.name}
          <br />
          <span className="material-icons lead-info-icon">payments</span>
          {row?.operatorPrice
            ? currencyFormatter.format(row.operatorPrice)
            : "-"}
          <br />
          <span className="material-icons lead-info-icon">credit_card</span>
          {row?.totalClientPayment
            ? currencyFormatter.format(row.totalClientPayment)
            : "-"}
        </TableCell>
      </TableRow>

      <TableRow
        className={`${
          row?.hidden ? "sourcing-record-hidden" : "sourcing-record-active"
        }`}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Sourcing Record Data
              </Typography>

              <p key={`operatorName`}>
                <strong>Operator: </strong>
                {row?.operator?.name || "-"}
              </p>

              <p key={`tailNumber`}>
                <strong>Tail #: </strong>
                {row?.tailNumber || "-"}
              </p>

              <p key={`yom`}>
                <strong>YOM: </strong>
                {row?.yomIsRange && row?.yom && row?.yomEnd
                  ? `${row.yom} - ${row.yomEnd}`
                  : row?.yom
                  ? row.yom
                  : row?.fullAircraftData?.yom
                  ? row?.fullAircraftData?.yom
                  : "-"}
              </p>

              <p key={`interiorRefurb`}>
                <strong>Interior Refurb: </strong>
                {row?.interiorRefurbYearIsRange &&
                row?.interiorRefurbYear &&
                row?.interiorRefurbYearEnd
                  ? `${row.interiorRefurbYear} - ${row.interiorRefurbYearEnd}`
                  : row?.interiorRefurbYear}
              </p>

              <p key={`commission`}>
                <strong>Commission: </strong>
                {row?.grossCommission
                  ? currencyFormatter.format(row.grossCommission)
                  : "-"}
              </p>

              <p key={`createdAt-${row.createdAt}`}>
                <strong>Created At: </strong>
                {moment(row.createdAt).format("MMM Do YYYY h:mm a")}
              </p>

              <p key={`leadCreatedAt-${row.createdAt}`}>
                <strong>Lead Created At: </strong>
                {moment(leadData.createdAt).format("MMM Do YYYY h:mm a")}
              </p>

              <p className="mt-20">
                <Link
                  to={{
                    pathname: `/sourcing/${row?._id}`,
                    state: {
                      lead: props.leadData,
                    },
                  }}
                  className="evo-blue base-button"
                >
                  View / Edit Sourcing
                </Link>
              </p>

              <p className="mt-30">
                <Link
                  to={`/leads/${row?.leadId}`}
                  className="evo-gunmetal base-button"
                >
                  View Lead
                </Link>
              </p>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Actions
              </Typography>

              {!row?.hidden && (
                <button
                  className={`base-button btn-danger mr-20`}
                  key={"handle-delete"}
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(row._id);
                  }}
                >
                  Delete
                </button>
              )}
              <button
                className={`base-button evo-gunmetal`}
                key={"handle-hide"}
                onClick={(e) => {
                  e.preventDefault();
                  handleHide(row._id);
                }}
              >
                {row?.hidden ? "Unhide" : "Hide"}
              </button>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Quote Status
              </Typography>

              {row?.latestQuoteId && row?.latestQuoteTimeStamp ? (
                <p>
                  <strong>Latest Quote Sent: </strong>
                  <Link to={`/quotes/${row?.latestQuoteId}`}>
                    {moment(row?.latestQuoteTimeStamp).format("lll")}
                  </Link>
                </p>
              ) : row?.latestQuoteId ? (
                <p>
                  <strong>Quote Drafted: </strong>
                  <Link to={`/quotes/${row?.latestQuoteId}`}>View Draft</Link>
                </p>
              ) : (
                "-"
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function OperatorCallLogRow(props) {
  const { superAdminOrAd } = props;
  let { row, dispatch, handleDelete } = props;
  const [open, setOpen] = React.useState(false);

  // row = row[0];

  if (!row) return null;

  // let rowSelected = selectedRows.filter(
  //   (selectedRow) => row._id === selectedRow._id
  // );

  // rowSelected = rowSelected?.length ? true : false;

  // let matchingOperator = null;

  // if (props?.leadData?.operatorData?.length) {
  //   matchingOperator = props?.leadData?.operatorData.filter(
  //     (item) => item._id === row.operator.id
  //   );

  //   if (matchingOperator?.length) {
  //     matchingOperator = matchingOperator[0];
  //   } else {
  //     matchingOperator = null;
  //   }
  // }

  // let rowSelected = false;

  // console.log("SOURCING ROW", row);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className={`fm-l2-parent-table-row operator-call-log-row `}
        key={row._id}
      >
        {/* <TableCell key="toggleOpenClose">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}

        {/* <TableCell key="editLink">
          <Link
            onClick={(e) => {
              if (row?.hidden) {
                e.preventDefault();
              }
              return;
            }}
            to={`/sourcing/${row?._id}`}
            className={row?.hidden ? "disabled-link" : ""}
          >
            Edit
          </Link>
        </TableCell> */}

        <TableCell key="DETAILS" className="mobile-unavailable-table-cell">
          <p>
            <strong>Operator: </strong>
            {row.operator.name}
          </p>

          <p>
            <strong>Timestamp: </strong>
            {moment(row.timestamp).format("MMM Do YYYY h:mm a")}
          </p>
          <p>
            <strong>Notes: </strong>
            {row?.notes || "-"}
          </p>
          <button
            className={`base-button btn-danger mr-20 delete-icon`}
            key={"handle-delete"}
            onClick={(e) => {
              e.preventDefault();
              handleDelete(row._id);
            }}
          >
            <span className="material-icons">close</span>
          </button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable(props) {
  // const { superAdminOrAd } = props;
  return (
    <>
      <TableContainer
        component={Paper}
        className="mt-20 accordion-table-with-details"
      >
        <Table aria-label="collapsible table">
          <TableHead className="fm-l2-parent-table-head">
            <TableRow className="fm-l2-parent-table-head-row">
              {/* <TableCell /> */}
              <TableCell id="leads-l2-mobile-header" colSpan={2}>
                LEAD INFO
              </TableCell>
              {/* <TableCell>STAGE</TableCell>
            <TableCell>ASSIGNEE</TableCell>
            <TableCell>LEAD NAME</TableCell>
            <TableCell>PHONE NUMBER</TableCell>
            <TableCell>EMAIL</TableCell>

            {props.rows[0].companyName !== "" && <TableCell>COMPANY</TableCell>} */}
            </TableRow>
          </TableHead>
          <TableBody className="fm-l2-parent-table-body">
            {props.rows.map((row) => (
              <Row key={row._id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props?.rows[0].sourcingRecords?.length > 0 && (
        <>
          <h2 className="mt-30 mb-0 text-center">SOURCING</h2>

          <TableContainer
            component={Paper}
            className="mt-30 accordion-table-with-details"
            key="sourcingTable"
          >
            <Table aria-label="collapsible table">
              <TableHead className="fm-l2-parent-table-head">
                <TableRow className="fm-l2-parent-table-head-row">
                  <TableCell />
                  <TableCell>RECORD DETAILS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="fm-l2-parent-table-body">
                {props?.rows[0]?.sourcingRecords?.length > 0 &&
                  props?.rows[0]?.sourcingRecords.map((row) => (
                    <SourcingRow
                      key={row?._id}
                      row={row}
                      handleDelete={props.handleSourcingRecordDelete}
                      handleHide={props.handleSourcingRecordHide}
                      dispatch={props.parentDispatch}
                      selectedRows={props.selectedRows}
                      leadData={props.rows[0]}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <div className="text-right mt-20">
        <Link
          key="Add Record"
          className="base-button evo-blue"
          to={{ pathname: `/sourcing/new`, state: { lead: props.lead } }}
        >
          Add Record
        </Link>

        {props?.selectedRows?.length > 0 && (
          <Link
            key="Create Quote"
            className="base-button evo-gunmetal ml-20"
            to={{
              pathname: `/leads/${props?.lead?._id}/quotes/new`,
              state: {
                sourcingRecords: props.selectedRows,
                leadL2Data: props.lead,
              },
            }}
          >
            Create Quote
          </Link>
        )}
      </div>

      <h2 className="mt-30 mb-0 text-center">UNAVAILABLE</h2>

      {props?.operatorCallLog?.length > 0 && (
        <TableContainer
          component={Paper}
          className="mt-30 accordion-table-with-details unavailable-operator-table"
          key="operatorCallLogTable"
        >
          <Table aria-label="collapsible table">
            <TableHead className="fm-l2-parent-table-head">
              <TableRow className="fm-l2-parent-table-head-row">
                <TableCell>DETAILS</TableCell>
                {/* <TableCell>TIMESTAMP</TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell>NOTES</TableCell>
                <TableCell>ACTIONS</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody className="fm-l2-parent-table-body">
              {props?.operatorCallLog?.length > 0 &&
                props?.operatorCallLog.map((row) => (
                  <OperatorCallLogRow
                    key={row?._id}
                    row={row}
                    leadData={props.lead}
                    handleDelete={props.handleOperatorCallLogDelete}
                    dispatch={props.parentDispatch}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div className="text-center mt-20">
        <button
          key="Add Operator"
          className="base-button evo-blue"
          onClick={props.handleAddOperatorButton}
        >
          Add Operator
        </button>
      </div>
    </>
  );
}
