import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";
import useLocalStorage from "../../helpers/useLocalStorage";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import moment from "moment-timezone";

import columns from "./trips-list-columns";
// import salesTeamColumns from "./flightLeg-columns-ae-sa";
import Search from "../search";
import DataTableServerPagination from "../data-table-server-pagination";

import { Eventcalendar, Popup, Button } from "@mobiscroll/react";

import PageHeaderRowSearch from "../shared/page-header-row-search";
import SwitchInput from "../reusable-modules/switch-input";
import TripBulkActions from "./trip-bulk-actions";
import Loader from "../loader";

const stageFilterOptions = [
  { label: "Active", value: "newTrip" },
  { label: "Pending", value: "pending" },
  { label: "Ended", value: "ended" },
  { label: "Canceled", value: "canceled" },
];

const colors = ['#0f9ed5', '#f1a983', '#4da72e', '#ffff00', '#d86dcd', '#ff0000'];
// Function to assign unique colors to events
const assignColorsToEvents = (events) => {
    const colorMap = {};
    events.forEach((event, index) => {
        let availableColors = [...colors];
        // Check overlapping events and exclude their colors
        events.forEach((e) => {
            const eStart = new Date(e.start);
            const eEnd = e.end ? new Date(e.end) : eStart;
            const eventStart = new Date(event.start);
            const eventEnd = event.end ? new Date(event.end) : eventStart;

            if (e.id !== event.id && (eStart <= eventEnd && eEnd >= eventStart)) {
                availableColors = availableColors.filter(color => color !== colorMap[e.id]);
            }
        });
        colorMap[event.id] = availableColors.length > 0 ? availableColors[0] : colors[index % colors.length];
        event.color = colorMap[event.id];
    });
    return events;
};


const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        trips: action?.payload?.data?.trips,
        loading: false,
        totalLength: action?.payload?.data?.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "tableDataChanged": {
      return {
        ...state,
        trips: action.value,
      };
    }

    case "setPopupTripInfo": {
      return {
        ...state,
        popupTripInfo: action.payload,
      };
    }

    case "calendarNavigation": {
      return {
        ...state,
        calendarDateRange: action.payload.dateRange,
      };
    }

    default:
      break;
  }
  return state;
};

const formatUSDAmount = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const TripsListContent = (props) => {
  const initialState = {
    trips: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    ownerFilterOptions: [],
    selectedOwner: null,
    query: "",
    stageFilters: ["newLead", "inProgress"],
    sortDirection: "desc",
    sortBy: "createdAt",
    ownerOptions: [],
    teamMembers: [],
    defaultStageFilters: { label: "New Lead", value: "newLead" },
    popupTripInfo: {},
  };

  if (props.prefilters && props.prefilters.length) {
    initialState.stageFilters = props.prefilters;
  }

  if (props.defaultStageFilters) {
    initialState.defaultStageFilters = props.defaultStageFilters;
  }

  if (props.qfsOnly) {
    initialState.defaultStageFilters = [];
    initialState.stageFilters = [];
  }

  const { user } = useAuth0();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [viewType, setViewType] = useLocalStorage("viewType", "table");

  const timerRef = useRef(null);

  const myView = { calendar: { labels: true } };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const navigateToTrip = (id) => {
    openInNewTab(`/trips/${id}`);
  };

  const handleEventHoverIn = useCallback(
    (args) => {
      const selectedTrip = args.event;
      const tripDetails = state.trips.find(
        (trip) => trip._id === selectedTrip.id
      );

      dispatch({
        type: "setPopupTripInfo",
        payload: {
          legId: selectedTrip.legId,
          id: tripDetails._id,
          owner: tripDetails.owner ? tripDetails.owner.name : "No Owner",
          title: selectedTrip.title,
          createdAt: tripDetails.createdAt,
          clientName: tripDetails.clientName || "No Client Name",
          legs: tripDetails.itineraryData[0].legs.map((leg) => {
            const etdTime = new Date(leg.etd).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            return {
              legInfo: `${new Date(leg.departureDate).toLocaleDateString()} ${etdTime} | ${leg.departureAirport.code} - ${leg.arrivalAirport.code}`,
              legId: leg._id
            };
          }),
          payments: tripDetails.clientPaymentHistory.map((payment) => `${formatUSDAmount(payment.amount)} - ${payment.paymentType || ""
              } - ${new Date(payment.paymentDate).toLocaleDateString()}`
          ),
          tripTag: tripDetails.tripTag ? "#" + tripDetails.tripTag : "",
          anchor: args.domEvent.target,
          isOpen: true,
        },
      });

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [state.trips]
  );

  const handleEventHoverOut = useCallback(() => {
    timerRef.current = setTimeout(() => {
      dispatch({
        type: "setPopupTripInfo",
        payload: {
          isOpen: false,
        },
      });
    }, 200);
  }, []);

  const handleEventClick = useCallback(
    (args) => {
      handleEventHoverIn(args);
    },
    [handleEventHoverIn]
  );

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    timerRef.current = setTimeout(() => {
      dispatch({
        type: "setPopupTripInfo",
        payload: {
          isOpen: false,
        },
      });
    }, 200);
  }, []);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    // console.log("props.showOnlyMine", props.showOnlyMine);

    console.log("TRIPS BEGINNING DOWNLOAD", dbQuery, stageFilters, sortBy);

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          stageFilters,
          sortBy,
          sortDirection,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      console.log("TRIPS DOWNLOADED", response.data);
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  const getDataForCalendar = async (dateRangeStart, dateRangeEnd) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
        .auth(token)
        .get(`/api/trips/calendarViewList`, {
              params: {
                dateRangeStart,
                dateRangeEnd,
              }
            }
        )
        .catch((error) => {
              console.error(error.response.data);
              Sentry.captureException(error);
            }
        );

    if (response) {
      console.log("TRIPS DOWNLOADED", response.data);
      if (response.data === []) {
        response.data = { trips: [], countTotal: 0 };
      }

      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  }

  const handleCalendarNavigation = async (event) => {
    const { month } = event;
    const dateRangeStart = moment(month).startOf('month').format();
    const dateRangeEnd = moment(month).endOf('month').format();
    dispatch({ type: "calendarNavigation", payload: { dateRange: { dateRangeStart, dateRangeEnd } } });
    void toast.promise(() => getDataForCalendar(dateRangeStart, dateRangeEnd), {
      loading: "Loading trips...",
      success: "Trips loaded successfully",
      error: "Failed to load trips",
    }, {
      position: "top-right",
      autoClose: 1500,
    }).then();
    }

  useEffect(() => {
    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection
    );
    document.title = `${
      props.pageTitle ? props.pageTitle : "Trips"
    } | Flight Deck by evoJets`;
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let rowIds = state.selectedRows.map((item) => item._id);
    let { data } = await ai.auth(token).delete("/api/airports/deleteMany", {
      data: { ids: rowIds },
    });

    if (data.deletedCount) {
      getData("", true);

      toast.success("Lead Deleted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      console.log("No Rows Deleted");
    }

    dispatch({
      type: "rowsDeleted",
    });
  };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();
    dispatch({ type: "search", query });
    getData(query, true, state.stageFilters, state.sortBy, state.sortDirection);
  };

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/users/roles/sales`, {});

    if (data.users.length >= 1) {
      console.log(data.users);
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      return results;
    } else {
      return [];
    }
  };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection
    );

    //keyField is required for server sort
  };

  const handleChangeViewType = (viewType) => {
    setViewType(viewType);

    if (viewType === "calendar") {
        toast.promise(()=> getDataForCalendar(moment().startOf('month').format(), moment().endOf('month').format()), {
            loading: "Loading trips...",
            success: "Trips loaded successfully",
            error: "Failed to load trips",
        }).then();
    } else {
      getData(state.query, true, state.stageFilters, state.sortBy, state.sortDirection);
    }
  };

  const handleOwnerSelection = async (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });
  };

  const handleOwnerInputChange = (newValue) => {
    dispatch({
      type: "fieldChanged",
      field: "ownerInputField",
      value: newValue,
    });
    return newValue;
  };

  const handleStageFilterChange = (selectedValues, action) => {
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "stageFilterChanged", payload: { values } });

    dispatch({
      type: "fieldChanged",
      field: "stageFilterInput",
      value: selectedValues,
    });

    getData(
      state.query,
      true,
      values,
      state.sortBy,
      state.sortDirection,
      state.ownerFilters,
      state.originFilters
    );
  };

  const onRowClicked = (row, event) => {
    // check if row id is inside selectedRows array
    let arr = [...state.selectedRows];
    arr = arr.filter((selectedRow) => selectedRow._id === row._id);

    if (arr.length) {
      // row is already in there...uncheck it?

      let newSelectedRowsArr = [...state.selectedRows];

      newSelectedRowsArr = newSelectedRowsArr.filter(
        (selectedRow) => selectedRow._id !== row._id
      );

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: newSelectedRowsArr,
      });

      let newData = [...state.trips];

      dispatch({ type: "tableDataChanged", value: newData });

      return;
    } else {
      //push row into selectedRows
      let currentSelectedRowsArray = [...state.selectedRows];
      currentSelectedRowsArray.push(row);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: currentSelectedRowsArray,
      });

      let newData = [...state.trips];
      dispatch({ type: "tableDataChanged", value: newData });

      return;
    }
  };

  const preSelectCriteria = (row) => {
    let selectedRows = [...state.selectedRows];

    selectedRows = selectedRows.filter(
      (selectedRow) => selectedRow._id === row._id
    );

    console.log("SELECTED ROWS", selectedRows.length);

    return selectedRows.length;
  };

  return (
    <Fragment>
      <PageHeaderRowSearch
        h1={`${props.pageTitle ? props.pageTitle : "Trips"}`}
        pretitle="Business"
        breadcrumbs={[{ link: "/trips", label: "Trips" }]}
        rightColumn={
          <div id="quote-actions-row">
            <Search
              onSearch={handleSearch}
              placeholder="Search Trips"
              query={state.query}
              className="trip-search"></Search>
            <SwitchInput
              leftLabel="Table"
              rightLabel="Calendar"
              checked={viewType === "calendar"}
              onChange={(e) =>
                handleChangeViewType(e.target.checked ? "calendar" : "table")
              }></SwitchInput>
          </div>
        }
      />

      {!state.loading && !isMobile && (
        <TripBulkActions
          selectedOwner={state.selectedOwner}
          superAdminOrAd={superAdminOrAd}
          ownerOptions={state.ownerOptions}
          handleOwnerSelection={handleOwnerSelection}
          stageFilterOptions={stageFilterOptions}
          handleStageFilterChange={handleStageFilterChange}
          defaultStageFilters={[]}
          stageFilterInput={state.stageFilterInput}
        />
      )}

      {!state.loading && (
        <div
          className={`view-transition ${
            viewType === "table" ? "view-visible" : ""
          }`}>
          <DataTableServerPagination
            onSelection={handleSelection}
            key={JSON.stringify(columns)}
            columns={columns}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            state={state}
            data={state.trips}
            defaultSortField={"createdAt"}
            defaultSortAsc={true}
            selectableRowSelected={preSelectCriteria}
            onRowClicked={onRowClicked}
            handleSort={handleSort}
            disableSelection={true}
            sortServer={true}
            persistTableHead={true}
          />
        </div>
      )}

      {state.loading && <Loader/>}
      {!state.loading && (
          <div
              className={`view-transition ${
                  viewType === "calendar" ? "view-visible" : ""
              }`}>
          <Eventcalendar
            clickToCreate={false}
            dragToCreate={false}
            dragToMove={false}
            dragToResize={false}
            eventDelete={false}
            popver={true}
            onPageChange={handleCalendarNavigation}
            showEventTooltip={false}
            onEventHoverIn={handleEventHoverIn}
            onEventHoverOut={handleEventHoverOut}
            data={assignColorsToEvents((state.trips || []).reduce((acc, trip) => {
              const {departureTimeZone} = trip.contractData[0].legs[0];
              const legs = trip.itineraryData[0]?.legs || [];
              acc.push(...legs.map((leg, index) => {
                const start = moment.tz(leg.departureDate, departureTimeZone).format();
                const end = leg.eta;
                return {
                  id: trip._id,
                  legId: leg._id,
                  start, end,
                  title: `${index + 1} of ${legs.length} | ${trip.clientName} | ${leg.departureAirport.code}-${leg.arrivalAirport.code} | ${trip.latestOFR.ofrTag}`
                }
              }));
              return acc;
            }, []))}
            view={myView}
            onEventClick={handleEventClick}
            theme="ios"
            themeVariant="light"
          />
          <Popup
            display="anchored"
            isOpen={state.popupTripInfo.isOpen}
            anchor={state.popupTripInfo.anchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={350}
            cssClass="md-tooltip">
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <div className="md-tooltip-header">
                <div>{state.popupTripInfo.title}</div>
                <Button
                  variant="outline"
                  color="primary"
                  className="md-tooltip-view-button"
                  onClick={() => navigateToTrip(state.popupTripInfo.id)}>
                  View
                </Button>
              </div>

              <div className="md-tooltip-info">
                <div className="md-tooltip-title">
                  Owner:{" "}
                  <span className="md-tooltip-text">
                    {state.popupTripInfo.owner}
                  </span>
                </div>
                <div className="md-tooltip-title">
                  Created at:{" "}
                  <span className="md-tooltip-text">
                    {new Date(state.popupTripInfo.createdAt).toLocaleString()}
                  </span>
                </div>
                <div className="md-tooltip-title">
                  Client:{" "}
                  <span className="md-tooltip-text">
                    {state.popupTripInfo.clientName}
                  </span>
                </div>
                <div className="md-tooltip-title">
                  Trip:{" "}
                  <span className="md-tooltip-text">
                    {state.popupTripInfo.tripTag}
                  </span>
                </div>
                <div className="md-tooltip-title">Legs:</div>
                <ul>
                  {state.popupTripInfo &&
                    state.popupTripInfo.legs &&
                      state.popupTripInfo.legs.map((leg, i) => (
                          <li className="md-tooltip-text" key={i}>
                            {
                              leg.legId === state.popupTripInfo.legId ? <b>{leg.legInfo}</b> : leg.legInfo
                            }
                          </li>
                      ))}
                </ul>
                {(state.popupTripInfo.payments?.length > 0)  && (
                    <>
                      <div className="md-tooltip-title">Payments:</div>
                      <ul>
                        { state.popupTripInfo.payments.map((pay, i) => (
                          <li className="md-tooltip-text" key={i}>
                            {pay}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
              </div>
            </div>
          </Popup>
        </div>
      )}
    </Fragment>
  );
};

export default TripsListContent;
