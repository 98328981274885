/* eslint-disable default-case */
import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import TextInput from "../text-input";
import { useParams, useHistory, useLocation } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { EditorState, ContentState, convertFromHTML } from "draft-js";

import { stateToHTML } from "draft-js-export-html";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// leg form import
import DatePicker from "react-date-picker";
import AsyncSelect from "react-select/async";
import TextAreaInput from "../textarea-input";

import timeFormatter from "../../helpers/timeFormatter";
import NumberFormat from "react-number-format";

import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import JSONPretty from "react-json-pretty";

import ProspectsAutocomplete from "../prospects/prospects-autocomplete";
import CreateProspectModal from "../prospects/create-prospect-modal";
import OperatorAutocomplete from "../opi/operator-autocomplete";

import CreateAuthorizedSignerModal from "../prospects/create-authorized-signer-modal";

import { Datepicker, momentTimezone } from "@mobiscroll/react";
import mobiscroll from "@mobiscroll/react";

import StateDisplay from "../reusable-modules/state-display";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import moment from "moment-timezone";

// mui imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

import ContractCancellationPolicies from "./contract-cancellation-policies";
import PhoneInput from "react-phone-number-input";

momentTimezone.moment = moment;

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "fieldChanged": {
      console.log("FIELD CHANGED", action.field, action.value);

      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "routingProcessed": {
      return {
        ...state,
        legs: action.value,
        routingProcessed: true,
      };
    }

    case "prospectAutoSelected": {
      let prospectOptions = [action.value, ...state.prospectOptions];

      return {
        ...state,
        selectedClient: action.value,
        prospectOptions,
      };
    }

    case "legAdded": {
      return {
        ...state,
        legs: [
          ...state.legs,
          {
            departureDate: "",
            departureAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            arrivalAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            passengers: "",
            aircraft: {
              name: "",
              seats: 8,
              yom: "",
              opi: "",
              id: "",
            },
          },
        ],
      };
    }
    case "legDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray.splice(action.index, 1);
      console.log(newLegsArray);
      return {
        ...state,
        legs: newLegsArray,
      };
    }
    case "legFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action?.payload?.legIndex][action?.payload?.field] =
        action?.payload?.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "flightTimesDownloaded": {
      let { index, values } = action;

      let newLegsArray = [...state.legs];

      // console.log("FLIGHT TIMES DOWNLOADED RUNNING", values);

      values.forEach((item) => {
        if (item.status === "rejected" && isNaN(item?.value?.legIndex)) {
          toast.error(
            `Flight times not available for Leg ${
              item?.value?.legIndex + 1
            }, please input manually.`,
            toastOptions
          );
          return;
        } else if (item.status === "rejected") {
          toast.error(
            `Flight times not available - please input manually.`,
            toastOptions
          );
          return;
        }

        if (
          !item?.value?.timeWithWindAdjustment ||
          !item?.value?.status ||
          isNaN(item?.value?.legIndex)
        ) {
          toast.error(
            "Flight times not available - please input manually.",
            toastOptions
          );
          return;
        }

        newLegsArray[item.value.legIndex].ete = timeFormatter(
          item.value.timeWithWindAdjustment
        );

        // toast.success(
        //   "Flight Times were populated successfully.",
        //   toastOptions
        // );
      });

      console.log("FLIGHT TIMES DOWNLOADED RUNNING", newLegsArray);

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    default:
      break;
  }
  return state;
};

// let etd = new Date();

// let eta = new Date();
// eta = eta.setHours(etd.getHours() + 2);

function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}

let contractNotesHTML = ` <h3>Message</h3>
<p id="contract-message-body">
  ETAs, ETDs, and ETEs are not guaranteed, are estimates, and may vary due to airport delays, weather, and/or other events outside evoJets’ control. Technical stops for fuel and customs clearance are not listed above, may apply, and are outside of evoJets' control.
</p>

<p class="italics">
  Cancellation fees include any costs incurred by evoJets in association with this trip.
</p>

<p>
  Expenses for catering, ground transportation, itinerary changes, and any other incidentals incurred after booking will be charged to the credit card held on file, and may not be processed for up to 60 days after the completion of this trip.
</p>`;

const contractNotesConverted = convertFromHTML(contractNotesHTML);

const defaultContractNotesEditorState = EditorState.createWithContent(
  ContentState.createFromBlockArray(
    contractNotesConverted.contentBlocks,
    contractNotesConverted.entityMap
  )
);

const NewContractContent = (props) => {
  const history = useHistory();
  const location = useLocation();

  let { tripId, contractId } = useParams();

  let initialState = {
    modalPhoneNumber: "",
    selectedClient: null,
    createProspectModalIsOpen: false,
    createAuthorizedSignerModalIsOpen: false,
    routingProcessed: false,

    prospectOptions: [],
    loading: true,
    useDifferentAuthorizedSigner: false,
    companyName: "",
    signerName: "",
    signerEmail: "",
    signerPhone: "",
    signerPhoneCountry: "US",

    //todo - update sample legs data

    authorizedSigners: [],

    paymentType: "",
    flightCharges: "",
    FET: "",
    ccFee: "",
    segmentFees: "",
    catering: "",
    groundTransportation: "",
    otherExpense: "",
    paymentApplied: "",
    discount: "",
    totalCharges: "",

    email: "",
    emailInputValue: "",
    subject: "",

    cc: [],
    ccInputValue: "",

    contractNotesEditor: defaultContractNotesEditorState,
    contractCancellationPolicies: [
      {
        type: 1,
        value: 0,
        amountOfTime: null,
        unitOfTime: null,
      },
    ],

    dusCancellationPolicy: {
      type: 1,
      value: 0,
      dus100: false,
    },

    legs: [
      // {
      //   departureDate: new Date(),
      //   departureAirport: {
      //     airportName: "KTEB/TEB, Teterboro Airport, Teterboro, US-NJ",
      //     id: "60885379b7e7fd0536f16be4",
      //     code: "KTEB",
      //     city: "New York/Teterboro, US-NJ",
      //     localCode: "TEB",
      //   },
      //   arrivalAirport: {
      //     airportName: "KVNY/VNY, Van Nuys Airport, Van Nuys, US-CA",
      //     id: "60885379b7e7fd0536f16c92",
      //     code: "KVNY",
      //     city: "Los Angeles/Van Nuys, US-CA",
      //     localCode: "VNY",
      //   },
      //   passengers: 5,
      //   etd: "",
      //   eta: "",
      //   ete: "2:00",
      //   aircraft: {
      //     name: "Phenom 300",
      //     seats: 8,
      //     yom: "2009 - Present",
      //     // opi: "",
      //     // id: "5fefb798507f6f13ce3e56e6",
      //   },
      // },
    ],
  };

  const leadDataFromLocation = location?.state?.lead;
  const sourcingRecords = location?.state?.sourcingRecords;

  const [state, dispatch] = useReducer(reducer, initialState);

  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

  const processSourcing = (sourcingRecords) => {
    sourcingRecords.forEach((item) => {
      if (item.operatorPrice) {
        dispatch({
          type: "fieldChanged",
          field: "flightCharges",
          value: item.operatorPrice,
        });
      }
      if (item?.fet?.amount && !isNaN(item?.fet?.amount)) {
        dispatch({
          type: "fieldChanged",
          field: "FET",
          value: item.fet.amount.toFixed(2),
        });
      }

      if (item?.segmentFees?.amount && !isNaN(item?.segmentFees?.amount)) {
        dispatch({
          type: "fieldChanged",
          field: "segmentFees",
          value: item.segmentFees.amount.toFixed(2),
        });
      }

      if (item?.ccFee) {
        dispatch({
          type: "fieldChanged",
          field: "ccFee",
          value: item.ccFee,
        });
      }

      if (item?.paymentType) {
        dispatch({
          type: "fieldChanged",
          field: "paymentType",
          value: item.paymentType,
        });
      }
    });
  };

  if (sourcingRecords?.length) {
    console.log({ sourcingRecords });
  }

  const prepareNewContract = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    //handle creation from trip
    if (tripId) {
      await getTripData(tripId);
    }

    if (leadDataFromLocation?._id) {
      let response = await ai
        .auth(token)
        .get(`/api/leads/${leadDataFromLocation?._id}/withQuoteDataAndTZ`)
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });

      if (response && response.data) {
        console.log("LEAD DATA", response.data);
        dispatch({
          type: "fieldChanged",
          field: "leadData",
          value: response.data,
        });

        dispatch({ type: "fieldChanged", field: "loading", value: false });

        if (response.data?.quoteData?.length) {
          // parseQuoteData(response.data.quoteData);
        }

        parseClientData(response.data?.clientData);

        processRouting(response.data, sourcingRecords);

        if (response.data.tripIds?.length) {
          //todo
          console.log("LEAD HAS TRIP");
        }
      }
    }
  };

  const processSubject = () => {
    let estimate = state?.leadData?.estimateData;

    if (!estimate?.length) {
      return;
    }

    estimate = estimate[0];

    let departDate = moment(estimate.departureDateString).format("DD MMM");
    let returnDate = null;

    let subjectText = `${state?.leadData?.firstName} ${state?.leadData?.lastName} | ${departDate} | ${estimate.departureAirportCode}-${estimate.arrivalAirportCode}`;

    if (state?.leadData?.tripType === 2) {
      returnDate = moment(estimate.returnDateString).format("DD MMM");

      subjectText = `${state?.leadData?.firstName} ${state?.leadData?.lastName} | ${departDate} - ${returnDate} | ${estimate.departureAirportCode}-${estimate.arrivalAirportCode}`;
    }

    dispatch({
      type: "fieldChanged",
      field: "subject",
      value: subjectText,
    });
  };

  function convertETtoUTC(dateString) {
    // Parse the date string as being in UTC
    let date = moment.utc(dateString);

    // Consider this date as Eastern Time without changing the time values
    date.tz("America/New_York", true);

    // Convert it back to UTC
    let dateInUTC = date.clone().tz("UTC");

    // Format the date as an ISO string
    return dateInUTC.format();
  }

  const processRouting = async (leadData, sourcingRecords) => {
    let estimate = null;
    if (leadData?.estimateData?.length) {
      estimate = { ...leadData?.estimateData[0] };
    }

    if (!estimate) {
      return;
    }

    let departureAirportTZ = null;
    let arrivalAirportTZ = null;
    if (estimate.departureAirportTZ?.length) {
      departureAirportTZ = estimate.departureAirportTZ[0];
    }

    if (estimate.arrivalAirportTZ?.length) {
      arrivalAirportTZ = estimate.arrivalAirportTZ[0];
    }

    console.log("DEPARTURE AIRPORT TZ", estimate?.departureAirportTZ);

    let formattedRoutingLegOne = {
      departureDate: convertETtoUTC(estimate.departureDateString),
      departureAirport: {
        airportName: estimate.departureAirportName,
        id: estimate.departureAirportId,
        code: estimate.departureAirportCode,
        city: estimate.departureAirportCity,
        localCode: estimate.departureAirportLocalCode,
      },
      arrivalAirport: {
        airportName: estimate.arrivalAirportName,
        id: estimate.arrivalAirportId,
        code: estimate.arrivalAirportCode,
        city: estimate.arrivalAirportCity,
        localCode: estimate.arrivalAirportLocalCode,
      },
      passengers: estimate.passengerCount,
      etd: "",
      eta: "",
      ete: "",
      departureTimeZone: departureAirportTZ,
      arrivalTimeZone: arrivalAirportTZ,
    };

    let formattedRoutingLegTwo = null;

    if (leadData?.tripType === 2) {
      formattedRoutingLegTwo = {
        departureDate: convertETtoUTC(estimate.returnDateString),
        arrivalAirport: {
          airportName: estimate.departureAirportName,
          id: estimate.departureAirportId,
          code: estimate.departureAirportCode,
          city: estimate.departureAirportCity,
          localCode: estimate.departureAirportLocalCode,
        },
        departureAirport: {
          airportName: estimate.arrivalAirportName,
          id: estimate.arrivalAirportId,
          code: estimate.arrivalAirportCode,
          city: estimate.arrivalAirportCity,
          localCode: estimate.arrivalAirportLocalCode,
        },
        passengers: estimate.passengerCount,
        etd: "",
        eta: "",
        ete: "",
        departureTimeZone: arrivalAirportTZ,
        arrivalTimeZone: departureAirportTZ,
      };
    }

    if (sourcingRecords) {
      //handle roundtrip
      //todo

      let legOne = sourcingRecords[0];
      formattedRoutingLegOne.aircraft = {
        name: legOne?.aircraft?.name,
        seats: legOne?.aircraft?.seats,
        yom: legOne.yom,
        id: legOne?.aircraft?.id,
      };

      formattedRoutingLegOne.selectedOperator = {
        name: legOne?.operator?.name,
        id: legOne?.operator?.id,
        value: legOne?.operator?.id,
        label: legOne?.operator?.name,
      };

      if (formattedRoutingLegTwo) {
        formattedRoutingLegTwo.aircraft = {
          name: legOne?.aircraft?.name,
          seats: legOne?.aircraft?.seats,
          yom: legOne.yom,
          id: legOne?.aircraft?.id,
        };

        //TODO - handle round trip routing sourcing record if 2nd leg record is in place

        formattedRoutingLegTwo.selectedOperator = {
          name: legOne?.operator?.name,
          id: legOne?.operator?.id,
          value: legOne?.operator?.id,
          label: legOne?.operator?.name,
        };
      }
    }

    let formattedRouting = [formattedRoutingLegOne];

    if (formattedRoutingLegTwo) {
      formattedRouting.push(formattedRoutingLegTwo);
    }

    // console.log({ formattedRoutingLegOne });

    //todo - calculate ETE

    dispatch({
      type: "routingProcessed",
      value: formattedRouting,
    });
  };

  useEffect(() => {
    handleFlightTimeCalculation();
  }, [state.routingProcessed]);

  const parseAuthorizedSigners = (clientData) => {
    if (!clientData?.additionalUsers?.length) {
      return false;
    }

    let authorizedSigners = clientData.additionalUsers.flatMap((item) => {
      if (!item?.isAuthorizedSigner) {
        return [];
      }
      let label = `${item.firstName} ${item.lastName} - ${item.relationship}`;
      let value = item?._id;
      return {
        ...item,
        label,
        value,
      };
    });

    dispatch({
      type: "fieldChanged",
      field: "authorizedSigners",
      value: authorizedSigners,
    });
  };

  const parseClientData = (clientData) => {
    if (!clientData) {
      //null
      return false;
    }

    if (!clientData?._id || !clientData?.firstName || !clientData?.lastName) {
      //no contact info
      return false;
    }

    if (clientData?.isBusiness && !clientData?.companyName) {
      //client is business but missing company name
      return false;
    }

    let label = `${clientData.firstName} ${clientData?.lastName}`;
    let value = clientData?._id;

    if (clientData?.isBusiness) {
      label = clientData.companyName;

      dispatch({
        type: "fieldChanged",
        field: "companyName",
        value: clientData.companyName,
      });
    }

    if (clientData?.firstName && clientData?.lastName) {
      dispatch({
        type: "fieldChanged",
        field: "signerName",
        value: `${clientData.firstName} ${clientData?.lastName}`,
      });
    }

    if (clientData?.phoneNumbers?.length) {
      if (
        clientData?.phoneNumbers[0]?.number &&
        clientData?.phoneNumbers[0]?.countryCode
      ) {
        dispatch({
          type: "fieldChanged",
          field: "signerPhone",
          value: clientData?.phoneNumbers[0]?.number,
        });
        dispatch({
          type: "fieldChanged",
          field: "signerPhoneCountry",
          value: clientData?.phoneNumbers[0]?.countryCode,
        });
      }
    } else if (clientData?.workPhones?.length) {
      dispatch({
        type: "fieldChanged",
        field: "signerPhone",
        value: clientData?.workPhones[0],
      });
    } else if (clientData?.mobilePhones?.length) {
      dispatch({
        type: "fieldChanged",
        field: "signerPhone",
        value: clientData?.mobilePhones[0],
      });
    }

    parseAuthorizedSigners(clientData);

    if (clientData?.emails?.length) {
      dispatch({
        type: "fieldChanged",
        field: "email",
        value: clientData.emails[0],
      });

      dispatch({
        type: "fieldChanged",
        field: "signerEmail",
        value: clientData.emails[0],
      });
    }

    dispatch({
      type: "fieldChanged",
      field: "selectedClient",
      value: {
        label,
        value,
        ...clientData,
      },
    });
  };

  const parseQuoteData = (quoteData) => {
    let sorted = quoteData.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    let latestQuote = sorted[0];

    console.log("SORTED", sorted);

    console.log(
      "LOCAL",
      convertUTCToLocalDate(latestQuote.legs[0].departureDate)
    );

    dispatch({
      type: "legFieldChanged",
      payload: {
        legIndex: 0,
        field: "departureDate",
        value: convertUTCToLocalDate(latestQuote.legs[0].departureDate),
      },
    });

    return sorted;
  };

  const processContractAndTripData = async (contractAndTripData) => {
    console.log("PROCESS CONTRACT AND TRIP DATA");

    let legs = contractAndTripData.legs.map((leg) => {
      let selectedOperator = null;
      if (leg?.operator?.id) {
        selectedOperator = {
          _id: leg.operator.id,
          name: leg.operator.name,
          value: leg.operator.id,
          label: leg.operator.name,
        };

        console.log("OPERATOR LABEL");
      }

      let departureTimeZone = null;
      let arrivalTimeZone = null;
      let departureDate = moment(leg.departureDate);

      console.log(departureDate);

      departureDate.isValid()
        ? (departureDate = departureDate.toDate())
        : (departureDate = "");

      // if (contractAndTripData?.departureAirports?.length) {
      //   let matchingDepartureAirport =
      //     contractAndTripData?.departureAirports.find(
      //       (item) => item._id === leg.departureAirport?.id
      //     );
      //   //get lat and lon

      //   console.log("MATCHING DEPART AIRPORT", matchingDepartureAirport);

      //   // get timezone
      //   // departureTimeZone = find(
      //   //   matchingDepartureAirport.lat,
      //   //   matchingDepartureAirport.lon
      //   // );
      //   console.log("MATCHING DEPART TZ", departureTimeZone);

      //   if (departureTimeZone?.length) {
      //     departureTimeZone = departureTimeZone[0];
      //   }

      //   // pass to datepicker component for etd
      // }

      // if (contractAndTripData?.departureAirports?.length) {
      //   let matchingArrivalAirport = contractAndTripData?.arrivalAirports.find(
      //     (item) => item._id === leg.arrivalAirport?.id
      //   );

      //   // arrivalTimeZone = find(
      //   //   matchingArrivalAirport.lat,
      //   //   matchingArrivalAirport.lon
      //   // );

      //   console.log("ARRIVAL TIME ZONE", arrivalTimeZone);

      //   // get timezone

      //   if (arrivalTimeZone?.length) {
      //     arrivalTimeZone = arrivalTimeZone[0];
      //   }

      //   // pass to datepicker component for eta
      // }

      return {
        departureDate,
        departureAirport: leg.departureAirport,
        arrivalAirport: leg.arrivalAirport,
        passengers: leg.passengers,
        etd: leg.etd ?? "",
        eta: leg.eta ?? "",
        ete: leg.ete ?? "",
        selectedOperator,
        aircraft: leg.aircraft,
        departureTimeZone: leg?.departureTimeZone,
        arrivalTimeZone: leg?.arrivalTimeZone,
      };
    });

    if (contractAndTripData?.contractNotes) {
      let contractNotes = convertFromHTML(contractAndTripData.contractNotes);

      let contractNotesEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          contractNotes.contentBlocks,
          contractNotes.entityMap
        )
      );

      dispatch({
        type: "fieldChanged",
        field: "contractNotesEditor",
        value: contractNotesEditorState,
      });
    }

    //pricing
    Object.keys(contractAndTripData.pricing).forEach((item) => {
      let itemValue = contractAndTripData.pricing[item];

      if (typeof itemValue === "number" && itemValue !== 0) {
        itemValue = itemValue.toFixed(2);
      }

      if (itemValue === 0) {
        itemValue = "";
      }

      dispatch({
        type: "fieldChanged",
        field: item,
        value: itemValue,
      });
    });

    //recipient fields
    Object.keys(contractAndTripData.recipientFields).forEach((item) => {
      let itemValue = contractAndTripData.recipientFields[item];

      if (item === "cc" && itemValue?.length) {
        itemValue = itemValue.map((item) => {
          return { label: item, value: item };
        });
      }

      dispatch({
        type: "fieldChanged",
        field: item,
        value: itemValue,
      });
    });

    if (contractAndTripData.clientData) {
      let i = contractAndTripData.clientData;
      let label = `${contractAndTripData.clientData?.firstAndLastName} - ${contractAndTripData.clientData?.emails[0]}`;

      if (!i?.firstAndLastName) {
        if (!i.firstName) {
          i.firstName = "";
        }

        if (!i.lastName) {
          i.lastName = "";
        }

        label = `${i.firstName} ${i.lastName} - ${i.emails[0]}`;
      }

      if (i.companyName) {
        label = `${label} - ${i.companyName}`;
      }

      if (i.assigneeName && i.assigneeId) {
        label = `${label} - ${i.assigneeName}`;
      }

      contractAndTripData.clientData.label = label;
    }

    if (contractAndTripData?.dusCancellationPolicy) {
      console.log(
        "DUS CANCELLATION POLICY",
        contractAndTripData?.dusCancellationPolicy
      );
      dispatch({
        type: "fieldChanged",
        field: "dusCancellationPolicy",
        value: contractAndTripData?.dusCancellationPolicy,
      });
    }

    if (contractAndTripData?.contractCancellationPolicies) {
      console.log(
        "contractCancellationPolicies",
        contractAndTripData?.contractCancellationPolicies
      );

      dispatch({
        type: "fieldChanged",
        field: "contractCancellationPolicies",
        value: contractAndTripData?.contractCancellationPolicies,
      });
    }

    dispatch({
      type: "fieldChanged",
      field: "companyName",
      value: contractAndTripData.companyName || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerName",
      value: contractAndTripData.signerName || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerPhone",
      value: contractAndTripData.signerPhone || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerPhoneCountry",
      value: contractAndTripData.signerPhoneCountry || "US",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerEmail",
      value: contractAndTripData.signerEmail || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "selectedClient",
      value: contractAndTripData.clientData,
    });
    dispatch({
      type: "fieldChanged",
      field: "useDifferentAuthorizedSigner",
      value: contractAndTripData.useDifferentAuthorizedSigner,
    });

    dispatch({
      type: "fieldChanged",
      field: "leadData",
      value: contractAndTripData.leadData,
    });

    dispatch({ type: "fieldChanged", field: "legs", value: legs });

    dispatch({ type: "fieldChanged", field: "loading", value: false });
  };

  const processTripData = async (tripData) => {
    console.log("PROCESS TRIP DATA");

    if (!tripData?.contractData?.length) {
      return false;
    }

    let contractAndTripData = tripData.contractData.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    contractAndTripData = contractAndTripData[0];

    let legs = contractAndTripData.legs.map((leg) => {
      let selectedOperator = null;
      if (leg?.operator?.id) {
        selectedOperator = {
          _id: leg.operator.id,
          name: leg.operator.name,
          value: leg.operator.id,
          label: leg.operator.name,
        };

        console.log("OPERATOR LABEL");
      }

      let departureTimeZone = null;
      let arrivalTimeZone = null;
      let departureDate = moment(leg.departureDate);

      console.log(departureDate);

      departureDate.isValid()
        ? (departureDate = departureDate.toDate())
        : (departureDate = "");

      if (contractAndTripData?.departureAirports?.length) {
        let matchingDepartureAirport =
          contractAndTripData?.departureAirports.find(
            (item) => item._id === leg.departureAirport?.id
          );
        //get lat and lon

        if (matchingDepartureAirport) {
          // get timezone
          // departureTimeZone = find(
          //   matchingDepartureAirport.lat,
          //   matchingDepartureAirport.lon
          // );
          console.log("MATCHING DEPART TZ", departureTimeZone);

          if (departureTimeZone?.length) {
            departureTimeZone = departureTimeZone[0];
          }
        }

        // pass to datepicker component for etd
      }

      if (contractAndTripData?.departureAirports?.length) {
        let matchingArrivalAirport = contractAndTripData?.arrivalAirports.find(
          (item) => item._id === leg.arrivalAirport?.id
        );

        // arrivalTimeZone = find(
        //   matchingArrivalAirport.lat,
        //   matchingArrivalAirport.lon
        // );

        // console.log("ARRIVAL TIME ZONE", arrivalTimeZone);

        // // get timezone

        // if (arrivalTimeZone?.length) {
        //   arrivalTimeZone = arrivalTimeZone[0];
        // }

        // pass to datepicker component for eta
      }

      return {
        departureDate,
        departureAirport: leg.departureAirport,
        arrivalAirport: leg.arrivalAirport,
        passengers: leg.passengers,
        etd: leg.etd ?? "",
        eta: leg.eta ?? "",
        ete: leg.ete ?? "",
        selectedOperator,
        aircraft: leg.aircraft,
        departureTimeZone,
        arrivalTimeZone,
      };
    });

    if (contractAndTripData?.contractNotes) {
      let contractNotes = convertFromHTML(contractAndTripData.contractNotes);

      let contractNotesEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          contractNotes.contentBlocks,
          contractNotes.entityMap
        )
      );

      dispatch({
        type: "fieldChanged",
        field: "contractNotesEditor",
        value: contractNotesEditorState,
      });
    }

    //pricing
    Object.keys(contractAndTripData.pricing).forEach((item) => {
      let itemValue = contractAndTripData.pricing[item];

      if (typeof itemValue === "number" && itemValue !== 0) {
        itemValue = itemValue.toFixed(2);
      }

      if (itemValue === 0) {
        itemValue = "";
      }

      dispatch({
        type: "fieldChanged",
        field: item,
        value: itemValue,
      });
    });

    //recipient fields
    Object.keys(contractAndTripData.recipientFields).forEach((item) => {
      let itemValue = contractAndTripData.recipientFields[item];

      if (item === "cc" && itemValue?.length) {
        itemValue = itemValue.map((item) => {
          return { label: item, value: item };
        });
      }

      dispatch({
        type: "fieldChanged",
        field: item,
        value: itemValue,
      });
    });

    if (contractAndTripData.clientData) {
      let i = contractAndTripData.clientData;
      let label = `${contractAndTripData.clientData?.firstAndLastName} - ${contractAndTripData.clientData?.emails[0]}`;

      if (!i?.firstAndLastName) {
        if (!i.firstName) {
          i.firstName = "";
        }

        if (!i.lastName) {
          i.lastName = "";
        }

        label = `${i.firstName} ${i.lastName} - ${i.emails[0]}`;
      }

      if (i.companyName) {
        label = `${label} - ${i.companyName}`;
      }

      if (i.assigneeName && i.assigneeId) {
        label = `${label} - ${i.assigneeName}`;
      }

      contractAndTripData.clientData.label = label;
    }

    if (contractAndTripData?.data?.dusCancellationPolicy) {
      dispatch({
        type: "fieldChanged",
        field: "dusCancellationPolicy",
        value: contractAndTripData?.data?.dusCancellationPolicy,
      });
    }

    if (contractAndTripData?.data?.contractCancellationPolicies) {
      dispatch({
        type: "fieldChanged",
        field: "contractCancellationPolicies",
        value: contractAndTripData?.data?.contractCancellationPolicies,
      });
    }

    dispatch({
      type: "fieldChanged",
      field: "selectedClient",
      value: contractAndTripData.clientData,
    });
    dispatch({
      type: "fieldChanged",
      field: "useDifferentAuthorizedSigner",
      value: contractAndTripData.useDifferentAuthorizedSigner,
    });

    dispatch({
      type: "fieldChanged",
      field: "companyName",
      value: contractAndTripData?.companyName || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerName",
      value: contractAndTripData.signerName || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerPhone",
      value: contractAndTripData.signerPhone || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerPhoneCountry",
      value: contractAndTripData.phoneCountry || "US",
    });

    dispatch({
      type: "fieldChanged",
      field: "signerEmail",
      value: contractAndTripData.signerEmail || "",
    });

    dispatch({
      type: "fieldChanged",
      field: "leadData",
      value: contractAndTripData.leadData,
    });

    dispatch({ type: "fieldChanged", field: "legs", value: legs });
    // dispatch({ type: "fieldChanged", field: "routingProcessed", value: true });
    dispatch({ type: "fieldChanged", field: "loading", value: false });

    // console.log({ legs });
  };

  const getTripData = async (tripId) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("GET TRIP DATA");

    let { data: tripData } = await ai.auth(token).get(`/api/trips/${tripId}`);

    console.log({ tripData });

    processTripData(tripData);

    // processContractAndTripData(contractAndTripData);

    // dispatch({
    //   type: "tripDataDownloaded",
    //   payload: {
    //     tripData,
    //   },
    // });
  };

  const getContractAndTripData = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("GET CONTRACT AND TRIP DATA");

    let { data: contractAndTripData } = await ai
      .auth(token)
      .get(`/api/trips/${tripId}/contracts/${contractId}`);

    // await getLeadData();

    console.log({ contractAndTripData });

    processContractAndTripData(contractAndTripData);

    // dispatch({
    //   type: "tripDataDownloaded",
    //   payload: {
    //     tripData,
    //   },
    // });
  };

  useEffect(() => {
    if (!contractId || contractId === "new") {
      prepareNewContract();
    } else {
      getContractAndTripData();
    }

    document.title = "Contract Builder | Flight Deck";
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleTextInputChange = (e) => {
    return dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleEmailChange = (newValue, actionMeta) => {
    // // console.group("Email Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "email",
      value: newValue || [],
    });
    // console.groupEnd();
  };

  const handleCcChange = (newValue, actionMeta) => {
    // console.group("CC Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "cc",
      value: newValue || [],
    });
    // console.groupEnd();
  };

  const handleBccChange = (newValue, actionMeta) => {
    // console.group("BCC Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "bcc",
      value: newValue || [],
    });
    // console.groupEnd();
  };

  const handleEmailInputChange = (inputValue, action) => {
    // console.group("Email Input Changed");
    // console.log(inputValue);
    // console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "emailInputValue",
      value: inputValue,
    });

    if (
      action.action === "menu-close" &&
      validateEmail(state.emailInputValue)
    ) {
      dispatch({
        type: "fieldChanged",
        field: "email",
        value: [...state.email, createOption(state.emailInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "emailInputValue",
        value: "",
      });
    }

    // console.groupEnd();
  };

  const handleCcInputChange = (inputValue, action) => {
    console.group("CC Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "ccInputValue",
      value: inputValue,
    });

    if (action.action === "menu-close" && validateEmail(state.ccInputValue)) {
      dispatch({
        type: "fieldChanged",
        field: "cc",
        value: [...state.cc, createOption(state.ccInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "ccInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const handleBccInputChange = (inputValue, action) => {
    console.group("BCC Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "bccInputValue",
      value: inputValue,
    });

    if (action.action === "menu-close" && validateEmail(state.bccInputValue)) {
      dispatch({
        type: "fieldChanged",
        field: "bcc",
        value: [...state.bcc, createOption(state.bccInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "bccInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const emailComponents = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleEmailKeyDown = (event) => {
    const { emailInputValue, email } = state;
    if (!emailInputValue || validateEmail(emailInputValue)) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Email Value Added");
        console.log(email);
        console.groupEnd();

        if (state.email) {
          dispatch({
            type: "fieldChanged",
            field: "email",
            value: [...email, createOption(emailInputValue)],
          });
        } else {
          dispatch({
            type: "fieldChanged",
            field: "email",
            value: [createOption(emailInputValue)],
          });
        }

        dispatch({
          type: "fieldChanged",
          field: "emailInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleCcKeyDown = (event) => {
    const { ccInputValue, cc } = state;
    if (!ccInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("CC Value Added");
        console.log(cc);
        console.groupEnd();
        dispatch({
          type: "fieldChanged",
          field: "cc",
          value: [...cc, createOption(ccInputValue)],
        });

        dispatch({
          type: "fieldChanged",
          field: "ccInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleBccKeyDown = (event) => {
    const { bccInputValue, bcc } = state;
    if (!bccInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Bcc Value Added");
        console.log(bcc);
        console.groupEnd();
        dispatch({
          type: "fieldChanged",
          field: "bcc",
          value: [...bcc, createOption(bccInputValue)],
        });

        dispatch({
          type: "fieldChanged",
          field: "bccInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleAddLeg = (event) => {
    event.preventDefault();

    dispatch({ type: "legAdded" });
  };

  const handleAddOption = (event) => {
    event.preventDefault();

    dispatch({ type: "optionAdded" });
  };

  const loadAirportOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&includeTZ=true`);
    console.log(data);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        city: i.city,
        localCode: i.localCode,
        lat: i.lat,
        lon: i.lon,
        tz: i.tz,
      }));
    }

    return options;
  };
  const loadAircraftOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/aircrafts/search?q=${inputValue}`, {
        params: {
          query: inputValue,
          page: 1,
          countPerPage: 50,
        },
      });

    if (data.aircraft.length >= 1) {
      console.log(data.aircraft);
      return data.aircraft.map((aircraft) => {
        return {
          value: aircraft._id,
          label: aircraft.name,
          id: aircraft._id,
          yom: aircraft.yom,
          name: aircraft.name,
          opi: aircraft.opi,
          seats: aircraft.seats,
          speed: aircraft.cruiseSpeed,
        };
      });
    } else {
      return [];
    }
  };

  const safetyStandards = [
    { label: "ARG/US Gold+", value: false },
    { label: "ARG/US Platinum", value: false },
    { label: "Wyvern Wingman", value: false },
    { label: "Wyvern Pass", value: false },
    { label: "Wyvern Registered", value: false },
    { label: "ARG/US Gold", value: false },
  ];

  const amenities = [
    { label: "Cabin Attendant", value: false },
    { label: "WiFi (adtl charges may apply)", value: false },
    { label: "Free WiFi", value: false },
    { label: "Enhanced Multimedia", value: false },
    { label: "Standard", value: false },
  ];

  const handleClientChange = (selectedClient) => {
    if (selectedClient) {
      selectedClient._id = selectedClient?.value;

      dispatch({
        type: "fieldChanged",
        field: "selectedClient",
        value: selectedClient,
      });
    } else {
      // dispatch({
      //   type: "fieldChanged",
      //   field: "outreachProspectId",
      //   value: null,
      // });

      dispatch({
        type: "fieldChanged",
        field: "selectedClient",
        value: selectedClient,
      });
    }
  };

  const handleClientOptionCreation = (inputValue) => {
    console.log("HANDLE CLIENT OPTION CREATION", inputValue);

    dispatch({
      type: "fieldChanged",
      field: "createProspectModalIsOpen",
      value: true,
    });
  };

  const handleAuthorizedSignerOptionCreation = (inputValue) => {
    console.log("HANDLE AUTHORIZED SIGNER CREATION", inputValue);

    dispatch({
      type: "fieldChanged",
      field: "createAuthorizedSignerModalIsOpen",
      value: true,
    });
  };

  const handleContractSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let legs = state.legs.map(
      ({
        departureDate,
        departureAirport,
        arrivalAirport,
        passengers,
        etd,
        eta,
        ete,
        aircraft,
        // selectedOperator,
        arrivalTimeZone,
        departureTimeZone,
      }) => {
        // let operator = {
        //   id: selectedOperator?._id,
        //   name: selectedOperator?.operatorName || selectedOperator?.name,
        // };

        return {
          departureDate,
          departureAirport,
          arrivalAirport,
          passengers,
          etd,
          eta,
          ete,
          aircraft,
          arrivalTimeZone,
          departureTimeZone,
          // operator,
        };
      }
    );

    let {
      flightCharges,
      FET,
      paymentType,
      segmentFees,
      catering,
      groundTransportation,
      otherExpense,
      paymentApplied,
      discount,
      subject,
      email,
      cc,
      ccFee,
      signerName,
      signerEmail,
      signerPhone,
      signerPhoneCountry,
    } = state;

    if (cc.length) {
      cc = cc.map((item) => item.value);
    }
    let sourcingRecordIds = [];
    if (sourcingRecords?.length) {
      sourcingRecordIds = sourcingRecords.map((i) => i._id);
    }

    let contractNotes = stateToHTML(
      state.contractNotesEditor.getCurrentContent()
    );

    let contractData = {
      clientId: state.selectedClient?._id,
      useDifferentAuthorizedSigner: state.useDifferentAuthorizedSigner,
      authorizedSignerId: state.selectedAuthorizedSigner?._id,
      legs,
      pricing: {
        flightCharges: +flightCharges,
        FET: +FET,
        paymentType,
        segmentFees: +segmentFees,
        catering: +catering,
        groundTransportation: +groundTransportation,
        otherExpense: +otherExpense,
        paymentApplied: +paymentApplied,
        discount: +discount,
        ccFee: +ccFee,
      },
      recipientFields: {
        subject,
        email,
        cc,
      },
      leadId: state?.leadData?._id,
      sourcingRecordIds,
      contractNotes,
      contractCancellationPolicies: state.contractCancellationPolicies,
      dusCancellationPolicy: state.dusCancellationPolicy,
      companyName: state?.companyName,
      signerName,
      signerEmail,
      signerPhone,
      signerPhoneCountry,
    };

    if (tripId) {
      contractData.tripId = tripId;
    }

    console.log("CONTRACT DATA BEFORE SUBMISSION", contractData);

    let response = false;

    if (!contractId || contractId === "new") {
      console.log("CREATE CONTRACT");

      if (tripId) {
        //create new for trip
        response = await ai
          .auth(token)
          .post(`/api/trips/${tripId}/contracts/create`, contractData)
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error);
          });
      } else {
        //create new and create trip
        response = await ai
          .auth(token)
          .post(`/api/trips/contracts/create`, contractData)
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error);
          });
      }
    }

    if (contractId && contractId !== "new") {
      //edit
      response = await ai
        .auth(token)
        .put(`/api/trips/${tripId}/contracts/${contractId}`, contractData)
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }

    if (response && response.data) {
      console.log("TRIP DATA", response.data);
      dispatch({
        type: "fieldChanged",
        field: "tripData",
        value: response.data,
      });

      setTimeout(() => {
        toast.success("Contract saved successfully!", toastOptions);
      }, 500);

      history.push(
        `/trips/${response.data?.tripId}/contracts/${response.data._id}/preview`
      );

      // dispatch({
      //   type: "fieldChanged",
      //   field: "tripId",
      //   value: "1",
      // });
    }
  };

  const handleAirportChange = async (legIndex, airport, airportIndex) => {
    // airportIndex = 0 = depart
    // airportIndex = 1 = arrive

    let legData = { ...state.legs[legIndex] };

    if (airportIndex === 0) {
      legData.departureAirport = {
        id: airport.value,
        name: airport.label,
      };

      let departureTimeZone = null;
      if (airport.tz && airport.tz.length) {
        departureTimeZone = airport.tz[0];
      }

      console.log(`DEPARTURE TIME ZONE CHANGE`, departureTimeZone, airport);

      // let departureTimeZone = find(airport.lat, airport.lon);
      if (departureTimeZone?.length) {
        dispatch({
          type: "legFieldChanged",
          payload: {
            legIndex: legIndex,
            field: "departureTimeZone",
            value: departureTimeZone,
          },
        });
      }
    }

    if (airportIndex === 1) {
      legData.arrivalAirport = {
        id: airport.value,
        name: airport.label,
      };

      let arrivalTimeZone = null;

      if (airport.tz && airport.tz.length) {
        arrivalTimeZone = airport.tz[0];
      }

      console.log(
        "ARRIVAL TIME ZONE CHANGE FOR LEG",
        legIndex,
        arrivalTimeZone
      );

      if (arrivalTimeZone?.length) {
        dispatch({
          type: "legFieldChanged",
          payload: {
            legIndex: legIndex,
            field: "arrivalTimeZone",
            value: arrivalTimeZone,
          },
        });
      }

      console.log("ARRIVAL AIRPORT CHANGE", airport);
    }

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const handleAircraftChange = async (legIndex, updatedAircraft) => {
    // airportIndex = 0 = depart
    // airportIndex = 1 = arrive

    let legData = { ...state.legs[legIndex] };

    legData.aircraft = updatedAircraft;

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const handleFlightTimeCalculationByIndex = async (legData, legIndex) => {
    //todo - update syntax
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let params = {
      legData: {
        arrivalAirportId: legData.arrivalAirport.id,
        departureAirportId: legData.departureAirport.id,
        legIndex: legIndex,
        aircraftId: legData.aircraft.id,
      },
    };

    let response = await ai
      .auth(token)
      .post(`/api/trips/flightTimes`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (
      response &&
      response?.data &&
      response?.data?.length &&
      response?.data[0]?.value
    ) {
      console.log(response?.data[0]?.value);

      let returnedValueForLeg = response?.data[0]?.value;

      let composedString = "";

      if (returnedValueForLeg?.timeWithWindAdjustment) {
        composedString = timeFormatter(
          returnedValueForLeg?.timeWithWindAdjustment
        );
      }

      console.log("COMPOSED STRING", composedString);

      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex,
          field: "ete",
          value: composedString,
        },
        // values: response.data,
      });

      handleETEChange(legIndex, composedString);

      return toast.success(`ETE and ETA for Leg ${legIndex + 1} were updated.`);
    }
  };

  const calculateETAForLeg = async (ete, etd, leg) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("LEG ETA RECALCULATION");

    let etaFactors = {
      ete,
      etd,
      leg,
    };

    //re-calculate ETA

    let response = await ai
      .auth(token)
      .post(`/api/trips/calculateETAOneLeg`, etaFactors)
      .catch((err) => {
        console.error(err.response.data);
        // toast.error(
        //   // "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
        //   toastOptions
        // );
        return;
      });

    // console.log({ etaRESP: response });

    return response;
  };

  const handleETDChange = async (legIndex, updatedETD) => {
    console.log(legIndex, state.legs[legIndex], updatedETD);

    let leg = { ...state.legs[legIndex] };

    let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    const isValid = pattern.test(leg.ete);

    if (!isValid && leg.ete !== "" && leg.ete !== null) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "ete",
          value: "",
        },
      });

      return toast.error(
        `Please enter a valid ETE for Leg ${
          legIndex + 1
        } to recalculate the ETA.`,
        toastOptions
      );
    }

    let hours = parseInt(leg.ete.split(":")[0]);
    let minutes = parseInt(leg.ete.split(":")[1]);

    console.log(
      `ETD was updated to ${updatedETD}, and the ESTIMATED FLIGHT TIME (ETE) is ${hours} hours ${minutes} minutes. RAW ETE IS ${leg.ete}.`
    );

    let etaResponse = await calculateETAForLeg(leg.ete, updatedETD, leg);
    if (!etaResponse.data) {
      return;
    }
    console.log("ETA RESPONSE", etaResponse.data);

    // console.log(`The revised ETA is ${etaResponse.data}.`);
    if (etaResponse.data?.eta) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          field: "eta",
          value: etaResponse?.data?.eta,
          legIndex,
        },
      });
    }

    if (etaResponse.data?.departureTimeZone) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          field: "departureTimeZone",
          value: etaResponse?.data?.departureTimeZone,
          legIndex,
        },
      });
    }

    if (etaResponse.data?.arrivalTimeZone) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          field: "arrivalTimeZone",
          value: etaResponse?.data?.arrivalTimeZone,
          legIndex,
        },
      });
    }
  };

  const handleETEChange = async (legIndex, updatedETE) => {
    console.log(legIndex, state.legs[legIndex], updatedETE);

    let leg = { ...state.legs[legIndex] };

    let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    const isValid = pattern.test(updatedETE);

    if (!isValid) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "ete",
          value: "",
        },
      });

      return toast.error(
        `Please enter a valid ETE for Leg ${legIndex + 1}`,
        toastOptions
      );
    }

    let hours = parseInt(updatedETE.split(":")[0]);
    let minutes = parseInt(updatedETE.split(":")[1]);

    console.log("updated ETE is", hours, "hours", minutes, "minutes");

    if (!state.legs[legIndex]?.etd) {
      return toast.error(
        `Please enter a valid ETD for Leg ${
          legIndex + 1
        } to recalculate the ETA.`,
        toastOptions
      );
    }

    let etaResponse = await calculateETAForLeg(
      updatedETE,
      state.legs[legIndex]?.etd,
      leg
    );

    console.log("ETA RESP IN HANDLE ETE CHANGE FUNCTION", etaResponse.data);

    dispatch({
      type: "legFieldChanged",
      payload: {
        field: "eta",
        value: etaResponse.data?.eta,
        legIndex,
      },
    });
  };

  const handleFlightTimeCalculation = async (legData) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    if (!state.routingProcessed) {
      return;
    }

    let params = {
      legData: state.legs.flatMap((item, index) => {
        if (!item?.aircraft?.id) {
          return [];
        }

        return {
          arrivalAirportId: item.arrivalAirport.id,
          departureAirportId: item.departureAirport.id,
          legIndex: index,
          aircraftId: item?.aircraft?.id,
        };
      }),
    };

    if (params?.legData?.length === 0) {
      return;
    }

    let response = await ai
      .auth(token)
      .post(`/api/trips/flightTimesMulti`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (response && response.data) {
      console.log("TIMES", response.data);
      dispatch({
        type: "flightTimesDownloaded",
        values: response.data,
      });
      return response.data;
    }
  };

  const handleFieldChange = (e) => {
    console.log("EVENT", e.target.name);

    // if (e.target.type === "number") {
    //   return dispatch({
    //     type: "fieldChanged",
    //     field: e.target.name,
    //     value: parseInt(e.target.value),
    //     fieldType: e.target.type,
    //   });
    // }

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
      fieldType: e.target.type,
    });
  };

  const handleCheckboxChange = (e) => {
    console.log("EVENT", e.target.name);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.checked,
    });
  };

  const handlePriceFieldChange = (values, sourceInfo) => {
    const { formattedValue, value } = values;
    // Event is a Synthetic Event wrapper which holds target and other information. Source tells whether the reason for this function being triggered was an 'event' or due to a 'prop' change
    const { event, source } = sourceInfo;

    console.log("event", sourceInfo);

    if (!event?.target) {
      return;
    }

    dispatch({
      type: "fieldChanged",
      field: event?.target?.name,
      value: value,
      fieldType: "float",
    });
  };

  const handleOperatorChange = (selectedOperator, legIndex) => {
    console.log(selectedOperator, legIndex);

    if (selectedOperator) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "selectedOperator",
          value: selectedOperator,
        },
      });
    } else {
      resetSelection("selectedOperator", legIndex);
    }
  };

  const resetSelection = (fieldName, legIndex) => {
    setTimeout(() => {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "selectedOperator",
          value: null,
        },
      });
    }, 0);
  };

  useEffect(() => {
    if (document) {
      document.getElementById("page-heading").scrollIntoView({
        behavior: "smooth",
        // block: "start",
        // inline: "nearest",
      });
    }

    if (sourcingRecords?.length) {
      processSourcing(sourcingRecords);
    }

    processSubject();
  }, [state.loading, sourcingRecords]);

  return (
    <Fragment>
      <h2 className="base-heading" id="page-heading">
        {contractId && contractId !== "new" ? `Edit Contract` : `New Contract`}
      </h2>

      {!state.loading && (
        <Fragment>
          <form
            id="new-contract"
            onSubmit={handleContractSubmit}
            className="base-form"
          >
            <ProspectsAutocomplete
              onCreateOption={handleClientOptionCreation}
              handleProspectChange={handleClientChange}
              prospectOptions={[]}
              parentDispatch={dispatch}
              selectedProspect={state.selectedClient}
              label="Client"
              placeholder="Select a Client"
              required
            />

            <CreateProspectModal
              modalIsOpen={state.createProspectModalIsOpen}
              parentDispatch={dispatch}
              toast={toast}
              defaultPhoneNumber={state.modalPhoneNumber}
              toastOptions={toastOptions}
            />
            {/* 
            <div className="form-group">
              <label>Use a Different Authorized Signer?</label>
              <Checkbox
                checked={state.useDifferentAuthorizedSigner}
                onChange={handleCheckboxChange}
                name="useDifferentAuthorizedSigner"
              />
            </div> */}

            <div className="flex-row column-gap-1pct four-columns">
              <TextInput
                name="signerName"
                label="Signer Name"
                handleChange={handleTextInputChange}
                value={state.signerName}
              ></TextInput>

              <TextInput
                name="signerEmail"
                label="Signer Email"
                handleChange={handleTextInputChange}
                value={state.signerEmail}
                type={`email`}
              ></TextInput>

              <TextInput
                name="companyName"
                label="On Behalf Of"
                handleChange={handleTextInputChange}
                value={state.companyName}
                className={"companyName"}
              ></TextInput>

              <div className="form-group ml-0" key={`signerPhone`}>
                <label className="phoneInputLabel" htmlFor="phone">
                  Phone Number
                </label>

                <PhoneInput
                  placeholder="Phone Number"
                  value={state?.signerPhone}
                  name={`signerPhone`}
                  onChange={(value) => {
                    console.log("NUMBER CHANGE", value);

                    dispatch({
                      type: "fieldChanged",
                      field: "signerPhone",
                      value: value,
                    });
                  }}
                  onCountryChange={(value) => {
                    console.log("COUNTRY CHANGE", value);
                    dispatch({
                      type: "fieldChanged",
                      field: "signerPhoneCountry",
                      value: value || "",
                    });
                  }}
                  defaultCountry={state?.signerPhoneCountry}
                  className="mb-0 base-phone-input"
                />
              </div>
            </div>

            {state.useDifferentAuthorizedSigner && false && (
              <>
                <div className="form-group mt20">
                  <label htmlFor="selectedAuthorizedSigner">
                    Authorized Signer
                  </label>

                  <CreatableSelect
                    className="authorized-signer-select"
                    classNamePrefix="select"
                    placeholder="Authorized Signer"
                    isClearable
                    options={state.authorizedSigners}
                    onChange={(val) =>
                      dispatch({
                        type: "fieldChanged",
                        field: "selectedAuthorizedSigner",
                        value: val,
                      })
                    }
                    value={state.selectedAuthorizedSigner}
                    name={"selectedAuthorizedSigner"}
                    onCreateOption={handleAuthorizedSignerOptionCreation}
                    formatCreateLabel={(inputValue) =>
                      "Add an Authorized Signer"
                    }
                  ></CreatableSelect>
                </div>

                <CreateAuthorizedSignerModal
                  modalIsOpen={state.createAuthorizedSignerModalIsOpen}
                  parentDispatch={dispatch}
                  toast={toast}
                  toastOptions={toastOptions}
                  modalStateKey={`createAuthorizedSignerModalIsOpen`}
                />
              </>
            )}

            <div className="flight-details-container">
              <h3>Routing</h3>

              {state?.legs?.length > 0 &&
                state?.legs?.map((leg, index) => (
                  <div className="flight-details-block" key={index}>
                    <div className="flex-row column-gap-1pct">
                      <div className="datepickerContainer mb-0">
                        <label
                          htmlFor={`departureDate[${index}]`}
                          className="base-label"
                        >
                          Date <span className="required">*</span>
                        </label>

                        <Datepicker
                          value={leg.departureDate}
                          onChange={(event) => {
                            console.log(event.value);

                            dispatch({
                              type: "legFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "departureDate",
                                value: event.value,
                              },
                            });
                          }}
                          dataTimezone="utc"
                          displayTimezone="local"
                          timezonePlugin={momentTimezone}
                          controls={["date"]}
                        />
                        {/* 

                    <DatePicker
                      name={`departureDate[${index}]`}
                      onChange={(date) => {
                        dispatch({
                          type: "legFieldChanged",
                          payload: {
                            legIndex: index,
                            field: "departureDate",
                            value: date,
                          },
                        });
                      }}
                      value={leg.departureDate}
                    ></DatePicker> */}
                      </div>
                      <div className={`form-group flex-half`}>
                        <label htmlFor={`etd[${index}]`}>
                          ETD <span className="required">*</span>
                        </label>

                        <Datepicker
                          onChange={(e) => {
                            handleETDChange(index, e.value);

                            dispatch({
                              type: "legFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "etd",
                                value: e.value || null,
                              },
                            });
                          }}
                          // label="With Data Timezone"
                          timezonePlugin={momentTimezone}
                          dataTimezone="utc"
                          displayTimezone={leg.departureTimeZone}
                          controls={["time"]}
                          value={leg.etd}
                        ></Datepicker>
                      </div>

                      <div className={`form-group flex-half`}>
                        <label htmlFor={`eta[${index}]`}>ETA</label>

                        <Datepicker
                          onChange={(e) => {
                            dispatch({
                              type: "legFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "eta",
                                value: e.value || null,
                              },
                            });
                          }}
                          timezonePlugin={momentTimezone}
                          dataTimezone="utc"
                          displayTimezone={leg.arrivalTimeZone}
                          controls={["time"]}
                          value={leg.eta}
                        ></Datepicker>
                      </div>

                      <div className="airportContainer flex-grow-1 mb-0">
                        <label
                          htmlFor={`departureAirport[${index}]`}
                          className="base-label"
                        >
                          Depart <span className="required">*</span> (
                          {leg.departureTimeZone})
                        </label>

                        <AsyncSelect
                          key={`departure-${leg.departureAirport?.airportName}`}
                          name={`departureAirport[${index}]`}
                          className="departure-airport-select mb-0"
                          classNamePrefix="select"
                          cacheOptions
                          loadOptions={loadAirportOptions}
                          placeholder="Search Airports"
                          defaultValue={{
                            label: leg.departureAirport.airportName,
                            value: leg.departureAirport.id,
                          }}
                          // onInputChange={props.handleDepartureInputChange}
                          onChange={(selectedAirport) => {
                            handleAirportChange(index, selectedAirport, 0);

                            dispatch({
                              type: "legFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "departureAirport",
                                value: {
                                  airportName: selectedAirport?.label || "",
                                  id: selectedAirport?.value || "",
                                  code: selectedAirport?.code || "",
                                  city: selectedAirport?.city || "",
                                  localCode: selectedAirport?.localCode || "",
                                },
                              },
                            });
                          }}
                        ></AsyncSelect>
                      </div>
                      <div className="airportContainer flex-grow-1">
                        <label
                          htmlFor={`departureAirport[${index}]`}
                          className="base-label"
                        >
                          Arrive <span className="required">*</span> (
                          {leg.arrivalTimeZone})
                        </label>

                        <AsyncSelect
                          key={`arrival-${leg.arrivalAirport.airportName}`}
                          name={`arrivalAirport[${index}]`}
                          className="arrival-airport-select mb-0"
                          classNamePrefix="select"
                          cacheOptions
                          loadOptions={loadAirportOptions}
                          placeholder="Search Airports"
                          defaultValue={{
                            label: leg.arrivalAirport.airportName,
                            value: leg.arrivalAirport.id,
                          }}
                          // onInputChange={props.handleDepartureInputChange}
                          onChange={(selectedAirport) => {
                            handleAirportChange(index, selectedAirport, 1);

                            dispatch({
                              type: "legFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "arrivalAirport",
                                value: {
                                  airportName: selectedAirport?.label || "",
                                  id: selectedAirport?.value || "",
                                  code: selectedAirport?.code || "",
                                  city: selectedAirport?.city || "",
                                  localCode: selectedAirport?.localCode || "",
                                },
                              },
                            });
                          }}
                        ></AsyncSelect>
                      </div>
                    </div>

                    <div className="flex-row column-gap-1pct">
                      <TextInput
                        // type={"time"}
                        name={`ete[${index}]`}
                        label="ETE"
                        handleChange={(e) => {
                          dispatch({
                            type: "legFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "ete",
                              value: e.target.value || "",
                            },
                          });
                        }}
                        handleBlur={(e) => {
                          handleETEChange(index, e.target.value);
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={leg.ete}
                      ></TextInput>

                      <TextInput
                        type={"number"}
                        name={`passengers[${index}]`}
                        label="PAX"
                        handleChange={(e) => {
                          dispatch({
                            type: "legFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "passengers",
                              value: parseInt(e.target.value) || "",
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={leg.passengers}
                        required
                      ></TextInput>

                      <div className="form-group flex-grow-1">
                        <label htmlFor={`aircraft[${index}]`}>Aircraft</label>
                        <AsyncSelect
                          name={`aircraft[${index}]`}
                          className="aircraft-select mb-0"
                          classNamePrefix="select"
                          cacheOptions
                          key={`aircraft[${index}]${leg?.aircraft?.name}`}
                          loadOptions={loadAircraftOptions}
                          placeholder="Search Aircraft"
                          defaultValue={{
                            label: leg?.aircraft?.name,
                            value: leg?.aircraft?.id,
                          }}
                          // onInputChange={handleInputchange}
                          onChange={(selectedAircraft) => {
                            console.log("SELECTED AIRCRAFT", selectedAircraft);

                            if (selectedAircraft) {
                              handleAircraftChange(index, selectedAircraft);

                              dispatch({
                                type: "legFieldChanged",
                                payload: {
                                  legIndex: index,
                                  field: "aircraft",
                                  value: {
                                    name: selectedAircraft.name,
                                    seats: selectedAircraft.seats,
                                    yom: selectedAircraft.yom,
                                    // opi: selectedAircraft.opi || "",
                                    id: selectedAircraft.id,
                                  },
                                },
                              });
                            }

                            if (!selectedAircraft) {
                              dispatch({
                                type: "legFieldChanged",
                                payload: {
                                  legIndex: index,
                                  field: "aircraft",
                                  value: {},
                                },
                              });
                            }
                          }}
                          isClearable
                        ></AsyncSelect>
                      </div>
                      {/* <OperatorAutocomplete
                    handleChange={(selectedOperator) =>
                      handleOperatorChange(selectedOperator, index)
                    }
                    defaultOperatorOptions={state.operatorOptions}
                    parentDispatch={dispatch}
                    selectedOperator={leg.selectedOperator}
                    containerClasses="flex-grow-1"
                  ></OperatorAutocomplete> */}
                    </div>

                    {index >= 1 && (
                      <button
                        className="deleteLeg action-button base-gunmetal-button"
                        onClick={(e) => {
                          e.preventDefault();
                          return dispatch({ type: "legDeleted", index: index });
                        }}
                        type="button"
                      >
                        Delete Leg {index + 1}
                      </button>
                    )}
                  </div>
                ))}
              <button
                className="action-button base-blue-button mt-0"
                onClick={handleAddLeg}
                type="button"
              >
                Add Leg
              </button>
            </div>

            <ContractCancellationPolicies
              parentDispatch={dispatch}
              contractCancellationPolicies={state.contractCancellationPolicies}
              dusCancellationPolicy={state.dusCancellationPolicy}
            ></ContractCancellationPolicies>

            <div className="notes-container">
              <h3>Contract Message</h3>

              <Editor
                editorState={state.contractNotesEditor}
                wrapperClassName="header-editor-wrapper"
                editorClassName="notes-editor"
                onEditorStateChange={(editorState) =>
                  dispatch({
                    type: "fieldChanged",
                    field: "contractNotesEditor",
                    value: editorState,
                  })
                }
              />
            </div>

            <div className="pricing-container">
              <h3>Pricing</h3>

              <p>
                <strong>
                  {`Flight Charges${
                    state.paymentType === "cc" ? " (Subtotal)" : ``
                  }: `}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                  }).format(state.flightCharges)}
                </strong>
              </p>

              {state.paymentType === "cc" &&
                !isNaN(parseFloat(state.ccFee)) && (
                  <p>
                    <strong>
                      Flight Charges (Grand Total){" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                      }).format(
                        parseFloat(state.flightCharges) +
                          parseFloat(state.ccFee)
                      )}
                    </strong>
                  </p>
                )}

              <div className="form-group">
                <label>Total Flight Charges (Incl. Fees, Except CC Fee)</label>

                <NumberFormat
                  value={state.flightCharges}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Flight Charges"
                  className="semibold-text"
                  decimalScale={2}
                  name="flightCharges"
                />
              </div>

              <FormControl className="form-group base-radio-group">
                <label id="payment-type-label">
                  Payment Type <span className="required">*</span>{" "}
                </label>

                <RadioGroup
                  aria-labelledby="payment-type-label"
                  name="paymentType"
                  value={state.paymentType}
                  onChange={handleFieldChange}
                >
                  <FormControlLabel
                    value="wire"
                    control={<Radio />}
                    label="Wire"
                  />
                  <FormControlLabel
                    value="cc"
                    control={<Radio />}
                    label="Credit Card"
                  />
                </RadioGroup>
              </FormControl>

              {state.paymentType === "cc" && (
                <div className="form-group">
                  <label>CC Processing Fee</label>

                  <NumberFormat
                    value={state.ccFee}
                    thousandSeparator={true}
                    prefix={"$"}
                    onValueChange={handlePriceFieldChange}
                    placeholder="CC Processing Fee"
                    className="semibold-text"
                    decimalScale={2}
                    name="ccFee"
                  />
                </div>
              )}

              <div className="form-group">
                <label>FET</label>

                <NumberFormat
                  value={state.FET}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="FET"
                  className="semibold-text"
                  decimalScale={2}
                  name="FET"
                />
              </div>

              <div className="form-group">
                <label>Segment Fees</label>

                <NumberFormat
                  value={state.segmentFees}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Segment Fees"
                  className="semibold-text"
                  decimalScale={2}
                  name="segmentFees"
                />
              </div>

              {/* <div className="form-group">
                <label>Catering</label>

                <NumberFormat
                  value={state.catering}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Catering"
                  className="semibold-text"
                  decimalScale={2}
                  name="catering"
                />
              </div>

              <div className="form-group">
                <label>Ground Transportation</label>

                <NumberFormat
                  value={state.groundTransportation}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Ground Transportation"
                  className="semibold-text"
                  decimalScale={2}
                  name="groundTransportation"
                />
              </div>

              <div className="form-group">
                <label>Other Expense</label>

                <NumberFormat
                  value={state.otherExpense}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Other Expense"
                  className="semibold-text"
                  decimalScale={2}
                  name="otherExpense"
                />
              </div> */}

              <div className="form-group">
                <label>Payment Applied</label>

                <NumberFormat
                  value={state.paymentApplied}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Payment Applied"
                  className="semibold-text"
                  decimalScale={2}
                  name="paymentApplied"
                />
              </div>

              <div className="form-group">
                <label>Discount</label>

                <NumberFormat
                  value={state.discount}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Discount"
                  className="semibold-text"
                  decimalScale={2}
                  name="discount"
                />
              </div>
            </div>

            <div className="recipient-fields-container">
              {/* <h3>Recipient Fields</h3>

              <TextInput
                name="subject"
                label="Subject"
                handleChange={handleTextInputChange}
                value={state.subject}
                className={"subject-field"}
                required
              ></TextInput>

              <TextInput
                name="email"
                type="email"
                label="Email"
                handleChange={handleTextInputChange}
                value={state.email}
                className={"email-field"}
                required
              ></TextInput> */}
              {/* 
          <div className="form-group mt20">
            <label htmlFor="email">Email</label>
            <CreatableSelect
              className="email-select"
              classNamePrefix="select"
              placeholder="Email"
              components={emailComponents}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={handleEmailChange}
              onInputChange={handleEmailInputChange}
              onKeyDown={handleEmailKeyDown}
              inputValue={state.emailInputValue}
              value={state.email}
              name={"email"}
              inputId={"email"}
            ></CreatableSelect>
          </div> */}
              {/* <div className="form-group">
                <label htmlFor="cc">CC</label>
                <CreatableSelect
                  className="email-select"
                  classNamePrefix="select"
                  placeholder="Cc"
                  components={emailComponents}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleCcChange}
                  onInputChange={handleCcInputChange}
                  onKeyDown={handleCcKeyDown}
                  inputValue={state.ccInputValue}
                  value={state.cc}
                  inputId={"cc"}
                  name={"cc"}
                ></CreatableSelect>
              </div> */}
            </div>

            <input type="submit" value="Save and Preview"></input>
          </form>

          <StateDisplay user={user} data={state}></StateDisplay>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NewContractContent;
