import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useParams, Link, useHistory } from "react-router-dom";
import PageHeaderRow from "../shared/page-header-row";
import moment from "moment";
import formatStage from "../../helpers/format-stage";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import LeadDetailsDesktop from "./lead-details-desktop";
import LeadDetailsMobile from "./lead-details-mobile";
import TextAreaInput from "../textarea-input";
import Paper from "@mui/material/Paper";
import OperatorAutocomplete from "../opi/operator-autocomplete";

import MessageForm from "../messages/message-form";
import MessageList from "../messages/message-list";

import ReactModal from "react-modal";

const reducer = (state, action) => {
  switch (action.type) {
    // case "search": {
    //   return {
    //     ...state,
    //     query: action.query,
    //   };
    // }
    // case "fieldChanged": {
    //   return {
    //     ...state,
    //     airport: {
    //       ...state.airport,
    //       [action.field]: action.value,
    //     },
    //   };
    // }

    case "fieldChanged": {
      return {
        ...state,
        lead: {
          ...state.lead,
          [action.field]: action.value,
        },
      };
    }

    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "resetOperatorModal": {
      return {
        ...state,
        selectedOperator: null,
        unavailableOperatorNotes: "",
      };
    }

    case "resetAndCloseOperatorModal": {
      return {
        ...state,
        selectedOperator: null,
        unavailableOperatorNotes: "",
        createOperatorCallLogRecordModalIsOpen: false,
      };
    }

    case "closeOperatorModal": {
      return {
        ...state,
        createOperatorCallLogRecordModalIsOpen: false,
      };
    }

    case "dataBeganDownloading": {
      return {
        ...state,
        // loading: true,
      };
    }
    case "dataDownloaded": {
      let sourcingRows = [];
      let lead = action?.payload?.data;

      if (action?.payload?.data?.sourcingRecords) {
        sourcingRows = action?.payload?.data?.sourcingRecords;
      }

      if (!action?.payload?.data?.operatorCallLog?.length) {
        lead.operatorCallLog = [];
      }

      return {
        ...state,
        lead: action.payload.data,
        sourcingRows,
        loading: false,
        loadCount: state.loadCount + 1,
      };
    }

    case "rowSelectionEvent": {
      let selectedRowIds = [...state.selectedRows].map((row) => row._id);

      let newSelection = new Set([...selectedRowIds]);

      if (action.selected) {
        newSelection.add(action.rowId);
      }

      if (!action.selected) {
        newSelection.delete(action.rowId);
      }

      newSelection = [...newSelection];

      let selectedRows = state?.lead?.sourcingRecords?.filter((record) =>
        newSelection.includes(record._id)
      );

      console.log("selected rows", selectedRows);

      return { ...state, selectedRows: [...selectedRows] };

      // return {
      //   ...state,
      //   selectedRows: [...selectedRows],
      // };
    }

    default:
      break;
  }
  return state;
};

const SingleLeadContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    lead: {
      itineraryDetails: {
        departureAirportName: "",
        arrivalAirportName: "",
        departureDate: "",
        departureTime: "",
        returnDate: "",
        returnTime: "",
        passengerCount: "",
        companyName: "",
      },
      aircraftSelection: [],
      baggage: [],
      quotes: [],
      internalComments: "",
      tripOptions: [],
      operatorCallLog: [],
    },
    loading: true,

    selectedRows: [],
    sourcingRows: [],

    deleteSourcingRecordModalOpen: false,
    sourcingRecordToDelete: null,
    deleteOperatorCallLogModalOpen: false,
    operatorCallLogRecordToDelete: null,
    createOperatorCallLogRecordModalIsOpen: false,

    selectedOperator: null,
    unavailableOperatorNotes: "",

    sourcingSortField: "createdAt",
    sourcingSortDirection: -1,
    unavailableOperatorSortDirection: -1,
    unavailableOperatorSortField: "timestamp",
    loadCount: 0,
    latestMessage: null,
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const formatCurrency = (x) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getAircraftSelection = () => {
    if (state?.lead?.aircraftSelection?.length) {
      return state?.lead?.aircraftSelection
        .map((aircraft) => formatAircraftSelection(aircraft))
        .join(", ");
    }
    return "N/A";
  };

  const formatAircraftSelection = (aircraft) => {
    switch (aircraft) {
      case "heavy_jet":
        return "Heavy Jet";
      case "executive_turboprop":
        return "Turboprop";
      case "compact_light_jet":
        return "VLJ";
      case "light_jet":
        return "Light Jet";
      case "midsize_jet":
        return "Midsize Jet";
      case "super_midsize_jet":
        return "Super Midsize Jet";
      default:
        return aircraft;
    }
  };

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/leads/${id}/l2`)
      .catch((error) => {
        history.push({ pathname: "/leads" });
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response) {
      console.log("LEAD DATA", response.data);

      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  const handleLeadNotesUpdate = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .put(`/api/leads/${id}/updateNotes`, {
        notes: state.lead.internalComments,
      })
      .catch((e) => console.error(e));

    toast.success("Notes Updated.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    console.log("lead notes update", data, state.lead.internalComments);
  };

  const handleTextInputChange = (e) => {
    let value = e.target.value;

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: value,
    });
  };

  const handleRootTextInputChange = (e) => {
    let value = e.target.value;

    dispatch({
      type: "rootFieldChanged",
      field: e.target.name,
      value: value,
    });
  };

  const handleSourcingRecordDelete = (recordId) => {
    console.log(`DELETE OF ${recordId} INITIATED - MODAL CONFIRMATION`);
    dispatch({
      type: "rootFieldChanged",
      field: "deleteSourcingRecordModalOpen",
      value: true,
    });

    dispatch({
      type: "rootFieldChanged",
      field: "sourcingRecordToDelete",
      value: recordId,
    });
  };

  const handleOperatorCallLogDelete = (recordId) => {
    console.log(
      `DELETE OF OPERATOR CALL LOG ${recordId} INITIATED - MODAL CONFIRMATION`
    );
    dispatch({
      type: "rootFieldChanged",
      field: "deleteOperatorCallLogModalOpen",
      value: true,
    });

    dispatch({
      type: "rootFieldChanged",
      field: "operatorCallLogRecordToDelete",
      value: recordId,
    });
  };

  const handleSourcingRecordHide = async (recordId) => {
    console.log(`HIDE OF ${recordId} INITIATED - API REQUEST`);
    // dispatch({
    //   type: "rootFieldChanged",
    //   field: "hideSourcingRecord",
    //   value: true,
    // });
    if (!recordId) {
      return;
    }

    // console.log("TO DO", recordId);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .put(`/api/sourcing/${recordId}/toggleHiddenState`)
      .catch((e) => console.error(e));

    if (data) {
      toast.success("Record Updated.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      console.log("UPDATED RECORD", data);
      return getData();
    }

    return toast.error("Record update failed.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const deleteSourcingRecord = async (recordId) => {
    if (!recordId) {
      return;
    }

    console.log(`DELETE OF ${recordId} INITIATED - API REQUEST`);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .delete(`/api/sourcing/${state.sourcingRecordToDelete}`)
      .catch((e) => console.error(e));

    toast.success("Record Deleted.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    getData();
  };

  const deleteOperatorCallLogRecord = async (recordId) => {
    if (!recordId) {
      return;
    }

    console.log(
      `DELETE OF OPERATOR CALL LOG ${recordId} INITIATED - API REQUEST`
    );

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .delete(`/api/leads/${id}/operatorCallLog/${recordId}`)
      .catch((e) => console.error(e));

    toast.success("Unavailable Operator Record Deleted.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    getData();
  };

  const handleAddOperatorButton = (e) => {
    console.log("ADD OPERATOR BUTTON");
    dispatch({
      type: "rootFieldChanged",
      field: "createOperatorCallLogRecordModalIsOpen",
      value: true,
    });
  };

  const handleOperatorChange = (selectedOperator) => {
    console.log(selectedOperator);

    if (selectedOperator) {
      dispatch({
        type: "rootFieldChanged",
        field: "selectedOperator",
        value: selectedOperator,
      });
    } else {
      resetSelection("selectedOperator");
    }
  };

  const resetSelection = (fieldName) => {
    setTimeout(() => {
      dispatch({
        type: "rootFieldChanged",
        field: fieldName,
        value: null,
      });
    }, 0);
  };

  const addOperatorCallLogRecord = async (e) => {
    console.log(`ADD OF OPERATOR CALL LOG RECORD INITIATED - API REQUEST`);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let operatorCallLogData = {
      operator: {
        id: state?.selectedOperator?._id,
        name: state?.selectedOperator?.value,
      },
      notes: state.unavailableOperatorNotes,
    };

    console.log(operatorCallLogData);

    // return;

    let { data } = await ai
      .auth(token)
      .put(`/api/leads/${id}/operatorCallLog`, operatorCallLogData)
      .catch((e) => console.error(e));

    toast.success("Unavailable Operator Added.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    // dispatch({
    //   type:"fieldChanged",
    // })

    if (e.target.name === "addOperatorCallLog-another") {
      dispatch({ type: "resetOperatorModal" });
    }

    if (e.target.name === "addOperatorCallLog-close") {
      dispatch({ type: "resetAndCloseOperatorModal" });
    }

    getData();
  };

  useEffect(() => {
    getData();
    document.title = "Lead | Flight Deck by evoJets";
  }, []);

  useEffect(() => {
    let sourcingRows = [...state.sourcingRows];

    sourcingRows = sourcingRows.sort((a, b) => {
      console.log(
        "SOURCING SORT DATA",
        state.sourcingSortDirection,
        state.sourcingSortField
      );

      if (
        state.sourcingSortField === "createdAt" ||
        state.sourcingSortField === "quoteStatus"
      ) {
        if (state.sourcingSortDirection === 1) {
          if (a?.hidden === false && b?.hidden === false) {
            return (
              new Date(a[state.sourcingSortField]) -
              new Date(b[state.sourcingSortField])
            );
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          return (
            new Date(a[state.sourcingSortField]) -
            new Date(b[state.sourcingSortField])
          );
        }

        if (state.sourcingSortDirection === -1) {
          console.log("HIDDEN", a.hidden, b.hidden);

          if (a?.hidden === false && b?.hidden === false) {
            return (
              new Date(b[state.sourcingSortField]) -
              new Date(a[state.sourcingSortField])
            );
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          return (
            new Date(b[state.sourcingSortField]) -
            new Date(a[state.sourcingSortField])
          );
        }
        // return false;
      }

      if (
        state.sourcingSortField === "aircraftName" ||
        state.sourcingSortField === "operatorName"
      ) {
        console.log(a[state.sourcingSortField], b[state.sourcingSortField]);
        if (state.sourcingSortDirection === 1) {
          if (a?.hidden === false && b?.hidden === false) {
            return a[state.sourcingSortField]?.localeCompare(
              b[state.sourcingSortField]
            );
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          return a[state.sourcingSortField]?.localeCompare(
            b[state.sourcingSortField]
          );
        }

        if (state.sourcingSortDirection === -1) {
          if (a?.hidden === false && b?.hidden === false) {
            return b[state.sourcingSortField]?.localeCompare(
              a[state.sourcingSortField]
            );
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }
          return b[state.sourcingSortField]?.localeCompare(
            a[state.sourcingSortField]
          );
        }
        // return false;
      }

      if (
        state.sourcingSortField === "operatorPrice" ||
        state.sourcingSortField === "totalClientPayment"
      ) {
        if (state.sourcingSortDirection === 1) {
          if (a?.hidden === false && b?.hidden === false) {
            return a[state.sourcingSortField] - b[state.sourcingSortField];
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          return a[state.sourcingSortField] - b[state.sourcingSortField];
        }

        if (state.sourcingSortDirection === -1) {
          if (a?.hidden === false && b?.hidden === false) {
            return b[state.sourcingSortField] - a[state.sourcingSortField];
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          if (a?.hidden === true) {
            return 1;
          }

          if (b?.hidden === true) {
            return -1;
          }

          return b[state.sourcingSortField] - a[state.sourcingSortField];
        }
        // return false;
      }

      return;
    });

    dispatch({
      type: "rootFieldChanged",
      field: "sourcingRows",
      value: sourcingRows,
    });

    console.log("SOURCING ROWS SORTED", sourcingRows);
  }, [state.sourcingSortDirection, state.sourcingSortField, state.loading]);

  useEffect(() => {
    let unavailableOperators = [...state?.lead?.operatorCallLog];

    unavailableOperators = unavailableOperators.sort((a, b) => {
      console.log(
        "UNAVAILABLE SORT DATA",
        state.unavailableOperatorSortDirection,
        state.unavailableOperatorSortField
      );

      if (state.unavailableOperatorSortField === "timestamp") {
        if (state.unavailableOperatorSortDirection === 1) {
          return (
            new Date(a[state.unavailableOperatorSortField]) -
            new Date(b[state.unavailableOperatorSortField])
          );
        }

        if (state.unavailableOperatorSortDirection === -1) {
          return (
            new Date(b[state.unavailableOperatorSortField]) -
            new Date(a[state.unavailableOperatorSortField])
          );
        }
        // return false;
      }

      if (state.unavailableOperatorSortField === "operatorName") {
        if (state.unavailableOperatorSortDirection === 1) {
          return a.operator.name.localeCompare(b.operator.name);
        }

        if (state.unavailableOperatorSortDirection === -1) {
          return b.operator.name.localeCompare(a.operator.name);
        }
        // return false;
      }

      return;
    });

    dispatch({
      type: "fieldChanged",
      field: "operatorCallLog",
      value: unavailableOperators,
    });

    console.log("UNAVAILABLE ROWS SORTED", unavailableOperators);
  }, [
    state.unavailableOperatorSortDirection,
    state.unavailableOperatorSortField,
    state.loadCount,
  ]);

  const handleSourcingSort = (field) => {
    console.log("SOURCING SORT", field);

    if (field === state.sourcingSortField) {
      //toggle direction only
      return dispatch({
        type: "rootFieldChanged",
        field: "sourcingSortDirection",
        value: state.sourcingSortDirection === -1 ? 1 : -1,
      });
    }

    dispatch({
      type: "rootFieldChanged",
      field: "sourcingSortField",
      value: field,
    });
    dispatch({
      type: "rootFieldChanged",
      field: "sourcingSortDirection",
      value: -1,
    });
  };

  const handleUnavailableOperatorSort = (field) => {
    console.log("UNAVAILABLE SORT", field);

    if (field === state.unavailableOperatorSortField) {
      //toggle direction only
      return dispatch({
        type: "rootFieldChanged",
        field: "unavailableOperatorSortDirection",
        value: state.unavailableOperatorSortDirection === -1 ? 1 : -1,
      });
    }

    dispatch({
      type: "rootFieldChanged",
      field: "unavailableOperatorSortField",
      value: field,
    });
    dispatch({
      type: "rootFieldChanged",
      field: "unavailableOperatorSortDirection",
      value: -1,
    });
  };

  return (
    <Fragment>
      {!state.loading && (
        <PageHeaderRow
          h1={`Lead #${id}`}
          pretitle="Business"
          breadcrumbs={[{ link: "/leads", label: "Leads" }]}
          mobileCurrentPageLabel={`Lead - ${state.lead.firstName} ${state.lead.lastName}`}
          isMobile={true}
        ></PageHeaderRow>
      )}

      {!state.loading && (
        <Paper className="lead-l2-notes-container">
          <form className="base-form" onSubmit={handleLeadNotesUpdate}>
            <h4 className="lead-notes-heading">Lead Notes</h4>
            <TextAreaInput
              type="text"
              name="internalComments"
              value={state.lead.internalComments}
              label="Lead Notes"
              placeholder={`Add a Note`}
              handleChange={handleTextInputChange}
              className="lead-internal-notes"
              containerClasses="lead-l2-notes-textarea-container"
              hideLabel
            ></TextAreaInput>
            <div className="p-l-16 p-r-16 p-b-16">
              <input type="submit" value="Save Notes"></input>
            </div>
          </form>
        </Paper>
      )}

      {!state.loading && superAdmin && (
        <Paper className="messages-container mt-30">
          <h4 className="lead-notes-heading">Lead Messages</h4>

          <MessageList
            threadId={id}
            latestMessage={state.latestMessage}
            parentDispatch={dispatch}
          ></MessageList>
          <MessageForm leadId={id} parentDispatch={dispatch}></MessageForm>
        </Paper>
      )}

      {!state.loading && !isMobile && (
        <LeadDetailsDesktop
          superAdmin={superAdmin}
          rows={[state.lead]}
          lead={state.lead}
          sourcingRows={state.sourcingRows}
          handleSourcingRecordDelete={handleSourcingRecordDelete}
          handleSourcingRecordHide={handleSourcingRecordHide}
          parentDispatch={dispatch}
          selectedRows={state.selectedRows}
          operatorCallLog={state.lead?.operatorCallLog || []}
          handleOperatorCallLogDelete={handleOperatorCallLogDelete}
          handleAddOperatorButton={handleAddOperatorButton}
          handleSourcingSort={handleSourcingSort}
          sourcingSortField={state.sourcingSortField}
          sourcingSortDirection={state.sourcingSortDirection}
          unavailableOperatorSortField={state.unavailableOperatorSortField}
          unavailableOperatorSortDirection={
            state.unavailableOperatorSortDirection
          }
          handleUnavailableOperatorSort={handleUnavailableOperatorSort}
          // key={`${state.sourcingSortField} - ${state.sourcingSortDirection}`}
        ></LeadDetailsDesktop>
      )}

      {!state.loading && (
        <div className="text-center mt-20">
          <Link
            className="evo-blue base-button"
            to={{ pathname: `/contracts/new`, state: { lead: state.lead } }}
          >
            Create Contract
          </Link>
        </div>
      )}

      <ReactModal
        isOpen={state.createOperatorCallLogRecordModalIsOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "rootFieldChanged",
            field: "createOperatorCallLogRecordModalIsOpen",
            value: false,
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            padding: "40px",
            border: "none",
            maxWidth: "700px",
            maxHeight: "80vh",
            minWidth: "50vw",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <h3 className="text-center mb-20">Add an Unavailable Operator</h3>

        <form
          className="base-form mb-20 w-100"
          id="sourcingModuleForm"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <OperatorAutocomplete
            handleChange={handleOperatorChange}
            defaultOperatorOptions={state.operatorOptions}
            parentDispatch={dispatch}
            selectedOperator={state.selectedOperator}
          ></OperatorAutocomplete>
          <TextAreaInput
            type="text"
            name="unavailableOperatorNotes"
            value={state.unavailableOperatorNotes}
            label="Notes"
            placeholder={`Add a Note`}
            handleChange={handleRootTextInputChange}
            className="lead-internal-notes"
            // containerClasses="lead-l2-notes-textarea-container"
            // hideLabel
          ></TextAreaInput>

          <button
            className="mt-0 base-button evo-blue fg-1 p-12 w-100"
            id="addOperatorCallLog-another"
            type="submit"
            name="addOperatorCallLog-another"
            onClick={addOperatorCallLogRecord}
          >
            Submit &amp; Add Another
          </button>

          <button
            className="mt-20 base-button evo-gunmetal fg-1 p-12 w-100"
            id="addOperatorCallLog-close"
            type="submit"
            name="addOperatorCallLog-close"
            onClick={addOperatorCallLogRecord}
          >
            Submit &amp; Close
          </button>

          {/* <pre>{JSON.stringify(state.selectedOperator, null, 2)}</pre> */}
        </form>

        {/* <div id="confirmationButtons">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: "rootFieldChanged",
                field: "deleteOperatorCallLogModalOpen",
                value: false,
              });
            }}
            className="base-button evo-blue"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: "rootFieldChanged",
                field: "deleteOperatorCallLogModalOpen",
                value: false,
              });

              deleteOperatorCallLogRecord(state.operatorCallLogRecordToDelete);
            }}
            className="base-button btn-danger delete-button-shadow"
          >
            Yes, Delete
          </button>
        </div> */}
      </ReactModal>

      <ReactModal
        isOpen={state.deleteOperatorCallLogModalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "rootFieldChanged",
            field: "deleteOperatorCallLogModalOpen",
            value: false,
          });

          dispatch({
            type: "rootFieldChanged",
            field: "operatorCallLogRecordToDelete",
            value: null,
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            padding: "40px",
            border: "none",
            maxWidth: "700px",
            maxHeight: "50%",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <h3>Are you sure you want to delete this record?</h3>
        <div id="confirmationButtons">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: "rootFieldChanged",
                field: "deleteOperatorCallLogModalOpen",
                value: false,
              });
            }}
            className="base-button evo-blue"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: "rootFieldChanged",
                field: "deleteOperatorCallLogModalOpen",
                value: false,
              });

              deleteOperatorCallLogRecord(state.operatorCallLogRecordToDelete);
            }}
            className="base-button btn-danger delete-button-shadow"
          >
            Yes, Delete
          </button>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={state.deleteSourcingRecordModalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "rootFieldChanged",
            field: "deleteSourcingRecordModalOpen",
            value: false,
          });

          dispatch({
            type: "rootFieldChanged",
            field: "sourcingRecordToDelete",
            value: null,
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            padding: "40px",
            border: "none",
            maxWidth: "700px",
            maxHeight: "50%",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <h3>Are you sure you want to delete this record?</h3>
        <div id="confirmationButtons">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: "rootFieldChanged",
                field: "deleteSourcingRecordModalOpen",
                value: false,
              });
            }}
            className="base-button evo-blue"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: "rootFieldChanged",
                field: "deleteSourcingRecordModalOpen",
                value: false,
              });

              deleteSourcingRecord(state.sourcingRecordToDelete);
            }}
            className="base-button btn-danger delete-button-shadow"
          >
            Yes, Delete
          </button>
        </div>
      </ReactModal>

      {!state.loading && isMobile && (
        <LeadDetailsMobile
          // superAdminOrAd={superAdminOrAd}
          rows={[state.lead]}
          lead={state.lead}
          sourcingRows={state.sourcingRows}
          handleSourcingRecordDelete={handleSourcingRecordDelete}
          handleSourcingRecordHide={handleSourcingRecordHide}
          parentDispatch={dispatch}
          selectedRows={state.selectedRows}
          operatorCallLog={state.lead?.operatorCallLog || []}
          handleOperatorCallLogDelete={handleOperatorCallLogDelete}
          handleAddOperatorButton={handleAddOperatorButton}
          handleSourcingSort={handleSourcingSort}
        ></LeadDetailsMobile>
      )}

      {/* <pre>{JSON.stringify(state.lead, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleLeadContent;
