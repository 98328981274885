const formatStage = (stage) => {
  let formattedStage = "N/A";

  if (stage === "newLead") {
    formattedStage = "New Lead";
  } else if (stage === "inProgress") {
    formattedStage = "In Progress";
  } else if (stage === "archived") {
    formattedStage = "Archived";
  } else if (stage === "warmProspect") {
    formattedStage = "Warm Prospect";
  } else if (stage === "unqualified") {
    formattedStage = "Unqualified";
  } else if (stage === "inLimbo") {
    formattedStage = "In Limbo";
  } else if (stage === "signedContract") {
    formattedStage = "Signed Contract";
  } else if (stage === "tripComplete") {
    formattedStage = "Trip Complete";
  } else if (stage === "closedNoReply") {
    formattedStage = "Closed - No Reply";
  } else if (stage === "closedGhost") {
    formattedStage = "Closed - Ghost";
  } else if (stage === "repeatClient") {
    formattedStage = "Lost - Repeat Client";
  } else if (stage === "closedCompetitor") {
    formattedStage = "Closed - Lost to Competitor";
  } else if (stage === "inProgressTemp") {
    formattedStage = "Soft-Deleted";
  } else if (stage === "noReply") {
    formattedStage = "Lost - No Reply";
  } else if (stage === "saved") {
    formattedStage = "Saved";
  } else if (stage === "draft") {
    formattedStage = "Draft";
  } else if (stage === "signed") {
    formattedStage = "Signed";
  } else if (stage === "inReview") {
    formattedStage = "In Review";
  } else if (stage === "canceled") {
    formattedStage = "Canceled";
  } else if (stage === "revoked") {
    formattedStage = "Revoked";
  } else if (stage === "sent") {
    formattedStage = "Sent";
  }

  return formattedStage;
};

export default formatStage;
