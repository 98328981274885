import React, { Fragment, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import columns from "./sourcing-columns";
import Search from "../search";
// import LeadBulkActions from "./lead-bulk-actions";

import DataTableServerPagination from "../data-table-server-pagination";

import PageHeaderRowSearch from "../shared/page-header-row-search";
import moment from "moment-timezone";

import { useMediaQuery } from "react-responsive";

import Select from "react-select";

import SourcingListMobile from "./sourcing-list-mobile";
import BackToTopButton from "../../helpers/back-to-top-button";

const paginationPageOptions = (totalLength, countPerPage) => {
  let pageCount = Math.ceil(totalLength / countPerPage);
  let pageNumbers = [];

  for (let i = 0; i < pageCount; i++) {
    pageNumbers.push(i + 1);
  }

  return pageNumbers;
};

// if (row.leadOrigin === "qfs") {
//   formatted = "Flight Deck - QFS";
// } else if (row.leadOrigin === "liveAPI") {
//   formatted = "Live API";
// } else if (row.leadOrigin === "phoneCall") {
//   formatted = "Phone Call";
// } else if (row.leadOrigin === "flightDeckInternal") {
//   formatted = "Flight Deck - Internal";
// } else if (row.leadOrigin === "flightDeckExternal") {
//   formatted = "Flight Deck - External";
// } else if (row.leadOrigin === "apiV3") {
//   formatted = "Outside Org API";
// } else {
//   formatted = "Unset";
// }

const originFilterOptions = [
  {
    label: "QFS",
    value: "qfs",
  },
  {
    label: "Live API",
    value: "liveAPI",
  },
  { label: "Phone Call", value: "phoneCall" },
  { label: "FD - Internal", value: "flightDeckInternal" },
  { label: "FD - External", value: "flightDeckExternal" },
  { label: "Outside Org API", value: "apiV3" },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }

    case "rowSelectionEvent": {
      let selectedRowIds = [...state.selectedRows].map((row) => row._id);

      let newSelection = new Set([...selectedRowIds]);

      if (action.selected) {
        newSelection.add(action.rowId);
      }

      if (!action.selected) {
        newSelection.delete(action.rowId);
      }

      newSelection = [...newSelection];

      let selectedRows = state.data.sourcingRecords.filter((record) =>
        newSelection.includes(record._id)
      );

      console.log("selected rows", selectedRows);

      return {
        ...state,
        selectedRows: [...selectedRows],
      };
    }

    case "dataDownloaded": {
      console.log("Data Downloaded", action.payload.data);

      return {
        ...state,
        data: {
          sourcingRecords: action.payload.data.sourcingRecords,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }

    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "tableDataChanged": {
      return {
        ...state,
        leads: {
          data: action.value,
        },
      };
    }

    default:
      break;
  }
  return state;
};

const SourcingListContent = (props) => {
  const initialState = {
    loading: true,

    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,

    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,

    ownerInputField: "",
    selectedOwner: null,

    query: "",
    stageFilters: ["inProgress"],
    sortDirection: "desc",
    sortBy: "createdAt",

    data: {
      sourcingRecords: [],
    },

    ownerOptions: [],
    teamMembers: [],
    defaultStageFilters: null,
    pageTitle: props.pageTitle,

    ownerFilters: props?.defaultOwnerFilters?.length
      ? [...props.defaultOwnerFilters.map((filter) => filter.value)]
      : [],
    defaultOwnerFilters: props?.defaultOwnerFilters?.length
      ? props.defaultOwnerFilters
      : [],
    ownerFilterOptions: [],
    originFilterOptions: [],
    ownerFiltersInput: props?.defaultOwnerFilters?.length
      ? props.defaultOwnerFilters
      : [],
    originFiltersInput: props?.defaultOriginFilters?.length
      ? props.defaultOriginFilters
      : [],
    stageFilterInput: [
      {
        label: "In Progress",
        value: "inProgress",
      },
    ],
  };

  if (props.prefilters && props.prefilters.length) {
    initialState.stageFilters = props.prefilters;
  }

  if (props.defaultStageFilters) {
    initialState.defaultStageFilters = props.defaultStageFilters;
    initialState.stageFilterInput = props.defaultStageFilters;
  }

  if (props.qfsOnly) {
    initialState.defaultStageFilters = [];
    initialState.stageFilters = [];
  }

  const { user } = useAuth0();

  if (
    user["https://app.evojets.com/appMetadata"]?.teamColor &&
    props.pageTitle === "My Team's Leads"
  ) {
    initialState.pageTitle = `${user["https://app.evojets.com/appMetadata"]?.teamColor} Team's Leads`;
  }

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const salesAssistant = userRoles.some(function (role) {
    return "Sales Assistant" === role;
  });

  let recordAccess = [user.sub];

  if (salesAssistant) {
    recordAccess = [
      user.sub,
      user["https://app.evojets.com/appMetadata"].accountDirectorId,
    ];
  }

  let sortableColumns = columns
    .filter((column) => column.sortable)
    .map((column) => {
      return { value: column.selector, label: column.name };
    });

  initialState.sortableColumns = sortableColumns;

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  // console.log(state);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection,
    ownerFilters,
    originFilters
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log({ originFilters });
    let response = await ai
      .auth(token)
      .get(`/api/sourcing/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          sortBy,
          sortDirection,
          stageFilters,
          myLeads: props.showOnlyMine,
          qfsOnly: props.qfsOnly,
          myTeamLeads: props.showMyTeamLeads,
          ownerFilters,
          originFilters,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData(
      state.query,
      true,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      state.ownerFilters,
      state.originFilters
    );
    loadOwnerOptions();
    getTeamMembers();

    document.title = `Sourcing Records | Flight Deck`;
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    // console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();

    dispatch({ type: "search", query });
    getData(
      query,
      true,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      state.ownerFilters,
      state.originFilters
    );
  };

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    // console.log("Leads Content");
    let { data } = await ai.auth(token).get(`/api/users/roles/sales`, {});

    if (data.users.length >= 1) {
      // console.log(data.users);
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      let ownerFilterOptions = [...results];
      ownerFilterOptions.unshift({ label: "Unassigned", value: null });
      dispatch({
        type: "fieldChanged",
        field: "ownerFilterOptions",
        value: ownerFilterOptions,
      });

      return results;
    } else {
      return [];
    }
  };

  const handleOwnerSelection = async (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });

    // console.log(selectedOwner);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    // hit api endpoint to update assignment -leads

    let leadIds = state.selectedRows.map((row) => row._id);

    let { data } = await ai
      .auth(token)
      .put(`/api/leads/bulkAssign`, {
        leadIds: leadIds,
        assigneeId: selectedOwner.value,
      })
      .catch((e) => console.error(e));

    if (data) {
      // console.log(data);

      getData(
        state.query,
        true,
        state.stageFilters,
        state.sortBy,
        state.sortDirection,
        state.ownerFilters,
        state.originFilters
      );

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: [],
      });

      dispatch({
        type: "fieldChanged",
        field: "clearSelectedRows",
        value: !state.clearSelectedRows,
      });

      dispatch({
        type: "fieldChanged",
        field: "selectedOwner",
        value: null,
      });

      return data;
    }

    return selectedOwner;
  };

  const selectedRowCriteria = (row) => {
    let ids = state.selectedRows.map((item) => item._id);
    return ids.includes(row._id);
  };

  const handleLeadStageUpdate = async (selectedStage) => {
    let leadIds = state.selectedRows.map((row) => row._id);
    let stage = null;

    stage = selectedStage?.value;

    if (!stage || !leadIds) {
      return;
    }

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .put(`/api/leads/bulkUpdateStages`, {
        leadIds,
        stage,
      })
      .catch((e) => console.error(e));

    if (data) {
      // console.log(data);

      getData(
        state.query,
        true,
        state.stageFilters,
        state.sortBy,
        state.sortDirection,
        state.ownerFilters,
        state.originFilters
      );

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: [],
      });

      dispatch({
        type: "fieldChanged",
        field: "clearSelectedRows",
        value: !state.clearSelectedRows,
      });

      dispatch({
        type: "fieldChanged",
        field: "selectedOwner",
        value: null,
      });

      return data;
    }
  };

  const disableStageAssignmentSelect = () => {
    let disabled = "disabled";

    if (state.selectedRows.length) {
      let someRowsAreNotAssigned = state.selectedRows.some(
        (row) => row.assigneeId === undefined
      );
      if (!someRowsAreNotAssigned) {
        disabled = "";
      }
    }

    return disabled;
  };

  const disableAssignmentSelect = () => {
    let disabled = true;

    if (state.selectedRows.length) {
      disabled = false;
    }

    return disabled;
  };

  const stageFilterOptions = [
    { label: "New Lead", value: "newLead" },
    { label: "In Progress", value: "inProgress" },
    { label: "Warm Prospect", value: "warmProspect" },
    { label: "Unqualified", value: "unqualified" },
    { label: "Archived", value: "archived" },
    { label: "In Limbo", value: "inLimbo" },
    { label: "Signed Contract", value: "signedContract" },
    { label: "Trip Complete", value: "tripComplete" },
    { label: "Closed - No Reply", value: "closedNoReply" },
    { label: "Closed - Ghost", value: "closedGhost" },
    { label: "Closed - Repeat", value: "repeatClient" },
    { label: "Closed - Lost to Competitor", value: "closedCompetitor" },
  ];

  const getTeamMembers = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/users/getTeamMembers`);

    if (data.length) {
      data = data.map((member) => member.user_id);
    }

    dispatch({
      type: "fieldChanged",
      field: "teamMembers",
      value: data,
    });
  };

  const handleStageFilterChange = (selectedValues, action) => {
    // console.log(selectedValues, action);
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "stageFilterChanged", payload: { values } });

    dispatch({
      type: "fieldChanged",
      field: "stageFilterInput",
      value: selectedValues,
    });

    getData(
      state.query,
      true,
      values,
      state.sortBy,
      state.sortDirection,
      state.ownerFilters,
      state.originFilters
    );
  };

  const handleOwnerFilterChange = (selectedValues, action) => {
    // console.log(selectedValues, action);
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "fieldChanged", field: "ownerFilters", value: values });

    dispatch({
      type: "fieldChanged",
      field: "ownerFiltersInput",
      value: selectedValues,
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      values,
      state.originFilters
    );
  };

  const handleOriginFilterChange = (selectedValues, action) => {
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "fieldChanged", field: "originFilters", value: values });

    dispatch({
      type: "fieldChanged",
      field: "originFiltersInput",
      value: selectedValues,
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      state.ownerFilters,
      values
    );
  };

  const handleSort = (column, sortDirection) => {
    // console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection,
      state.ownerFilters,
      state.originFilters
    );

    //keyField is required for server sort
  };

  const handleSortMobile = (sortBy, sortDirection) => {
    console.log("sorted", sortBy, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      sortBy,
      sortDirection,
      state.ownerFilters,
      state.originFilters
    );

    //keyField is required for server sort
  };

  const onRowClicked = (row, event) => {
    // confirm if row id is inside selectedRows array
    let arr = [...state.selectedRows];
    arr = arr.filter((selectedRow) => selectedRow._id === row._id);

    if (arr.length) {
      // row is already in array...uncheck it

      let newSelectedRowsArr = [...state.selectedRows];

      newSelectedRowsArr = newSelectedRowsArr.filter(
        (selectedRow) => selectedRow._id !== row._id
      );

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: newSelectedRowsArr,
      });

      let newData = [...state.data.sourcingRecords];

      dispatch({ type: "tableDataChanged", value: newData });

      return;
    } else {
      // push row into selectedRows array
      let currentSelectedRowsArray = [...state.selectedRows];
      currentSelectedRowsArray.push(row);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: currentSelectedRowsArray,
      });

      let newData = [...state.data.sourcingRecords];
      dispatch({ type: "tableDataChanged", value: newData });

      return;
    }
  };

  const preSelectCriteria = (row) => {
    let selectedRows = [...state.selectedRows];

    selectedRows = selectedRows.filter(
      (selectedRow) => selectedRow._id === row._id
    );

    // console.log("SELECTED ROWS", selectedRows.length);

    return selectedRows.length;
  };

  const disableNextButton = () => {
    let totalPages = Math.ceil(state.totalLength / state.countPerPage);
    let currentPage = state.currentPage;

    if (currentPage < totalPages) {
      return false;
    }

    return true;
  };

  const disablePrevButton = () => {
    let totalPages = Math.ceil(state.totalLength / state.countPerPage);
    let currentPage = state.currentPage;

    // console.log("DISABLE PREV BUTTON", currentPage, totalPages);

    if (currentPage > 1) {
      return false;
    }

    return true;
  };

  const handleNextButton = () => {
    return dispatch({
      type: "fieldChanged",
      field: "currentPage",
      value: state.currentPage + 1,
    });
  };

  const handlePrevButton = () => {
    return dispatch({
      type: "fieldChanged",
      field: "currentPage",
      value: state.currentPage - 1,
    });
  };

  return (
    <Fragment>
      {!isMobile && (
        <PageHeaderRowSearch
          h1={`${state.pageTitle ? state.pageTitle : "Sourcing Records"}`}
          pretitle="Business"
          breadcrumbs={[{ link: "/sourcing", label: "Sourcing" }]}
          rightColumn={
            <div id="quote-actions-row">
              <Search
                onSearch={handleSearch}
                placeholder="Search Records"
                query={state.query}
                className="lead-search"
              ></Search>
            </div>
          }
        ></PageHeaderRowSearch>
      )}

      {isMobile && (
        <PageHeaderRowSearch
          h1={`${state.pageTitle ? state.pageTitle : "Sourcing Records"}`}
          pretitle="Business"
          breadcrumbs={[{ link: "/sourcing", label: "Sourcing" }]}
          // rightColumn={
          //   <div id="quote-actions-row" className="w-100pct">
          //     <Search
          //       onSearch={handleSearch}
          //       placeholder="Search Leads"
          //       query={state.query}
          //       className="lead-search"
          //     ></Search>
          //   </div>
          // }
          extraClasses="no-border"
        ></PageHeaderRowSearch>
      )}

      {isMobile && (
        <>
          <hr></hr>
          <h4 className="mobile-leads-header-heading">SEARCH &amp; FILTER</h4>

          <div id="quote-actions-row" className="w-100pct mt-10">
            <Search
              onSearch={handleSearch}
              placeholder="Search Records"
              query={state.query}
              className="lead-search"
            ></Search>
          </div>
        </>
      )}

      {true && (
        <div id="sort-container">
          <Select
            className="stage-select mt-10 fg-1"
            options={stageFilterOptions}
            placeholder="Stage"
            onChange={handleStageFilterChange}
            defaultValue={state.defaultStageFilters}
            // key={`key-${state.stageFilters}`}d
            value={state.stageFilterInput}
            isMulti
          ></Select>
          {props.showOnlyMine || props.showMyTeamLeads || !superAdminOrAd ? (
            ""
          ) : (
            <Select
              className="stage-select mt-10 fg-1"
              options={state.ownerFilterOptions}
              placeholder="Owner"
              onChange={handleOwnerFilterChange}
              defaultValue={state.defaultOwnerFilters}
              value={state.ownerFiltersInput}
              // key={`key-${state.stageFilters}`}
              isMulti
            ></Select>
          )}

          <Select
            className="stage-select mt-10 fg-1"
            classNamePrefix="select"
            placeholder="Origin"
            isMulti
            options={originFilterOptions}
            onChange={handleOriginFilterChange}
            defaultValue={props.defaultOriginFilters}
            value={state.originFiltersInput}
            key={`ownerFilters${state.originFiltersInput}`}
          ></Select>

          {isMobile && (
            <>
              <Select
                className="sortBy-select mt-10"
                options={state.sortableColumns}
                placeholder="Sort By"
                onChange={(selectedValue, action) => {
                  console.log("SORT BY:", selectedValue);
                  console.log("SORT DIRECTION:", state.sortDirection);

                  dispatch({
                    type: "fieldChanged",
                    field: "sortBy",
                    value: selectedValue.value,
                  });

                  if (state.sortDirection) {
                    return handleSortMobile(
                      selectedValue.value,
                      state.sortDirection
                    );
                  }
                }}
                defaultValue={{ label: "Created At", value: "createdAt" }}
              ></Select>
              <Select
                className="sortDirection-select mt-10"
                options={[
                  { label: "Ascending", value: "asc" },
                  { label: "Descending", value: "desc" },
                ]}
                placeholder="Sort Direction"
                onChange={(selectedValue, action) => {
                  console.log("SORT BY:", state.sortBy);
                  console.log("SORT DIRECTION:", selectedValue);
                  dispatch({
                    type: "fieldChanged",
                    field: "sortDirection",
                    value: selectedValue.value,
                  });

                  if (state.sortBy) {
                    return handleSortMobile(state.sortBy, selectedValue.value);
                  }
                }}
                defaultValue={(() => {
                  if (state.sortDirection === "desc") {
                    return {
                      label: "Descending",
                      value: state.sortDirection,
                    };
                  } else if (state.sortDirection === "asc") {
                    return {
                      label: "Ascending",
                      value: state.sortDirection,
                    };
                  }
                })()}
              ></Select>
            </>
          )}
        </div>
      )}

      {!state.loading && isMobile && (
        <SourcingListMobile
          dispatch={dispatch}
          rows={state.data.sourcingRecords}
          superAdminOrAd={superAdminOrAd}
          selectedRows={state.selectedRows}
        />
      )}

      {!state.loading && isMobile && (
        <>
          <div id="evo-lead-pagination">
            <button
              className="prev-lead-pagination lead-pagination-button base-button evo-blue"
              disabled={disablePrevButton()}
              onClick={handlePrevButton}
            >
              <span className="material-icons">chevron_left</span>
            </button>
            <select
              id="countPerPage"
              onChange={(e) =>
                dispatch({
                  type: "fieldChanged",
                  field: "countPerPage",
                  value: e.target.value,
                })
              }
              value={state.countPerPage}
            >
              <option key="25" value="25">
                25
              </option>
              <option key="50" value="50">
                50
              </option>
              <option key="100" value="100">
                100
              </option>
              <option key="150" value="150">
                150
              </option>
            </select>
            <button
              className="next-lead-pagination lead-pagination-button base-button evo-blue"
              disabled={disableNextButton()}
              onClick={handleNextButton}
            >
              <span className="material-icons">chevron_right</span>
            </button>
          </div>

          <p className="evo-pagination-page-count">
            Page {state.currentPage} of{" "}
            {Math.ceil(state.totalLength / state.countPerPage)}
          </p>

          {/* <pre>{JSON.stringify(state.pageOptions, null, 2)}</pre> */}
        </>
      )}

      {!state.loading && !isMobile && (
        <DataTableServerPagination
          onSelection={handleSelection}
          key={JSON.stringify(columns)}
          columns={columns}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          state={state}
          data={state.data.sourcingRecords}
          defaultSortField={"createdAt"}
          defaultSortAsc={false}
          selectableRowSelected={preSelectCriteria}
          handleSort={handleSort}
          sortServer={true}
          onRowClicked={onRowClicked}
          disableSelection
          // overrideFixedHeader={true}
        ></DataTableServerPagination>
      )}

      {!state.loading && isMobile && <BackToTopButton />}

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SourcingListContent;
